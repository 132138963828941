import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { ClientFormProps, ClientFormValues } from '.';
import { ClientModel, ClientType } from '../../reducers/clients/model';
import { TCountryCode } from 'countries-list';

export const initFormik = (
  props: ClientFormProps,
  client: ClientModel | undefined,
): FormikConfig<ClientFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    companyNIP: client?.companyNIP ?? '',
    companyName: client?.companyName ?? '',
    companyStreet: client?.companyStreet ?? '',
    companyPostCode: client?.companyPostCode ?? '',
    companyCity: client?.companyCity ?? '',
    companyCountry: client?.companyCountry as TCountryCode,
    contactName: client?.contactName ?? '',
    contactSurname: client?.contactSurname ?? '',
    contactEmail: client?.contactEmail ?? '',
    contactPhone: client?.contactPhone ?? '',
    clientType: client?.clientType as ClientType,
  },
  validationSchema: () =>
    yup.object({
      companyNIP: yup.string().required('NIP jest wymagany'),
      companyName: yup.string().required('Nazwa firmy jest wymagana'),
      companyStreet: yup.string().required('Ulica jest wymagana'),
      companyPostCode: yup.string().required('Kod pocztowy jest wymagany'),
      companyCity: yup.string().required('Miasto jest wymagane'),
      companyCountry: yup.string().required('Kraj jest wymagany'),
      contactName: yup.string().required('Imię jest wymagane'),
      contactSurname: yup.string().required('Nazwisko jest wymagane'),
      contactEmail: yup.string().email('E-mail nie jest poprawny').required('E-mail jest wymagany'),
      contactPhone: yup.string().required('Telefon jest wymagany'),
      clientType: yup.string().required('Pole jest wymagane'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
