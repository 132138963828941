import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { PaintingsList } from '../../component/settings/paintings/PaintingsList';
import { PaintingHeader } from './PaintingHeader';

export const PaintingPage = () => {
  const [openPaintingModal, setOpenPaintingModal] = useState(false);

  return (
    <LayoutMain>
      <PaintingHeader onSetOpenPaintingModal={setOpenPaintingModal} />
      <PaintingsList
        openPaintingModal={openPaintingModal}
        clickOpenPaintingModal={openPaintingModal => setOpenPaintingModal(openPaintingModal)}
      />
    </LayoutMain>
  );
};
