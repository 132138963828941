import { HeaderComponent } from '../../component/header/HeaderComponent';
import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';

import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { OffersAdditionalMenu } from './OffersAdditionalMenu';

type OffersHeaderProps = {
  onSetOpenOfferModal: (openOfferModal: boolean) => void;
};

export const OffersHeader: FC<OffersHeaderProps> = props => {
  const { onSetOpenOfferModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Oferty'
      onAddElementOpenModal={onSetOpenOfferModal}
    >
      <OffersAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Oferty'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenOfferModal(true)}
          >
            Dodaj ofertę
          </Button>
        </Col>
        <OffersAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
