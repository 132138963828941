import React from 'react';
import { Drawer } from 'antd';

import './style.scss';

type ModalProps = {
  openModal: boolean;
  handleCloseModal: () => void;
  handleSubmit?: () => void;
  selectedItem?: unknown;
  titleEdit?: string;
  titleAdd: string;
  width?: string;
};

export const DrawerAddEdit: React.FC<ModalProps> = props => {
  const { openModal, handleCloseModal, selectedItem, titleEdit, titleAdd, width = '50%' } = props;

  return (
    <Drawer
      open={openModal}
      onClose={handleCloseModal}
      title={selectedItem ? titleEdit : titleAdd}
      width={width}
    >
      {openModal ? props.children : null}
    </Drawer>
  );
};
