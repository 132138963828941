import React, { FC } from 'react';
import { ProductStatusListType } from '../../reducers/forklift/model';
import { Tabs, TabsProps, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faSackDollar, faWarehouse } from '@fortawesome/free-solid-svg-icons';

type Props = {
  forkliftsAmount: number;
  forkliftsSoldAmount: number;
  forkliftsAvailableAmount: number;
  forkliftsReservedAmount: number;
  onChange: (status: ProductStatusListType) => void;
};
export const ForkliftsTabs: FC<Props> = ({
  forkliftsAvailableAmount,
  forkliftsSoldAmount,
  forkliftsAmount,
  forkliftsReservedAmount,
  onChange,
}) => {
  const itemsTab: TabsProps['items'] = [
    {
      key: ProductStatusListType.AVAILABLE,
      label: (
        <>
          <FontAwesomeIcon
            icon={faWarehouse}
            size='sm'
            className='icon'
          />
          Dostępne <Tag bordered={false}>{forkliftsAvailableAmount}</Tag>
        </>
      ),
    },
    {
      key: ProductStatusListType.IN_ORDER_RESERVED,
      label: (
        <>
          <FontAwesomeIcon
            icon={faFileContract}
            size='sm'
            className='icon'
          />
          Zarezerwowane <Tag bordered={false}>{forkliftsReservedAmount}</Tag>
        </>
      ),
    },
    {
      key: ProductStatusListType.SOLD,
      label: (
        <>
          <FontAwesomeIcon
            icon={faSackDollar}
            size='sm'
            className='icon'
          />
          Sprzedane <Tag bordered={false}>{forkliftsSoldAmount}</Tag>
        </>
      ),
    },
    {
      key: ProductStatusListType.ALL,
      label: (
        <>
          Wszystkie <Tag bordered={false}>{forkliftsAmount}</Tag>
        </>
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={ProductStatusListType.AVAILABLE}
      items={itemsTab}
      onChange={key => {
        onChange(key as ProductStatusListType);
      }}
      className='tab'
    />
  );
};
