export const GET_COSTS_REQUEST = '@costs/GET_COSTS_REQUEST';
export const GET_COSTS_SUCCESS = '@costs/GET_COSTS_SUCCESS';
export const GET_COSTS_ERROR = '@costs/GET_COSTS_ERROR';

export const GET_PRODUCT_COSTS_REQUEST = '@costs/GET_PRODUCT_COSTS_REQUEST';
export const GET_PRODUCT_COSTS_SUCCESS = '@costs/GET_PRODUCT_COSTS_SUCCESS';
export const GET_PRODUCT_COSTS_ERROR = '@costs/GET_PRODUCT_COSTS_ERROR';

export const ADD_COSTS_REQUEST = '@costs/ADD_COSTS_REQUEST';
export const ADD_COSTS_SUCCESS = '@costs/ADD_COSTS_SUCCESS';
export const ADD_COSTS_ERROR = '@costs/ADD_COSTS_ERROR';

export const UPDATE_COSTS_REQUEST = '@costs/UPDATE_COSTS_REQUEST';
export const UPDATE_COSTS_SUCCESS = '@costs/UPDATE_COSTS_SUCCESS';
export const UPDATE_COSTS_ERROR = '@costs/UPDATE__COSTS_ERROR';

export const DELETE_COSTS_REQUEST = '@costs/DELETE_COSTS_REQUEST';
export const DELETE_COSTS_SUCCESS = '@costs/DELETE_COSTS_SUCCESS';
export const DELETE_COSTS_ERROR = '@costs/DELETE__COSTS_ERROR';
