import { useQuery } from 'react-query';
import { apiClientSettings } from './index';

const queryKeys = {
  getSettings: () => 'settings',
};

export const useGetSettings = () => {
  return useQuery(queryKeys.getSettings(), async () => {
    const response = await apiClientSettings.getSettings();
    return response.data;
  });
};
