import dayjs from 'dayjs';
import { OrderStatus } from '../reducers/orders/model';

export const getStatusOrderClass = (status: OrderStatus, paidAt?: string) => {
  if (![OrderStatus.PAID, OrderStatus.DONE, OrderStatus.CANCEL].includes(status) && dayjs().diff(paidAt) > 0) {
    return 'red';
  }

  return '';
};
