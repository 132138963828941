import { BaseAPI } from '../base';
import { OfferModel } from '../../../reducers/offers/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientOffers extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/offers`;
  }

  public getOffers = async (): Promise<ResponseModel<OfferModel[]>> => {
    return this.authFetch<ResponseModel<OfferModel[]>>(this.apiURL, { method: 'GET' });
  };

  public getOffer = async (id: string): Promise<ResponseModel<OfferModel>> => {
    return this.authFetch<ResponseModel<OfferModel>>(`${this.apiURL}/${id}`, { method: 'GET' });
  };

  public addOffer = async (data: Partial<OfferModel>): Promise<ResponseModel<OfferModel>> => {
    delete data.offerNr;
    return this.authFetch<ResponseModel<OfferModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateOffer = async (data: Partial<OfferModel>): Promise<ResponseModel<OfferModel>> => {
    delete data.price;
    delete data.ownerId;
    delete data.owner;
    return this.authFetch<ResponseModel<OfferModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteOffer = async (offerId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${offerId}`, { method: 'DELETE' });
  };

  public getOfferPDF = async (offerId: string): Promise<ArrayBuffer> => {
    return this.authFetchFileAsArrayBuffer(`${this.apiURL}/${offerId}/pdf`, { method: 'GET' });
  };
}

export const apiClientOffers = new APIClientOffers();
