import { BaseAPI } from '../base';
import {
  ForkliftInternalId,
  ForkliftModel,
  ForkliftStatusesModel,
  ProductStatusListType,
} from '../../../reducers/forklift/model';
import { ResponseModel } from '../../../reducers/model';
import { prepareFilterQueryString } from '../../../utils/queryStringFiltering';

class APIClientForklifts extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/forklifts`;
  }

  public getForklifts = async (): Promise<ResponseModel<ForkliftModel[]>> => {
    return this.authFetch<ResponseModel<ForkliftModel[]>>(this.apiURL, { method: 'GET' });
  };

  public getAvailableForklifts = async (): Promise<ResponseModel<ForkliftModel[]>> => {
    return this.authFetch<ResponseModel<ForkliftModel[]>>(
      `${this.apiURL}/${prepareFilterQueryString({ filters: {}, productStatusTab: ProductStatusListType.AVAILABLE })}`,
      { method: 'GET' },
    );
  };

  public addForklift = async (
    data: Omit<ForkliftModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'order' | 'costs'>,
  ): Promise<ResponseModel<ForkliftModel>> => {
    return this.authFetch<ResponseModel<ForkliftModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateForklift = async (data: Partial<ForkliftModel>): Promise<ResponseModel<ForkliftModel>> => {
    delete data.brand;
    delete data.category;
    delete data.costs;
    delete data.costsAmount;
    delete data.order;
    delete data.mast;
    return this.authFetch<ResponseModel<ForkliftModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteForklift = async (forkliftId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${forkliftId}`, { method: 'DELETE' });
  };

  public updateForkliftStatus = async (data: ForkliftStatusesModel): Promise<ResponseModel<ForkliftModel[]>> => {
    return this.authFetch<ResponseModel<ForkliftModel[]>>(`${this.apiURL}/statuses`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public getForklift = async (forkliftId: string): Promise<ResponseModel<ForkliftModel>> => {
    return this.authFetch<ResponseModel<ForkliftModel>>(`${this.apiURL}/${forkliftId}`, { method: 'GET' });
  };

  public getForkliftInternalId = async (): Promise<ResponseModel<ForkliftInternalId>> => {
    return this.authFetch<ResponseModel<ForkliftInternalId>>(`${this.apiURL}/internal-id`, { method: 'GET' });
  };

  public getStockList = async (): Promise<ArrayBuffer> => {
    return this.authFetchFileAsArrayBuffer(`${this.apiURL}/stock-list`, { method: 'GET' });
  };
}

export const apiClientForklifts = new APIClientForklifts();
