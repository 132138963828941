import React, { FC, useEffect } from 'react';
import { Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AlignType, FixedType } from 'rc-table/lib/interface';
import { ColumnsType } from 'antd/es/table';

import { RootReducerState } from '../../../../reducers';
import { useFilterAntTable } from '../../../../utils/customHooks/useFilterAntTable';
import { CategoryModel } from '../../../../reducers/categories/model';
import { EquipmentModel } from '../../../../reducers/equipment/model';
import { getEquipmentsRequest } from '../../../../reducers/equipment/actions';
import { BrandModel } from '../../../../reducers/brands/model';
import { ProductStatus, ProductStatusNames } from '../../../../reducers/forklift/model';
import { getStatusForkliftIcon } from '../../../../utils/statusForkliftIcon';
import { EquipmentCategoryModel } from '../../../../reducers/equipmentCategories/model';
import { getEquipmentCategoriesRequest } from '../../../../reducers/equipmentCategories/actions';
import { getEquipmentBrandsRequest } from '../../../../reducers/equipmentBrands/actions';
import { formatPrice } from '../../../../utils/formatPrice';
import { CurrencyType } from '../../../../reducers/orders/model';

const { Title } = Typography;

type OrdersEquipmentsListProps = {
  productInOrder: string[];
  groupEditEquipmentsStatus: string[];
  onSelection: (newSelectedRowKeys: React.Key[]) => void;
};

export const OrdersEquipmentsList: FC<OrdersEquipmentsListProps> = props => {
  const { productInOrder, groupEditEquipmentsStatus, onSelection } = props;
  const equipmentList = useSelector<RootReducerState, EquipmentModel[]>(state => state.equipment.equipmentList);
  const brandsList = useSelector<RootReducerState, BrandModel[]>(state => state.equipmentBrands.equipmentBrandsList);
  const categoriesList = useSelector<RootReducerState, EquipmentCategoryModel[]>(
    state => state.equipmentCategories.categoriesList,
  );
  const dispatch = useDispatch();
  const { getColumnSearchProps, getColumnFilterProps, getColumnSearchRangeProps } = useFilterAntTable();

  useEffect(() => {
    dispatch(getEquipmentsRequest());
    dispatch(getEquipmentCategoriesRequest());
    dispatch(getEquipmentBrandsRequest());
  }, [dispatch]);

  const filteredEquipmentsByOrder = equipmentList
    .filter(equipmentItem => productInOrder.includes(equipmentItem._id))
    .map(equipment => ({
      ...equipment,
      brandName: equipment.brand?.name,
    }));

  const columns: ColumnsType<EquipmentModel> = [
    {
      title: 'Id',
      dataIndex: 'internalId',
      key: 'internalId',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('internalId', 'Id'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.internalId.localeCompare(b.internalId),
    },
    {
      title: 'Marka',
      dataIndex: 'brandName',
      key: 'brandName',
      fixed: 'left' as FixedType,
      ...getColumnFilterProps(
        'brandId',
        brandsList.map(brand => ({
          text: brand.name,
          value: brand._id,
        })),
      ),
      render: (brandName?: string) => <>{brandName}</>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('type', 'typu'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.type.localeCompare(b.type),
    },
    {
      title: 'Nr seryjny',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('serialNumber', 'nr seryjnego'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.serialNumber.localeCompare(b.serialNumber),
    },
    {
      title: 'Kategoria',
      dataIndex: 'category',
      key: 'category',
      width: 100,
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'categoryId',
        categoriesList.map(category => ({
          text: category.name,
          value: category._id,
        })),
      ),
      render: (category?: CategoryModel) => <>{category?.name}</>,
    },
    {
      title: 'Udźwig',
      dataIndex: 'liftingCapacity',
      key: 'liftingCapacity',
      ...getColumnSearchRangeProps('liftingCapacity', 'udźwigu'),
      render: (liftingCapacity: number) => <>{liftingCapacity} kg</>,
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.liftingCapacity - b.liftingCapacity,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'status',
        Object.values(ProductStatus).map(status => ({
          value: status,
          text: ProductStatusNames[status],
        })),
      ),
      render: (status: ProductStatus) => getStatusForkliftIcon(status),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.status.localeCompare(b.status),
    },
    {
      title: 'Mocowanie',
      dataIndex: 'mounting',
      key: 'mounting',
      width: 100,
      align: 'center' as AlignType,
      ...getColumnSearchProps('mounting', 'mocowania'),
    },
    {
      title: 'Rok',
      dataIndex: 'productionYear',
      key: 'productionYear',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('productionYear', 'rok produkcji'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.productionYear - b.productionYear,
    },
    {
      title: 'Szerokość',
      dataIndex: 'width',
      key: 'width',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('width', 'szerokości'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.width - b.width,
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('price', 'ceny'),
      render: (price: number) => formatPrice(price, CurrencyType.PLN),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.price - b.price,
    },
    {
      title: 'Cena hurt',
      dataIndex: 'priceWholesale',
      key: 'priceWholesale',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('priceWholesale', 'ceny'),
      render: (priceWholesale: number) => formatPrice(priceWholesale, CurrencyType.EUR),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.priceWholesale - b.priceWholesale,
    },
  ];

  if (filteredEquipmentsByOrder.length > 0) {
    return (
      <>
        <Title level={5}>Osprzęt</Title>
        <Table
          columns={columns}
          dataSource={filteredEquipmentsByOrder}
          rowKey={record => record._id || ''}
          size='small'
          style={{ paddingTop: '16px' }}
          rowSelection={{
            selectedRowKeys: groupEditEquipmentsStatus,
            onChange: onSelection,
          }}
        />
      </>
    );
  }
  return null;
};
