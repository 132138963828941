import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addEquipmentCategoryError,
  addEquipmentCategoryRequest,
  addEquipmentCategorySuccess,
  deleteEquipmentCategoryError,
  deleteEquipmentCategoryRequest,
  deleteEquipmentCategorySuccess,
  getEquipmentCategoriesError,
  getEquipmentCategoriesSuccess,
  updateEquipmentCategoryError,
  updateEquipmentCategoryRequest,
  updateEquipmentCategorySuccess,
} from './actions';

import * as CONST from './consts';
import { EquipmentCategoryModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientEquipmentCategories } from '../../services/apiClient/equipmentCategories';

function* getEquipmentCategories(): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentCategoryModel[]>> {
  try {
    const response = yield call(apiClientEquipmentCategories.getEquipmentCategories);

    yield put(getEquipmentCategoriesSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get equipment categories',
      description: getErrorMessage(err),
    });
    yield put(getEquipmentCategoriesError(err));
  }
}

function* addEquipmentCategory(
  action: ActionType<typeof addEquipmentCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentCategoryModel>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiClientEquipmentCategories.addEquipmentCategory, category);

    yield put(addEquipmentCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding equipment category',
      description: getErrorMessage(err),
    });
    yield put(addEquipmentCategoryError(err));
  }
}

function* updateEquipmentCategory(
  action: ActionType<typeof updateEquipmentCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentCategoryModel>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiClientEquipmentCategories.updateEquipmentCategory, category);

    yield put(updateEquipmentCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating equipment category',
      description: getErrorMessage(err),
    });
    yield put(updateEquipmentCategoryError(err));
  }
}

function* deleteEquipmentCategory(
  action: ActionType<typeof deleteEquipmentCategoryRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { categoryId, onSuccess } = action.payload;
    yield call(apiClientEquipmentCategories.deleteEquipmentCategory, categoryId);

    yield put(deleteEquipmentCategorySuccess(categoryId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting equipment category',
      description: getErrorMessage(err),
    });
    yield put(deleteEquipmentCategoryError(err));
  }
}

export function* watchEquipmentCategoriesSaga(): Generator {
  yield takeLatest(CONST.GET_EQUIPMENT_CATEGORIES_REQUEST, getEquipmentCategories);
  yield takeLatest(CONST.ADD_EQUIPMENT_CATEGORY_REQUEST, addEquipmentCategory);
  yield takeLatest(CONST.UPDATE_EQUIPMENT_CATEGORY_REQUEST, updateEquipmentCategory);
  yield takeLatest(CONST.DELETE_EQUIPMENT_CATEGORY_REQUEST, deleteEquipmentCategory);
}
