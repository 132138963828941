import React, { FC, useState } from 'react';
import { Modal, message } from 'antd';
import { ForkliftModel } from '../../../reducers/forklift/model';
import { ForkliftForm, ForkliftFormValues } from '../../../forms/forklift';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import {
  addForkliftRequest,
  deleteForkliftRequest,
  getForkliftInternalIdRequest,
  updateForkliftRequest,
} from '../../../reducers/forklift/actions';
import { useDispatch } from 'react-redux';
import { ForkliftPreview } from '../ForkliftPreview';
import { CostsManager } from '../../costs/CostsManager';
import { ForkliftMobileItem } from './ForkliftMobileItem';
import { getData } from '../utils';

import { ListSC } from './styles';

type ForkliftMobileListProps = {
  forkliftsList: ForkliftModel[];
  openForkliftModal: boolean;
  clickOpenForkliftModal: (openForkliftModal: boolean) => void;
  isFetchingForkliftsList: boolean;
};

export const ForkliftMobileList: FC<ForkliftMobileListProps> = props => {
  const { forkliftsList, openForkliftModal, clickOpenForkliftModal, isFetchingForkliftsList } = props;
  const [selectedForklift, setSelectedForklift] = useState<ForkliftModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [openForkliftCostModal, setOpenForkliftCostModal] = useState(false);
  const [openForkliftDeleteModal, setOpenForkliftDeleteModal] = useState(false);
  const [selectedForkliftCost, setSelectedForkliftCost] = useState<ForkliftModel | undefined>(undefined);

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const handleCloseForkliftModal = () => {
    setSelectedForklift(undefined);
    clickOpenForkliftModal(false);
  };

  const handleConfirmForkliftModal = () => {
    if (selectedForklift) {
      dispatch(
        deleteForkliftRequest(selectedForklift._id, () => {
          setOpenForkliftDeleteModal(false);
        }),
      );
    }
  };

  const handleSubmit = (values: ForkliftFormValues, onAddSimilar: () => void) => {
    if (selectedForklift) {
      dispatch(
        updateForkliftRequest(
          {
            ...selectedForklift,
            ...values,
            mastId: values.mastId ? values.mastId : null,
          },
          () => {
            setSelectedForklift(undefined);
            clickOpenForkliftModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addForkliftRequest(
          {
            ...values,
            mastId: values.mastId ? values.mastId : null,
          },
          () => {
            if (values._addNew) {
              void messageApi.open({
                type: 'success',
                content: 'Wózek został dodany.',
              });
              dispatch(getForkliftInternalIdRequest());
              onAddSimilar();
              return;
            }
            setSelectedForklift(undefined);
            clickOpenForkliftModal(false);
          },
        ),
      );
    }
  };

  const handleCloseQuickViewModal = () => {
    setSelectedForklift(undefined);
    setOpenQuickViewModal(false);
  };

  const handleCloseForkliftCostModal = () => {
    setSelectedForkliftCost(undefined);
    setOpenForkliftCostModal(false);
  };

  return (
    <>
      {contextHolder}
      <ListSC
        itemLayout='vertical'
        size='small'
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        loading={isFetchingForkliftsList}
        dataSource={getData(forkliftsList)}
        className='mobileProductList'
        renderItem={forklift => (
          <ForkliftMobileItem
            forklift={forklift}
            clickOpenForkliftModal={clickOpenForkliftModal}
            setSelectedForklift={setSelectedForklift}
            setOpenQuickViewModal={setOpenQuickViewModal}
            setSelectedForkliftCost={setSelectedForkliftCost}
            setOpenForkliftCostModal={setOpenForkliftCostModal}
            setOpenForkliftDeleteModal={setOpenForkliftDeleteModal}
          />
        )}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane wózka'
        titleAdd='Dodaj wózek'
        openModal={openForkliftModal}
        handleCloseModal={handleCloseForkliftModal}
        selectedItem={selectedForklift}
        width='100%'
      >
        <ForkliftForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseForkliftModal}
          forkliftId={selectedForklift?._id}
        />
      </DrawerAddEdit>
      {selectedForklift && (
        <DrawerAddEdit
          titleEdit={`Podgląd wózka: ${selectedForklift.internalId}`}
          titleAdd={`Podgląd wózka: ${selectedForklift.internalId}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedForklift}
          width='100%'
        >
          <ForkliftPreview forkliftId={selectedForklift._id} />
        </DrawerAddEdit>
      )}
      {selectedForkliftCost && (
        <DrawerAddEdit
          titleEdit={`Koszty wózka: ${selectedForkliftCost.internalId}`}
          titleAdd={`Koszty wózka: ${selectedForkliftCost.internalId}`}
          openModal={openForkliftCostModal}
          handleCloseModal={handleCloseForkliftCostModal}
          selectedItem={selectedForklift}
          width='100%'
        >
          <CostsManager forklift={selectedForkliftCost} />
        </DrawerAddEdit>
      )}
      <Modal
        title='Usuwanie wózka'
        open={openForkliftDeleteModal}
        cancelText='Anuluj'
        okText='Tak'
        onCancel={() => setOpenForkliftDeleteModal(false)}
        onOk={handleConfirmForkliftModal}
      >
        Czy na pewno chcesz usunąć wózek?
      </Modal>
    </>
  );
};
