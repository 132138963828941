import React, { FC } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

type UploadProps = {
  isFetchingAssets: boolean;
};

export const UploadButton: FC<UploadProps> = props => (
  <div>
    {props.isFetchingAssets ? <LoadingOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
