import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { HeaderSC } from './HeaderSC';

const { Title } = Typography;

type HeaderComponentProps = {
  title: string;
};

export const HeaderComponent: FC<HeaderComponentProps> = props => {
  const { title, children } = props;

  return (
    <HeaderSC className='header'>
      <Row justify='space-between'>
        <Col lg={8}>
          <Title level={2}>{title}</Title>
        </Col>
        <Col lg={16}>
          <Row
            justify='end'
            gutter={16}
          >
            <Col>{children}</Col>
          </Row>
        </Col>
      </Row>
    </HeaderSC>
  );
};
