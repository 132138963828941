import React, { FC } from 'react';
import { Tabs, TabsProps, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faSackDollar, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { ProductStatusListType } from '../../reducers/forklift/model';

type Props = {
  equipmentsAmount: number;
  equipmentsSoldAmount: number;
  equipmentsAvailableAmount: number;
  equipmentsReservedAmount: number;
  onChange: (status: ProductStatusListType) => void;
};
export const EquipmentTabs: FC<Props> = ({
  equipmentsAvailableAmount,
  equipmentsSoldAmount,
  equipmentsAmount,
  equipmentsReservedAmount,
  onChange,
}) => {
  const itemsTab: TabsProps['items'] = [
    {
      key: ProductStatusListType.AVAILABLE,
      label: (
        <>
          <FontAwesomeIcon
            icon={faWarehouse}
            size='sm'
            className='icon'
          />
          Dostępne <Tag bordered={false}>{equipmentsAvailableAmount}</Tag>
        </>
      ),
    },
    {
      key: ProductStatusListType.IN_ORDER_RESERVED,
      label: (
        <>
          <FontAwesomeIcon
            icon={faFileContract}
            size='sm'
            className='icon'
          />
          Zarezerwowane <Tag bordered={false}>{equipmentsReservedAmount}</Tag>
        </>
      ),
    },
    {
      key: ProductStatusListType.SOLD,
      label: (
        <>
          <FontAwesomeIcon
            icon={faSackDollar}
            size='sm'
            className='icon'
          />
          Sprzedane <Tag bordered={false}>{equipmentsSoldAmount}</Tag>
        </>
      ),
    },
    {
      key: ProductStatusListType.ALL,
      label: (
        <>
          Wszystkie <Tag bordered={false}>{equipmentsAmount}</Tag>
        </>
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={ProductStatusListType.AVAILABLE}
      items={itemsTab}
      onChange={key => {
        onChange(key as ProductStatusListType);
      }}
      className='tab'
    />
  );
};
