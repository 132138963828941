import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { EquipmentInternalId, EquipmentModel, EquipmentStatusesModel } from './model';

export const getEquipmentsRequest = () => action(CONST.GET_EQUIPMENTS_REQUEST);
export const getEquipmentsSuccess = (equipments: EquipmentModel[]) =>
  action(CONST.GET_EQUIPMENTS_SUCCESS, { equipments });
export const getEquipmentsError = (error: unknown) => action(CONST.GET_EQUIPMENTS_ERROR, { error });

export const addEquipmentRequest = (
  equipment: Omit<EquipmentModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'order'>,
  onSuccess?: () => void,
) => action(CONST.ADD_EQUIPMENT_REQUEST, { equipment, onSuccess });
export const addEquipmentSuccess = (equipment: EquipmentModel) => action(CONST.ADD_EQUIPMENT_SUCCESS, { equipment });
export const addEquipmentError = (error: unknown) => action(CONST.ADD_EQUIPMENT_ERROR, { error });

export const updateEquipmentRequest = (equipment: EquipmentModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_EQUIPMENT_REQUEST, { equipment, onSuccess });
export const updateEquipmentSuccess = (equipment: EquipmentModel) =>
  action(CONST.UPDATE_EQUIPMENT_SUCCESS, { equipment });
export const updateEquipmentError = (error: unknown) => action(CONST.UPDATE_EQUIPMENT_ERROR, { error });

export const deleteEquipmentRequest = (equipmentId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_EQUIPMENT_REQUEST, { equipmentId, onSuccess });
export const deleteEquipmentSuccess = (equipmentId: string) => action(CONST.DELETE_EQUIPMENT_SUCCESS, { equipmentId });
export const deleteEquipmentError = (error: unknown) => action(CONST.DELETE_EQUIPMENT_ERROR, { error });

export const updateEquipmentsStatusesRequest = (equipmentsStatuses: EquipmentStatusesModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_EQUIPMENTS_STATUSES_REQUEST, { equipmentsStatuses, onSuccess });
export const updateEquipmentsStatusesSuccess = (equipments: EquipmentModel[]) =>
  action(CONST.UPDATE_EQUIPMENTS_STATUSES_SUCCESS, { equipments });
export const updateEquipmentsStatusesError = (error: unknown) =>
  action(CONST.UPDATE_EQUIPMENTS_STATUSES_ERROR, { error });

export const getEquipmentRequest = (equipmentId: string) => action(CONST.GET_EQUIPMENT_REQUEST, { equipmentId });
export const getEquipmentSuccess = (equipment: EquipmentModel) => action(CONST.GET_EQUIPMENT_SUCCESS, { equipment });
export const getEquipmentError = (error: unknown) => action(CONST.GET_EQUIPMENT_ERROR, { error });

export const getEquipmentInternalIdRequest = () => action(CONST.GET_EQUIPMENT_INTERNAL_ID_REQUEST);
export const getEquipmentInternalIdSuccess = (equipmentInternalId: EquipmentInternalId) =>
  action(CONST.GET_EQUIPMENT_INTERNAL_ID_SUCCESS, { equipmentInternalId: equipmentInternalId });
export const getEquipmentInternalIdError = (error: unknown) => action(CONST.GET_EQUIPMENT_INTERNAL_ID_ERROR, { error });

export const getEquipmentStockistRequest = (onSuccess?: () => void) =>
  action(CONST.GET_EQUIPMENT_STOCK_LIST_REQUEST, { onSuccess });

export const getEquipmentStockistSuccess = () => action(CONST.GET_EQUIPMENT_STOCK_LIST_SUCCESS);

export const getEquipmentStockistError = (error: unknown) => action(CONST.GET_EQUIPMENT_STOCK_LIST_ERROR, { error });
