import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';

import { EquipmentCategoriesList } from '../../component/equipmentCategories/EquipmentCategoriesList';
import { EquipmentCategoriesHeader } from './EquipmentCategoriesHeader';

export const EquipmentCategoriesPage = () => {
  const [openEquipmentCategoryModal, setOpenEquipmentCategoryModal] = useState(false);

  return (
    <LayoutMain>
      <EquipmentCategoriesHeader onSetOpenEquipmentCategoryModal={setOpenEquipmentCategoryModal} />
      <EquipmentCategoriesList
        openEquipmentCategoryModal={openEquipmentCategoryModal}
        clickOpenEquipmentCategoryModal={openEquipmentCategoryModal =>
          setOpenEquipmentCategoryModal(openEquipmentCategoryModal)
        }
      />
    </LayoutMain>
  );
};
