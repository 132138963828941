import Color from 'color';
import { ThemeConfig } from 'antd';

export enum ProjectColors {
  GREEN = '#84BF40',
}

export const antDStyles: ThemeConfig = {
  components: {
    Button: {
      colorPrimary: ProjectColors.GREEN,
      colorPrimaryHover: Color(ProjectColors.GREEN).lighten(0.1).hex(),
      colorPrimaryActive: Color(ProjectColors.GREEN).lighten(0.1).hex(),
      borderRadius: 2,
      borderRadiusLG: 2,
      colorBgContainerDisabled: '#fff',
    },
    List: {},
  },
};
