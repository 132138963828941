import { Ability, AbilityBuilder } from '@casl/ability';
import { UserModel, UserRole } from '../../reducers/user/model';

export enum AbilityAction {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  LOGIN_AS = 'login_as',
  MANAGE = 'manage',
}

export enum AbilitySubject {
  ALL = 'all',
  ASSET = 'Asset',
  FORKLIFT = 'Forklift',
  FORKLIFT_BRAND = 'ForkliftBrand',
  FORKLIFT_MAST = 'ForkliftMast',
  FORKLIFT_CATEGORY = 'ForkliftCategory',
  EQUIPMENT = 'Equipment',
  EQUIPMENT_BRAND = 'EquipmentBrand',
  EQUIPMENT_CATEGORY = 'EquipmentCategory',
  CLIENT = 'Client',
  ORDER = 'Order',
  USER = 'User',
  REPORT = 'Report',
  SETTING = 'Setting',
  SETTING_PAINTING = 'SettingPainting',
  SETTING_GENERAL = 'SettingGeneral',
  OFFER = 'Offer',
}

export function defineAbilitiesFor(user: UserModel | null) {
  const { can, rules } = new AbilityBuilder(Ability);

  if (!user) {
    return new Ability(rules);
  }

  switch (user.role) {
    case UserRole.ADMIN:
      can(AbilityAction.MANAGE, AbilitySubject.ALL);
      break;
    case UserRole.SALES_DIRECTOR:
      can(AbilityAction.READ, AbilitySubject.USER);

      can(AbilityAction.CREATE, AbilitySubject.CLIENT);
      can(AbilityAction.UPDATE, AbilitySubject.CLIENT);
      can(AbilityAction.READ, AbilitySubject.CLIENT);

      can(AbilityAction.READ, AbilitySubject.FORKLIFT);
      can(AbilityAction.READ, AbilitySubject.EQUIPMENT);

      can(AbilityAction.CREATE, AbilitySubject.ORDER);
      can(AbilityAction.UPDATE, AbilitySubject.ORDER);
      can(AbilityAction.READ, AbilitySubject.ORDER);
      break;
    case UserRole.SELLER:
      can(AbilityAction.READ, AbilitySubject.USER);

      can(AbilityAction.CREATE, AbilitySubject.CLIENT);
      can(AbilityAction.UPDATE, AbilitySubject.CLIENT);
      can(AbilityAction.READ, AbilitySubject.CLIENT);

      can(AbilityAction.READ, AbilitySubject.FORKLIFT);
      can(AbilityAction.READ, AbilitySubject.EQUIPMENT);

      can(AbilityAction.CREATE, AbilitySubject.ORDER);
      can(AbilityAction.UPDATE, AbilitySubject.ORDER);
      can(AbilityAction.READ, AbilitySubject.ORDER);

      can(AbilityAction.CREATE, AbilitySubject.OFFER);
      can(AbilityAction.UPDATE, AbilitySubject.OFFER);
      can(AbilityAction.READ, AbilitySubject.OFFER);
      break;
    case UserRole.ACCOUNTANT:
      can(AbilityAction.READ, AbilitySubject.USER);

      can(AbilityAction.CREATE, AbilitySubject.CLIENT);
      can(AbilityAction.UPDATE, AbilitySubject.CLIENT);
      can(AbilityAction.READ, AbilitySubject.CLIENT);

      can(AbilityAction.READ, AbilitySubject.ORDER);
      can(AbilityAction.UPDATE, AbilitySubject.ORDER);

      can(AbilityAction.READ, AbilitySubject.FORKLIFT);
      can(AbilityAction.READ, AbilitySubject.EQUIPMENT);
      break;
    case UserRole.MECHANIC:
    case UserRole.LOGISTIC:
      can(AbilityAction.READ, AbilitySubject.FORKLIFT);
      can(AbilityAction.READ, AbilitySubject.EQUIPMENT);
      break;
  }

  return new Ability(rules);
}
