import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Row, Input } from 'antd';

import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../reducers';
import { GeneralSettingModel } from '../../../reducers/settings/model';

const { Item } = Form;

export type SettingFormValues = {
  displayName: string;
  key: string;
  value: string | number;
};

export type SettingFormProps = {
  setting?: GeneralSettingModel;
  handleSubmit: (values: SettingFormValues) => void;
  handleCancelForm: () => void;
};

export const SettingForm: FC<SettingFormProps> = props => {
  const { setting, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.settings.isFetchingSettings);

  const formik = useFormik(initFormik(props, setting));

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Item
          label='Nazwa'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.displayName && formik.touched.displayName ? 'error' : ''}
          help={formik.errors.displayName && formik.touched.displayName ? formik.errors.displayName : null}
        >
          <Input
            name='displayName'
            value={formik.values.displayName}
            placeholder='Nazwa'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Klucz'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.key && formik.touched.key ? 'error' : ''}
          help={formik.errors.key && formik.touched.key ? formik.errors.key : null}
        >
          <Input
            disabled={true}
            name='key'
            value={formik.values.key}
            placeholder='Klucz'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Wartość'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.value && formik.touched.value ? 'error' : ''}
          help={formik.errors.value && formik.touched.value ? formik.errors.value : null}
        >
          <Input
            name='value'
            value={formik.values.value}
            placeholder='Wartość'
            onChange={formik.handleChange}
          />
        </Item>
        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {setting ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
