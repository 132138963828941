import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTableList } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getForkliftsStockistRequest } from '../../reducers/forklift/actions';
import { RootReducerState } from '../../reducers';
import { useViewport } from '../../utils/customHooks/useViewport';

export const ForkliftsStockList: FC = () => {
  const isFetching = useSelector<RootReducerState, boolean>(state => state.forklifts.isFetchingStockList);
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  const renderButton = (isMobile: boolean) => {
    return (
      <Button
        type={isMobile ? 'link' : 'default'}
        shape={isMobile ? 'default' : 'circle'}
        icon={
          <FontAwesomeIcon
            icon={!isFetching ? faTableList : faCircleNotch}
            size='xl'
            spin={isFetching}
          />
        }
        onClick={() => dispatch(getForkliftsStockistRequest())}
        style={isMobile ? { padding: 0 } : {}}
      >
        {isMobile ? 'Pobierz stocklistę' : ''}
      </Button>
    );
  };

  return isMobile ? (
    renderButton(isMobile)
  ) : (
    <Col>
      <Tooltip
        title='Pobierz stock listę'
        placement='bottomRight'
      >
        {renderButton(isMobile)}
      </Tooltip>
    </Col>
  );
};
