import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addMastError,
  addMastRequest,
  addMastSuccess,
  deleteMastError,
  deleteMastRequest,
  deleteMastSuccess,
  getMastsError,
  getMastsSuccess,
  updateMastError,
  updateMastRequest,
  updateMastSuccess,
} from './actions';

import * as CONST from './consts';
import { MastModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientMasts } from '../../services/apiClient/masts';

function* getMasts(): Generator<CallEffect | PutEffect, void, ResponseModel<MastModel[]>> {
  try {
    const response = yield call(apiClientMasts.getMasts);

    yield put(getMastsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get masts',
      description: getErrorMessage(err),
    });
    yield put(getMastsError(err));
  }
}

function* addMast(
  action: ActionType<typeof addMastRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<MastModel>> {
  try {
    const { mast, onSuccess } = action.payload;
    const response = yield call(apiClientMasts.addMast, mast);

    yield put(addMastSuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding mast',
      description: getErrorMessage(err),
    });
    yield put(addMastError(err));
  }
}

function* updateMast(
  action: ActionType<typeof updateMastRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<MastModel>> {
  try {
    const { mast, onSuccess } = action.payload;
    const response = yield call(apiClientMasts.updateMast, mast);

    yield put(updateMastSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating mast',
      description: getErrorMessage(err),
    });
    yield put(updateMastError(err));
  }
}

function* deleteMast(action: ActionType<typeof deleteMastRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { mastId, onSuccess } = action.payload;
    yield call(apiClientMasts.deleteMast, mastId);

    yield put(deleteMastSuccess(mastId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting mast',
      description: getErrorMessage(err),
    });
    yield put(deleteMastError(err));
  }
}

export function* watchMastsSaga(): Generator {
  yield takeLatest(CONST.GET_MASTS_REQUEST, getMasts);
  yield takeLatest(CONST.ADD_MAST_REQUEST, addMast);
  yield takeLatest(CONST.UPDATE_MAST_REQUEST, updateMast);
  yield takeLatest(CONST.DELETE_MAST_REQUEST, deleteMast);
}
