import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addEquipmentBrandError,
  addEquipmentBrandRequest,
  addEquipmentBrandSuccess,
  deleteEquipmentBrandError,
  deleteEquipmentBrandRequest,
  deleteEquipmentBrandSuccess,
  getEquipmentBrandsError,
  getEquipmentBrandsSuccess,
  updateEquipmentBrandError,
  updateEquipmentBrandRequest,
  updateEquipmentBrandSuccess,
} from './actions';

import * as CONST from './consts';
import { EquipmentBrandModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientEquipmentBrands } from '../../services/apiClient/equipmentBrands';

function* getEquipmentBrands(): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentBrandModel[]>> {
  try {
    const response = yield call(apiClientEquipmentBrands.getEquipmentBrands);

    yield put(getEquipmentBrandsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get equipment brands',
      description: getErrorMessage(err),
    });
    yield put(getEquipmentBrandsError(err));
  }
}

function* addEquipmentBrand(
  action: ActionType<typeof addEquipmentBrandRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentBrandModel>> {
  try {
    const { equipmentBrand, onSuccess } = action.payload;
    const response = yield call(apiClientEquipmentBrands.addEquipmentBrand, equipmentBrand);

    yield put(addEquipmentBrandSuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding equipment brand',
      description: getErrorMessage(err),
    });
    yield put(addEquipmentBrandError(err));
  }
}

function* updateEquipmentBrand(
  action: ActionType<typeof updateEquipmentBrandRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentBrandModel>> {
  try {
    const { equipmentBrand, onSuccess } = action.payload;
    const response = yield call(apiClientEquipmentBrands.updateEquipmentBrand, equipmentBrand);

    yield put(updateEquipmentBrandSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating equipment brand',
      description: getErrorMessage(err),
    });
    yield put(updateEquipmentBrandError(err));
  }
}

function* deleteEquipmentBrand(
  action: ActionType<typeof deleteEquipmentBrandRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { equipmentBrandId, onSuccess } = action.payload;
    yield call(apiClientEquipmentBrands.deleteEquipmentBrand, equipmentBrandId);

    yield put(deleteEquipmentBrandSuccess(equipmentBrandId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting equipment brand',
      description: getErrorMessage(err),
    });
    yield put(deleteEquipmentBrandError(err));
  }
}

export function* watchEquipmentBrandsSaga(): Generator {
  yield takeLatest(CONST.GET_EQUIPMENT_BRANDS_REQUEST, getEquipmentBrands);
  yield takeLatest(CONST.ADD_EQUIPMENT_BRAND_REQUEST, addEquipmentBrand);
  yield takeLatest(CONST.UPDATE_EQUIPMENT_BRAND_REQUEST, updateEquipmentBrand);
  yield takeLatest(CONST.DELETE_EQUIPMENT_BRAND_REQUEST, deleteEquipmentBrand);
}
