import React, { FC, useState } from 'react';
import { CostsList } from './CostsList';
import { CostForm, CostFormValues } from '../../forms/cost';
import { ForkliftModel } from '../../reducers/forklift/model';
import { CostModel, CostProduct } from '../../reducers/costs/model';
import { addCostsRequest, updateCostsRequest } from '../../reducers/costs/actions';
import { useDispatch } from 'react-redux';

export type CostsManagerProps = {
  forklift: ForkliftModel;
};

export const CostsManager: FC<CostsManagerProps> = props => {
  const { forklift } = props;
  const [selectedCost, setSelectedCost] = useState<CostModel | undefined>(undefined);
  const dispatch = useDispatch();

  const handleCostSubmit = (values: CostFormValues, callback?: () => void) => {
    if (selectedCost) {
      const cost = {
        _id: selectedCost._id,
        name: values.name,
        cost: values.cost,
        product: CostProduct.FORKLIFT,
        productId: forklift._id,
      };
      dispatch(
        updateCostsRequest(cost, () => {
          if (typeof callback === 'function') {
            callback();
            setSelectedCost(undefined);
          }
        }),
      );
    } else {
      const cost = {
        name: values.name,
        cost: values.cost,
        product: CostProduct.FORKLIFT,
        productId: forklift._id,
      };
      dispatch(
        addCostsRequest(cost, () => {
          if (typeof callback === 'function') {
            callback();
            setSelectedCost(undefined);
          }
        }),
      );
    }
  };

  return (
    <>
      <CostForm
        forklift={forklift}
        cost={selectedCost}
        handleSubmit={handleCostSubmit}
      />
      <CostsList
        forkliftId={forklift._id}
        setSelectedCost={setSelectedCost}
      />
    </>
  );
};
