import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { ClientModel } from './model';

export const getClientsRequest = () => action(CONST.GET_CLIENTS_REQUEST);
export const getClientsSuccess = (clients: ClientModel[]) => action(CONST.GET_CLIENTS_SUCCESS, { clients });
export const getClientsError = (error: unknown) => action(CONST.GET_CLIENTS_ERROR, { error });

export const addClientRequest = (
  client: Omit<ClientModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'orders'>,
  onSuccess?: (client?: ClientModel) => void,
) => action(CONST.ADD_CLIENT_REQUEST, { client, onSuccess });
export const addClientSuccess = (client: ClientModel) => action(CONST.ADD_CLIENT_SUCCESS, { client });
export const addClientError = (error: unknown) => action(CONST.ADD_CLIENT_ERROR, { error });

export const updateClientRequest = (client: ClientModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_CLIENT_REQUEST, { client, onSuccess });
export const updateClientSuccess = (client: ClientModel) => action(CONST.UPDATE_CLIENT_SUCCESS, { client });
export const updateClientError = (error: unknown) => action(CONST.UPDATE_CLIENT_ERROR, { error });

export const deleteClientRequest = (clientId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_CLIENT_REQUEST, { clientId, onSuccess });
export const deleteClientSuccess = (clientId: string) => action(CONST.DELETE_CLIENT_SUCCESS, { clientId });
export const deleteClientError = (error: unknown) => action(CONST.DELETE_CLIENT_ERROR, { error });

export const getCeidgClientRequest = (clientId: string, onSuccess: (client: ClientModel) => void) =>
  action(CONST.GET_CEIDG_CLIENT_REQUEST, { clientId, onSuccess });
export const getCeidgClientSuccess = (client: ClientModel) => action(CONST.GET_CEIDG_CLIENT_SUCCESS, { client });
export const getCeidgClientError = (error: unknown) => action(CONST.GET_CEIDG_CLIENT_ERROR, { error });
