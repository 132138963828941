import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { QueryClientProvider, QueryClient } from 'react-query';

import reportWebVitals from './reportWebVitals';
import { store } from './store';

import { App } from './App';
import { antDStyles } from './styles/styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider theme={antDStyles}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
