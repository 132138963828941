import { BaseAPI } from '../base';
import { AssetModel } from '../../../reducers/assets/model';
import { ResponseModel } from '../../../reducers/model';

class APIAssets extends BaseAPI {
  private readonly apiURL: string;
  private readonly apiURLPrivate: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/assets`;
    this.apiURLPrivate = `${this.hostAPI}/assets/private`;
  }

  public deleteAsset = async (assetId: string): Promise<ResponseModel<AssetModel[]>> => {
    return this.authFetch<ResponseModel<AssetModel[]>>(`${this.apiURL}/${assetId}`, { method: 'DELETE' });
  };

  public deleteAssetPrivate = async (assetId: string): Promise<ResponseModel<AssetModel[]>> => {
    return this.authFetch<ResponseModel<AssetModel[]>>(`${this.apiURLPrivate}/${assetId}`, { method: 'DELETE' });
  };

  public downloadPrivateAsset = (assetId: string) => {
    return this.authFetchStreamAsBase64(`${this.apiURLPrivate}/${assetId}`, {
      method: 'GET',
    });
  };
}

export const apiAssets = new APIAssets();
