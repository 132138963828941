import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { EquipmentCategoryModel } from './model';

export const getEquipmentCategoriesRequest = () => action(CONST.GET_EQUIPMENT_CATEGORIES_REQUEST);
export const getEquipmentCategoriesSuccess = (categories: EquipmentCategoryModel[]) =>
  action(CONST.GET_EQUIPMENT_CATEGORIES_SUCCESS, { categories });
export const getEquipmentCategoriesError = (error: unknown) => action(CONST.GET_EQUIPMENT_CATEGORIES_ERROR, { error });

export const addEquipmentCategoryRequest = (
  category: Omit<EquipmentCategoryModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: (category?: EquipmentCategoryModel) => void,
) => action(CONST.ADD_EQUIPMENT_CATEGORY_REQUEST, { category, onSuccess });
export const addEquipmentCategorySuccess = (category: EquipmentCategoryModel) =>
  action(CONST.ADD_EQUIPMENT_CATEGORY_SUCCESS, { category });
export const addEquipmentCategoryError = (error: unknown) => action(CONST.ADD_EQUIPMENT_CATEGORY_ERROR, { error });

export const updateEquipmentCategoryRequest = (category: EquipmentCategoryModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_EQUIPMENT_CATEGORY_REQUEST, { category, onSuccess });
export const updateEquipmentCategorySuccess = (category: EquipmentCategoryModel) =>
  action(CONST.UPDATE_EQUIPMENT_CATEGORY_SUCCESS, { category });
export const updateEquipmentCategoryError = (error: unknown) =>
  action(CONST.UPDATE_EQUIPMENT_CATEGORY_ERROR, { error });

export const deleteEquipmentCategoryRequest = (categoryId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_EQUIPMENT_CATEGORY_REQUEST, { categoryId, onSuccess });
export const deleteEquipmentCategorySuccess = (categoryId: string) =>
  action(CONST.DELETE_EQUIPMENT_CATEGORY_SUCCESS, { categoryId });
export const deleteEquipmentCategoryError = (error: unknown) =>
  action(CONST.DELETE_EQUIPMENT_CATEGORY_ERROR, { error });
