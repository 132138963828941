import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addPaintingError,
  addPaintingRequest,
  addPaintingSuccess,
  deletePaintingError,
  deletePaintingRequest,
  deletePaintingSuccess,
  getPaintingsError,
  getPaintingsSuccess,
  getSettingsError,
  getSettingsSuccess,
  updatePaintingError,
  updatePaintingRequest,
  updatePaintingSuccess,
  updateSettingError,
  updateSettingRequest,
  updateSettingSuccess,
} from './actions';

import * as CONST from './consts';
import { GeneralSettingModel, PaintingCostModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientSettings } from '../../services/apiClient/settings';

function* getPaintings(): Generator<CallEffect | PutEffect, void, ResponseModel<PaintingCostModel[]>> {
  try {
    const response = yield call(apiClientSettings.getPaintings);

    yield put(getPaintingsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get paintings',
      description: getErrorMessage(err),
    });
    yield put(getPaintingsError(err));
  }
}

function* addPainting(
  action: ActionType<typeof addPaintingRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PaintingCostModel>> {
  try {
    const { painting, onSuccess } = action.payload;
    const response = yield call(apiClientSettings.addPainting, painting);

    yield put(addPaintingSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding painting',
      description: getErrorMessage(err),
    });
    yield put(addPaintingError(err));
  }
}

function* updatePainting(
  action: ActionType<typeof updatePaintingRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PaintingCostModel>> {
  try {
    const { painting, onSuccess } = action.payload;
    const response = yield call(apiClientSettings.updatePainting, painting);

    yield put(updatePaintingSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating painting',
      description: getErrorMessage(err),
    });
    yield put(updatePaintingError(err));
  }
}

function* deletePainting(
  action: ActionType<typeof deletePaintingRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { paintingId, onSuccess } = action.payload;
    yield call(apiClientSettings.deletePainting, paintingId);

    yield put(deletePaintingSuccess(paintingId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting painting',
      description: getErrorMessage(err),
    });
    yield put(deletePaintingError(err));
  }
}

function* getSettings(): Generator<CallEffect | PutEffect, void, ResponseModel<GeneralSettingModel[]>> {
  try {
    const response = yield call(apiClientSettings.getSettings);

    yield put(getSettingsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get settings',
      description: getErrorMessage(err),
    });
    yield put(getSettingsError(err));
  }
}

function* updateSetting(
  action: ActionType<typeof updateSettingRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<GeneralSettingModel>> {
  try {
    const { setting, onSuccess } = action.payload;
    const response = yield call(apiClientSettings.updateSetting, setting);

    yield put(updateSettingSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating setting',
      description: getErrorMessage(err),
    });
    yield put(updateSettingError(err));
  }
}

export function* watchPaintingsSaga(): Generator {
  yield takeLatest(CONST.GET_PAINTING_LIST_REQUEST, getPaintings);
  yield takeLatest(CONST.ADD_PAINTING_REQUEST, addPainting);
  yield takeLatest(CONST.UPDATE_PAINTING_REQUEST, updatePainting);
  yield takeLatest(CONST.DELETE_PAINTING_REQUEST, deletePainting);
  yield takeLatest(CONST.GET_SETTINGS_LIST_REQUEST, getSettings);
  yield takeLatest(CONST.UPDATE_SETTING_REQUEST, updateSetting);
}
