import React, { FC, useEffect, useMemo } from 'react';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Dropdown, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { CurrencyType, OrderModel, OrderStatus } from '../../reducers/orders/model';
import { getOrdersRequest } from '../../reducers/orders/actions';
import { formatPrice } from '../../utils/formatPrice';
import { AdvancePaymentModel } from '../../reducers/advancePayments/model';
import { getAdvancePaymentsRequest } from '../../reducers/advancePayments/actions';
import { useViewport } from '../../utils/customHooks/useViewport';
import { MenuSC } from '../header/styles';

import './style.scss';

export const SummaryOrders: FC = () => {
  const ordersList = useSelector<RootReducerState, OrderModel[]>(state => state.orders.ordersList);
  const advancePaymentsList = useSelector<RootReducerState, AdvancePaymentModel[]>(
    state => state.advancePayment.advancePaymentList,
  );
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersRequest());
    dispatch(getAdvancePaymentsRequest());
  }, [dispatch]);

  const { totalOrders, totalOrdersEur, sumAdvances, sumAdvancesEur } = useMemo(() => {
    let totalOrders = 0;
    let totalOrdersEur = 0;
    let sumAdvances = 0;
    let sumAdvancesEur = 0;

    const ordersNotPaidAmount = ordersList.filter(order =>
      [OrderStatus.NOT_PAID, OrderStatus.CANCEL, OrderStatus.LEASING, OrderStatus.PAID_IN_ADVANCE].includes(
        order.status,
      ),
    );

    ordersNotPaidAmount.forEach(order => {
      const advancePrice = advancePaymentsList.filter(advancePayment => advancePayment.orderId === order._id);
      const advPaymentSum = advancePrice.reduce<number>((total, advPayment) => {
        return total + advPayment.price;
      }, 0);
      if (order.currency === CurrencyType.EUR) {
        totalOrdersEur += order.price;
        sumAdvancesEur += advPaymentSum;
      } else {
        totalOrders += order.price;
        sumAdvances += advPaymentSum;
      }
    });

    return { totalOrders, totalOrdersEur, sumAdvances, sumAdvancesEur };
  }, [ordersList, advancePaymentsList]);

  const items: MenuProps['items'] = [
    {
      label: (
        <Row justify='space-between'>
          <Col>Suma nie opłaconych:</Col>
          <Col>
            <Row style={{ flexDirection: 'column' }}>
              <Col className='rightTextInColumn'>
                <b className='marginLeft'>{formatPrice(totalOrders, CurrencyType.PLN)}</b>
              </Col>
              <Col className='rightTextInColumn'>
                <b className='marginLeft'>{formatPrice(totalOrdersEur, CurrencyType.EUR)}</b>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
      key: '1',
    },
    {
      label: (
        <Row justify='space-between'>
          <Col>Pozostało do zapłaty:</Col>
          <Col>
            <Row style={{ flexDirection: 'column' }}>
              <Col className='rightTextInColumn'>
                <b className='marginLeft'>{formatPrice(totalOrders - sumAdvances, CurrencyType.PLN)}</b>
              </Col>
              <Col className='rightTextInColumn'>
                <b className='marginLeft'>{formatPrice(totalOrdersEur - sumAdvancesEur, CurrencyType.EUR)}</b>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
      key: '2',
    },
  ];

  return isMobile ? (
    <MenuSC items={items} />
  ) : (
    <Col>
      <Dropdown
        menu={{ items }}
        placement='bottomRight'
        arrow
      >
        <Button
          shape='circle'
          icon={
            <FontAwesomeIcon
              icon={faHandHoldingUsd}
              size='xl'
            />
          }
        />
      </Dropdown>
    </Col>
  );
};
