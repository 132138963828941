import { BaseAPI } from '../base';
import { GeneralSettingModel, PaintingCostModel } from '../../../reducers/settings/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientSettings extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/settings`;
  }

  public getPaintings = async (): Promise<ResponseModel<PaintingCostModel[]>> => {
    return this.authFetch<ResponseModel<PaintingCostModel[]>>(`${this.apiURL}/painting-costs/`, { method: 'GET' });
  };

  public addPainting = async (data: Partial<PaintingCostModel>): Promise<ResponseModel<PaintingCostModel>> => {
    return this.authFetch<ResponseModel<PaintingCostModel>>(`${this.apiURL}/painting-costs/`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updatePainting = async (data: Partial<PaintingCostModel>): Promise<ResponseModel<PaintingCostModel>> => {
    return this.authFetch<ResponseModel<PaintingCostModel>>(`${this.apiURL}/painting-costs/`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deletePainting = async (paintingId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/painting-costs/${paintingId}`, { method: 'DELETE' });
  };

  public getSettings = async (): Promise<ResponseModel<GeneralSettingModel[]>> => {
    return this.authFetch<ResponseModel<GeneralSettingModel[]>>(`${this.apiURL}/general-settings/`, { method: 'GET' });
  };

  public updateSetting = async (data: Partial<GeneralSettingModel>): Promise<ResponseModel<GeneralSettingModel>> => {
    return this.authFetch<ResponseModel<GeneralSettingModel>>(`${this.apiURL}/general-settings/`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };
}

export const apiClientSettings = new APIClientSettings();
