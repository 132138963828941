import { UserModel, UserRole } from '../reducers/user/model';
import { Paths } from '../router/paths';

export const getUserStartingPage = (user: UserModel): Paths => {
  switch (user.role) {
    case UserRole.ADMIN:
      return Paths.HOME_PAGE;

    default:
      return Paths.HOME_PAGE;
  }
};
