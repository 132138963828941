import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { LoginFormValues, LoginFormProps } from '.';

export const initFormik = (props: LoginFormProps): FormikConfig<LoginFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    username: '',
    password: '',
  },
  validationSchema: () =>
    yup.object({
      username: yup.string().required('Użytkownik jest wymagany'),
      password: yup.string().required('Hasło jest wymagane'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
