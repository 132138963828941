import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { BrandModel } from './model';

export type BrandsAction = ActionType<typeof actions>;
export type BrandsState = {
  readonly isFetching: boolean;
  readonly brandsList: BrandModel[];
};

export const brandsReducer = combineReducers<BrandsState, BrandsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_BRANDS_REQUEST:
      case CONST.ADD_BRAND_REQUEST:
      case CONST.UPDATE_BRAND_REQUEST:
      case CONST.DELETE_BRAND_REQUEST:
        return true;

      case CONST.GET_BRANDS_SUCCESS:
      case CONST.GET_BRANDS_ERROR:
      case CONST.ADD_BRAND_SUCCESS:
      case CONST.ADD_BRAND_ERROR:
      case CONST.UPDATE_BRAND_SUCCESS:
      case CONST.UPDATE_BRAND_ERROR:
      case CONST.DELETE_BRAND_SUCCESS:
      case CONST.DELETE_BRAND_ERROR:
        return false;

      default:
        return state;
    }
  },
  brandsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_BRANDS_SUCCESS:
        return action.payload.brands;

      case CONST.ADD_BRAND_SUCCESS:
        return [action.payload.brand, ...state];

      case CONST.UPDATE_BRAND_SUCCESS: {
        const brandIdx = state.findIndex(item => item._id === action.payload.brand._id);
        state.splice(brandIdx, 1, action.payload.brand);

        return [...state];
      }

      case CONST.DELETE_BRAND_SUCCESS:
        return state.filter(brand => brand._id !== action.payload.brandId);

      default:
        return state;
    }
  },
});
