import React, { FC } from 'react';
import { Col, Image, List, Row } from 'antd';

import {
  ForkliftEngineNames,
  ProductStatusNames,
  ForkliftTable,
  ForkliftTableNames,
} from '../../reducers/forklift/model';
import { gutterInner } from '../../forms/forklift';
import { displayPrice } from '../../utils/displayPrice';
import { useGetForklift } from '../../services/apiClient/forklifts/forkliftQueries';
import { useGetSettings } from '../../services/apiClient/settings/settingsQueries';
import { GeneralSettingModel } from '../../reducers/settings/model';
import { AssetTextSC } from '../common/preview/AssetTextSC';
import { PreviewListSC } from '../common/preview/PreviewListSC';
import { RowGallerySC } from '../common/preview/RowGallerySC';

type ForkliftProps = {
  forkliftId: string;
};

export const ForkliftPreview: FC<ForkliftProps> = props => {
  const { forkliftId } = props;

  const { data: forklift, isFetching } = useGetForklift(forkliftId);
  const { data: settingsList } = useGetSettings();

  let euroExchange: GeneralSettingModel | undefined;
  if (settingsList) {
    euroExchange = settingsList.find(item => item.key === 'EUR-TO-PLN');
  }

  return (
    <Row gutter={gutterInner}>
      <Col
        xs={24}
        md={12}
      >
        <PreviewListSC
          size='small'
          loading={isFetching}
          className='previewList firstList'
        >
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.BRAND]}:`}
              description={forklift?.brand?.name}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.TYPE]}:`}
              description={forklift?.type}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.CATEGORY]}:`}
              description={forklift?.category?.name}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.ENGINE]}:`}
              description={forklift && ForkliftEngineNames[forklift.engine]}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.STATUS]}:`}
              description={forklift && ProductStatusNames[forklift.status]}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.LIFTING_CAPACITY]}:`}
              description={`${forklift?.liftingCapacity ?? ''} kg`}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.PRODUCTION_YEAR]}:`}
              description={forklift?.productionYear}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.HOURS]}:`}
              description={`${forklift?.hours ?? ''} mth`}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Cena zakupu:'
              description={`${forklift?.priceInit ? displayPrice(forklift.priceInit) : 0} PLN`}
            />
            <div>{`(${displayPrice(
              forklift?.priceInit && euroExchange?.value ? forklift.priceInit / +euroExchange.value : 0,
            )} EUR)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title={`${ForkliftTableNames[ForkliftTable.PRICE_INIT_WITH_COSTS]}:`}
              description={`${displayPrice((forklift?.priceInit ?? 0) + (forklift?.costsAmount ?? 0))} PLN`}
            />
            <div>{`(${displayPrice(
              forklift?.priceInit && euroExchange?.value
                ? (forklift.priceInit + (forklift.costsAmount ?? 0)) / +euroExchange.value
                : 0,
            )} EUR)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Wyjściowa cena klient końcowy:'
              description={`${displayPrice(forklift?.priceSuggested ?? 0)} PLN `}
            />
            <div>{`(${displayPrice(
              forklift?.priceSuggested && euroExchange?.value ? forklift.priceSuggested / +euroExchange.value : 0,
            )} EUR)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Minimalna cena klient końcowy:'
              description={`${displayPrice(forklift?.priceMin ?? 0)} PLN`}
            />
            <div>{`(${displayPrice(
              forklift?.priceMin && euroExchange?.value ? forklift.priceMin / +euroExchange.value : 0,
            )} EUR)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Sugerowana cena dealer:'
              description={`${displayPrice(Number(forklift?.priceWholesaleSuggested))} EUR`}
            />
            <div>{`(${displayPrice(
              forklift?.priceWholesaleSuggested && euroExchange?.value
                ? forklift.priceWholesaleSuggested * +euroExchange.value
                : 0,
            )} PLN)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Minimalna cena dealer:'
              description={`${displayPrice(Number(forklift?.priceWholesaleMin))} EUR`}
            />
            <div>{`(${displayPrice(
              forklift?.priceWholesaleMin && euroExchange?.value ? forklift.priceWholesaleMin * +euroExchange.value : 0,
            )} PLN)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Cena z przygotowaniem:'
              description={`${displayPrice(Number(forklift?.priceWithPreparationSuggested))} PLN`}
            />
            <div>{`(${displayPrice(
              forklift?.priceWithPreparationSuggested && euroExchange?.value
                ? forklift.priceWithPreparationSuggested / +euroExchange.value
                : 0,
            )} EUR)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Minimalna cena z przygotowaniem:'
              description={`${displayPrice(Number(forklift?.priceWithPreparationMin))} PLN`}
            />
            <div>{`(${displayPrice(
              forklift?.priceWithPreparationMin && euroExchange?.value
                ? forklift.priceWithPreparationMin / +euroExchange.value
                : 0,
            )} EUR)`}</div>
          </List.Item>
        </PreviewListSC>
      </Col>
      <Col
        xs={24}
        md={12}
      >
        <PreviewListSC
          size='small'
          className='previewList'
        >
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Numer seryjny:'
              description={forklift?.serialNumber}
            />
          </List.Item>
          {forklift?.boughtFrom && (
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Kupione od:'
                description={forklift.boughtFrom}
              />
            </List.Item>
          )}
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Maszt:'
              description={forklift?.mast?.name}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Wolny skok masztu:'
              description={`${forklift?.mastFreeLifting ?? ''} mm`}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Wysokość podnoszenia:'
              description={`${forklift?.mastFoldedHeight ?? ''} mm`}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Wysokość masztu po złożeniu:'
              description={`${forklift?.liftingHeight ?? ''} mm`}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              title='Osprzęt:'
              description={forklift?.accessories}
              className='listItemMeta breakLine'
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              title='Uwagi:'
              description={forklift?.notes}
              className='listItemMeta breakLine'
            />
          </List.Item>
        </PreviewListSC>
        {forklift?.assets && (
          <Row gutter={gutterInner}>
            <Col span={24}>
              <Row>
                <AssetTextSC>Zdjęcia:</AssetTextSC>
              </Row>
              <RowGallerySC>
                <Image.PreviewGroup>
                  {forklift.assets.map(image => (
                    <div
                      className='imageWrapper'
                      key={image._id}
                    >
                      <Image
                        className='imageGalleryElement'
                        src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${image.uri}`}
                      />
                    </div>
                  ))}
                </Image.PreviewGroup>
              </RowGallerySC>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
