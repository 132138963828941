import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { EquipmentBrandsAdditionalMenu } from './EquipmentBrandsAdditionalMenu';

type EquipmentBrandsHeaderProps = {
  onSetOpenEquipmentBrandModal: (openEquipmentBrandModal: boolean) => void;
};

export const EquipmentBrandsHeader: FC<EquipmentBrandsHeaderProps> = props => {
  const { onSetOpenEquipmentBrandModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Marki'
      onAddElementOpenModal={onSetOpenEquipmentBrandModal}
    >
      <EquipmentBrandsAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Marki'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenEquipmentBrandModal(true)}
          >
            Dodaj markę
          </Button>
        </Col>
        <EquipmentBrandsAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
