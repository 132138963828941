import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutMain } from '../../layout/main/Main';
import { RootReducerState } from '../../reducers';
import { getEquipmentsRequest } from '../../reducers/equipment/actions';
import { EquipmentList } from '../../component/equipment/EquipmentList';
import { EquipmentTabs } from './EquipmentTabs';
import { EquipmentModel } from '../../reducers/equipment/model';
import { ProductStatusListType } from '../../reducers/forklift/model';
import { isProductStatus } from '../../utils/productsStatus';
import { OrderForm, OrderFormValues } from '../../forms/orders';
import { ProductType } from '../../reducers/orders/model';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { addOrderRequest } from '../../reducers/orders/actions';
import { EquipmentHeader } from './EquipmentHeader';

export const EquipmentPage = () => {
  const equipmentList = useSelector<RootReducerState, EquipmentModel[]>(state => state.equipment.equipmentList);
  const [openEquipmentModal, setOpenEquipmentModal] = useState(false);
  const [status, setStatus] = useState<ProductStatusListType>(ProductStatusListType.AVAILABLE);
  const [groupEditEquipments, setGroupEditEquipments] = useState<string[]>([]);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const dispatch = useDispatch();

  const { equipmentsSold, equipmentsReserved, equipmentsAvailable } = useMemo(() => {
    const equipmentsSoldAmount = equipmentList.filter(equipment =>
      isProductStatus(equipment, ProductStatusListType.SOLD),
    );
    const equipmentsReserved = equipmentList.filter(equipment =>
      isProductStatus(equipment, ProductStatusListType.IN_ORDER_RESERVED),
    );
    const equipmentsAvailable = equipmentList.filter(equipment =>
      isProductStatus(equipment, ProductStatusListType.AVAILABLE),
    );

    return {
      equipmentsSold: equipmentsSoldAmount,
      equipmentsReserved: equipmentsReserved,
      equipmentsAvailable: equipmentsAvailable,
    };
  }, [equipmentList]);
  const filteredEquipmentList = useMemo(() => {
    let filteredEquipmentList: EquipmentModel[] = [];
    switch (status) {
      case ProductStatusListType.ALL:
        filteredEquipmentList = equipmentList;
        break;
      case ProductStatusListType.AVAILABLE:
        filteredEquipmentList = equipmentsAvailable;
        break;
      case ProductStatusListType.IN_ORDER_RESERVED:
        filteredEquipmentList = equipmentsReserved;
        break;
      case ProductStatusListType.SOLD:
        filteredEquipmentList = equipmentsSold;
        break;
    }

    return filteredEquipmentList;
  }, [status, equipmentList, equipmentsAvailable, equipmentsReserved, equipmentsSold]);

  const selectedEquipments = equipmentList.filter(equipment => groupEditEquipments.includes(equipment._id));

  useEffect(() => {
    dispatch(getEquipmentsRequest());
  }, [dispatch]);

  const handleSubmit = (values: OrderFormValues) => {
    dispatch(
      addOrderRequest(
        {
          ...values,
        },
        () => {
          setOpenOrderModal(false);
        },
      ),
    );
  };

  return (
    <LayoutMain>
      <EquipmentHeader
        onGroupEditEquipments={groupEditEquipments}
        onSetOpenEquipmentModal={setOpenEquipmentModal}
        onSelectedEquipments={selectedEquipments}
        onSetOpenOrderModal={setOpenOrderModal}
      />
      <EquipmentTabs
        equipmentsAmount={equipmentList.length}
        equipmentsSoldAmount={equipmentsSold.length}
        equipmentsAvailableAmount={equipmentsAvailable.length}
        equipmentsReservedAmount={equipmentsReserved.length}
        onChange={status => {
          setStatus(status);
          setGroupEditEquipments([]);
        }}
      />
      <EquipmentList
        openEquipmentModal={openEquipmentModal}
        equipmentList={filteredEquipmentList}
        clickOpenEquipmentModal={openForkliftModal => setOpenEquipmentModal(openForkliftModal)}
        groupEditStatus={groupEditEquipments}
        setGroupEditStatus={selectedKeys => setGroupEditEquipments(selectedKeys)}
        selectedEquipmentTab={status}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane zamówienia'
        titleAdd='Dodaj zamówienie'
        width={'60%'}
        openModal={openOrderModal}
        handleCloseModal={() => setOpenOrderModal(false)}
      >
        <OrderForm
          handleSubmit={handleSubmit}
          order={{
            productsInOrder: selectedEquipments.map(equipment => ({
              productId: equipment._id,
              price: equipment.price,
              notes: '',
              type: ProductType.EQUIPMENT,
              technicalReview: false,
              guarantee: false,
              udt: false,
              rectifier: false,
            })),
          }}
          handleCancelForm={() => setOpenOrderModal(false)}
        />
      </DrawerAddEdit>
    </LayoutMain>
  );
};
