import React from 'react';

import { LayoutMain } from '../../layout/main/Main';
import { MonthlyReports } from '../../component/reports/monthly/MonthlyReports';
import { ReportsHeader } from './ReportsHeader';

export const ReportsPage = () => (
  <LayoutMain>
    <ReportsHeader />
    <MonthlyReports />
  </LayoutMain>
);
