import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Input, Row, Select, Space, Switch } from 'antd';

import { PhoneModel, UserModel, UserRole, UserRoleNames } from '../../reducers/user/model';
import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';

const { Item } = Form;

export type UserFormValues = {
  name: string;
  surname: string;
  email: string;
  role: UserRole;
  active: boolean;
  phone: PhoneModel;
};

export type UserFormProps = {
  user?: UserModel;
  handleSubmit: (values: UserFormValues) => void;
  handleCancelForm: () => void;
};

export const UserForm: FC<UserFormProps> = props => {
  const { user, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.users.isFetching);

  const formik = useFormik(initFormik(props, user));

  const options = [
    {
      value: '+48',
      label: '+48',
    },
    {
      value: '+49',
      label: '+49',
    },
  ];

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Item
          label='Imię'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
          help={formik.errors.name && formik.touched.name ? formik.errors.name : null}
        >
          <Input
            name='name'
            value={formik.values.name}
            placeholder='Imię'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Nazwisko'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.surname && formik.touched.surname ? 'error' : ''}
          help={formik.errors.surname && formik.touched.surname ? formik.errors.surname : null}
        >
          <Input
            name='surname'
            value={formik.values.surname}
            placeholder='Nazwisko'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='E-mail'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.email && formik.touched.email ? 'error' : ''}
          help={formik.errors.email && formik.touched.email ? formik.errors.email : null}
        >
          <Input
            name='email'
            value={formik.values.email}
            placeholder='E-mail'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Telefon'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.phone?.value && formik.touched.phone?.value ? 'error' : ''}
          help={formik.errors.phone?.value && formik.touched.phone?.value ? formik.errors.phone.value : null}
        >
          <Space.Compact style={{ width: '100%' }}>
            <Select
              style={{ width: '15%' }}
              value={formik.values.phone.prefix}
              onChange={value => formik.setFieldValue('phone.prefix', value)}
              options={options.map(option => ({
                label: option.label,
                value: option.value,
              }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            />
            <Input
              name='phone.value'
              value={formik.values.phone.value}
              placeholder='Telefon'
              onChange={formik.handleChange}
              style={{ width: '85%' }}
            />
          </Space.Compact>
        </Item>
        <Item
          label='Rola'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.role && formik.touched.role ? 'error' : ''}
          help={formik.errors.role && formik.touched.role ? formik.errors.role : null}
        >
          <Select
            value={formik.values.role}
            style={{ width: '100%' }}
            onChange={value => formik.setFieldValue('role', value)}
            options={Object.values(UserRole).map(itemRole => ({
              label: UserRoleNames[itemRole],
              value: itemRole,
            }))}
          />
        </Item>
        <Item
          label='Aktywny'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.active && formik.touched.active ? 'error' : ''}
          help={formik.errors.active && formik.touched.active ? formik.errors.active : null}
        >
          <Switch
            checked={formik.values.active}
            onChange={value => formik.setFieldValue('active', value)}
          />
        </Item>
        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {user ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
