import React from 'react';
import { Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { ReportsAdditionalMenu } from './ReportsAdditionalMenu';

export const ReportsHeader = () => {
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile title='Raporty'>
      <ReportsAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Raporty'>
      <Row gutter={16}>
        <ReportsAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
