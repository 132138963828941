import React, { FC, useEffect } from 'react';
import { Col, Divider, List, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import {
  CurrencyType,
  CurrencyTypeNames,
  OrderModel,
  OrderStatusNames,
  ProductType,
  TransportType,
  TransportTypeNames,
} from '../../reducers/orders/model';
import { gutterInner } from '../../forms/forklift';
import { ClientTypeNames } from '../../reducers/clients/model';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { ForkliftModel } from '../../reducers/forklift/model';
import { getForkliftsRequest } from '../../reducers/forklift/actions';
import { additionalServiceIcon } from './additionalServiceIcon';
import { formatPrice } from '../../utils/formatPrice';
import { EquipmentModel } from '../../reducers/equipment/model';
import { getEquipmentsRequest } from '../../reducers/equipment/actions';
import { getCountryData } from 'countries-list';
import { TaxRates } from '../../utils/taxRates';

const { Link, Text } = Typography;

type OrderProps = {
  order: OrderModel | undefined;
};

export const OrderPreview: FC<OrderProps> = props => {
  const { order } = props;
  const forkliftList = useSelector<RootReducerState, ForkliftModel[]>(state => state.forklifts.forkliftsList);
  const equipmentList = useSelector<RootReducerState, EquipmentModel[]>(state => state.equipment.equipmentList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getForkliftsRequest());
    dispatch(getEquipmentsRequest());
  }, [dispatch]);

  const productsInOrder = order?.productsInOrder.map(product => {
    let selectedProduct: ForkliftModel | EquipmentModel | undefined;
    if (product.type === ProductType.EQUIPMENT) {
      selectedProduct = equipmentList.find(equipment => equipment._id === product.productId);
    } else {
      selectedProduct = forkliftList.find(forklift => forklift._id === product.productId);
    }

    return {
      ...product,
      brandName: selectedProduct?.brand?.name,
      serialNumber: selectedProduct?.serialNumber,
      internalId: selectedProduct?.internalId,
      productType: selectedProduct?.type,
    };
  });

  return (
    <>
      <Row gutter={gutterInner}>
        <Col span={12}>
          <List
            className='quickViewList'
            size='small'
          >
            <List.Item
              className='quickViewListElement'
              style={{ paddingTop: 0 }}
            >
              <List.Item.Meta
                title='Nazwa firmy:'
                description={order?.client?.companyName}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Typ klienta:'
                description={order && ClientTypeNames[order.clientType]}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Transport:'
                description={order && TransportTypeNames[order.transport]}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Adres do transportu:'
                description={order && order.transport === TransportType.SELLER ? order.transportAddress : 'nie dotyczy'}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Status:'
                description={order && OrderStatusNames[order.status]}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Uwagi:'
                description={order?.notes}
                className='breakLine'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Waluta:'
                description={order && CurrencyTypeNames[order.currency]}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Płatne do:'
                description={dayjs(order?.paidAt).format('DD-MM-YYYY')}
              />
            </List.Item>
          </List>
        </Col>
        <Col span={12}>
          <List
            className='quickViewList'
            size='small'
          >
            <List.Item
              className='quickViewListElement'
              style={{ paddingTop: 0 }}
            >
              <List.Item.Meta
                title='NIP:'
                description={order?.client?.companyNIP}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Adres:'
                description={`${order?.client?.companyStreet ?? ''}, ${order?.client?.companyPostCode ?? ''}, ${
                  order?.client?.companyCity ?? ''
                }, ${order?.client ? getCountryData(order.client.companyCountry).name : ''}`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Osoba kontaktowa:'
                description={`${order?.client?.contactName ?? ''} ${order?.client?.contactSurname ?? ''}`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='E-mail:'
                description={
                  <Link href={`mailto:${order?.client?.contactEmail ?? ''}`}>{order?.client?.contactEmail}</Link>
                }
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Telefon:'
                description={order?.client?.contactPhone}
              />
            </List.Item>
          </List>
        </Col>
      </Row>
      <Divider />
      <Row className='orderPreview'>
        <Col span={24}>
          <List
            className='quickViewList'
            size='small'
          >
            {productsInOrder?.map(product => (
              <List.Item
                className='quickViewListElement quickViewOrderElement'
                key={product.productId}
                extra={
                  <div className='quickViewListExtra'>
                    <div className='quickViewIconService'>
                      {product.technicalReview && additionalServiceIcon('technicalReview')}
                      {product.guarantee && additionalServiceIcon('guarantee')}
                      {product.udt && additionalServiceIcon('udt')}
                      {product.rectifier && additionalServiceIcon('rectifier')}
                    </div>
                  </div>
                }
              >
                <List.Item.Meta
                  key={product.productId}
                  title={`${product.internalId ?? ''} - ${product.brandName ?? ''} - ${
                    product.productType ?? ''
                  } - SN: ${product.serialNumber ?? ''}`}
                  description={
                    <div className='orderItem'>
                      <div className='orderQuantity'>
                        <strong>Cena:</strong>{' '}
                        <div>
                          {formatPrice(product.price, order?.currency ? order.currency : CurrencyType.PLN)}
                          {order?.client?.companyCountry === 'PL' && (
                            <Text
                              className='grossPrice'
                              type='secondary'
                            >
                              {formatPrice(product.price * TaxRates.TAX_23 + product.price, order.currency)}
                            </Text>
                          )}
                        </div>
                      </div>
                      {product.notes && (
                        <div className='orderPrice breakLine'>
                          <strong>Uwagi:</strong> <div>{product.notes}</div>
                        </div>
                      )}
                    </div>
                  }
                />
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Text strong>
            Suma: {order?.price ? formatPrice(order.price, order.currency) : 0}
            {order?.client?.companyCountry === 'PL' && (
              <Text
                className='grossPrice'
                type='secondary'
              >
                {formatPrice(order.price * TaxRates.TAX_23 + order.price, order.currency)}
              </Text>
            )}
          </Text>
        </Col>
      </Row>
    </>
  );
};
