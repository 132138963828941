import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Row, Input } from 'antd';

import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { EquipmentCategoryModel } from '../../reducers/equipmentCategories/model';

const { Item } = Form;

export type EquipmentCategoryFormValues = {
  name: string;
};

export type EquipmentCategoryFormProps = {
  equipmentCategory?: EquipmentCategoryModel;
  handleSubmit: (values: EquipmentCategoryFormValues) => void;
  handleCancelForm: () => void;
};

export const EquipmentCategoryForm: FC<EquipmentCategoryFormProps> = props => {
  const { equipmentCategory, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.categories.isFetching);

  const formik = useFormik(initFormik(props, equipmentCategory));

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Item
          label='Nazwa kategorii'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
          help={formik.errors.name && formik.touched.name ? formik.errors.name : null}
        >
          <Input
            name='name'
            value={formik.values.name}
            placeholder='Nazwa'
            onChange={formik.handleChange}
          />
        </Item>
        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {equipmentCategory ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
