import { Button, Divider, Dropdown, Flex, List, Space, Tag, Typography } from 'antd';
import {
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { ForkliftEngineNames, ForkliftModel } from '../../../reducers/forklift/model';
import { formatPrice } from '../../../utils/formatPrice';
import { CurrencyType } from '../../../reducers/orders/model';
import React, { FC, useState } from 'react';
import { ForkliftDataModel } from '../utils';
import { DividerSC, ListItemSC } from './styles';

const { Text } = Typography;

type ForkliftMobileItemProps = {
  forklift: ForkliftDataModel;
  clickOpenForkliftModal: (openForkliftModal: boolean) => void;
  setSelectedForklift: (selectedForklift: ForkliftModel) => void;
  setOpenQuickViewModal: (openQuickViewModal: boolean) => void;
  setSelectedForkliftCost: (selectedForkliftCost: ForkliftModel) => void;
  setOpenForkliftCostModal: (openForkliftCostModal: boolean) => void;
  setOpenForkliftDeleteModal: (openForkliftDeleteModal: boolean) => void;
};

export const ForkliftMobileItem: FC<ForkliftMobileItemProps> = props => {
  const {
    forklift,
    clickOpenForkliftModal,
    setSelectedForklift,
    setOpenQuickViewModal,
    setSelectedForkliftCost,
    setOpenForkliftCostModal,
    setOpenForkliftDeleteModal,
  } = props;
  const [morePrice, showMorePrice] = useState(false);

  const getItemsForActionMenu = (forklift: ForkliftDataModel) => [
    {
      label: 'Edytuj',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => {
        clickOpenForkliftModal(true);
        setSelectedForklift(forklift);
      },
    },
    {
      label: 'Podgląd',
      key: '2',
      icon: <EyeOutlined />,
      onClick: () => {
        setOpenQuickViewModal(true);
        setSelectedForklift(forklift);
      },
    },
    {
      label: 'Dodaj koszty',
      key: '3',
      icon: <DollarOutlined />,
      onClick: () => {
        setSelectedForkliftCost(forklift);
        setOpenForkliftCostModal(true);
      },
    },
    {
      label: 'Usuń',
      key: '4',
      icon: <DeleteOutlined />,
      onClick: () => handleDeleteForkliftModal(forklift),
    },
  ];

  const handleDeleteForkliftModal = (forklift: ForkliftModel) => {
    setSelectedForklift(forklift);
    setOpenForkliftDeleteModal(true);
  };

  return (
    <ListItemSC className='listItem'>
      <List.Item.Meta
        className='listItemMeta'
        avatar={
          forklift.assets.length > 0 && (
            <img
              style={{ height: '55px' }}
              alt={'test'}
              src={
                forklift.assets.length > 0
                  ? `${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${forklift.assets[0].uri}`
                  : ''
              }
            />
          )
        }
        title={
          <Flex justify='space-between'>
            <div>
              {forklift.internalId} <Divider type='vertical' /> {forklift.brand?.name} <Divider type='vertical' />{' '}
              {forklift.serialNumber}
            </div>
            <Dropdown menu={{ items: getItemsForActionMenu(forklift) }}>
              <Button
                type='text'
                aria-label='Pokaż więcej opcji'
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
          </Flex>
        }
        description={
          <Flex
            gap='4px 0'
            wrap
          >
            <Tag color='magenta'>{forklift.category?.name}</Tag>
            <Tag color='red'>{ForkliftEngineNames[forklift.engine]}</Tag>
            <Tag color='purple'>{forklift.productionYear}</Tag>
            <Tag color='geekblue'>{forklift.hours} mth</Tag>
            {forklift.mast && <Tag color='blue'>{forklift.mast.name}</Tag>}
            <Tag color='cyan'>{forklift.liftingCapacity} kg</Tag>
          </Flex>
        }
      />
      <DividerSC />
      <>
        <Flex
          justify='space-between'
          align='center'
        >
          <Text>Cena minimalna: {formatPrice(forklift.priceMin, CurrencyType.PLN)} </Text>
          <Button
            type='text'
            aria-label='Pokaż więcej cen'
            onClick={() => showMorePrice(!morePrice)}
            icon={morePrice ? <UpOutlined /> : <DownOutlined />}
          ></Button>
        </Flex>
        {morePrice && (
          <Space direction='vertical'>
            <Text>
              Cena wyjściowa: <Text strong>{formatPrice(forklift.priceSuggested, CurrencyType.PLN)}</Text>
            </Text>
            <Text>
              Cena hurt min: <Text strong>{formatPrice(forklift.priceWholesaleMin ?? 0, CurrencyType.EUR)}</Text>
            </Text>
            <Text>
              Cena hurt sugerowana:{' '}
              <Text strong>{formatPrice(forklift.priceWholesaleSuggested ?? 0, CurrencyType.EUR)}</Text>
            </Text>
            <Text>
              Cena min z przygotowaniem:{' '}
              <Text strong>{formatPrice(forklift.priceWithPreparationMin ?? 0, CurrencyType.PLN)}</Text>
            </Text>
            <Text>
              Cena z przygotowaniem:{' '}
              <Text strong>{formatPrice(forklift.priceWithPreparationSuggested ?? 0, CurrencyType.PLN)}</Text>
            </Text>
            <Text>
              Zakup + koszt: <Text strong>{formatPrice(forklift.priceInitWithCosts, CurrencyType.PLN)}</Text>
            </Text>
          </Space>
        )}
      </>
    </ListItemSC>
  );
};
