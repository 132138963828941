import { FormikConfig } from 'formik';

import { ReservationFormProps, ReservationFormValues } from '.';
import { ProductType } from '../../reducers/orders/model';

export const initFormik = (props: ReservationFormProps): FormikConfig<ReservationFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    reservationNr: props.reservation.reservationNr ?? '',
    clientId: props.reservation.clientId ?? '',
    productsInReservation:
      props.reservation.productsInReservation?.map(product => ({
        ...product,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        type: product.type ?? ProductType.FORKLIFT,
      })) ?? [],
    notes: props.reservation.notes ?? '',
  },
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
