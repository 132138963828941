import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { AbilityProvider } from './providers/ability/AbilityProvider';
import { Paths } from './router/paths';
import { LoginPage } from './pages/login/LoginPage';
import { Page404 } from './pages/404/Page404';
import { AuthRoute } from './router/auth';
import { ResetPasswordPage } from './pages/password/reset/ResetPasswordPage';
import { SetPasswordPage } from './pages/password/set/SetPasswordPage';
import { UsersPage } from './pages/users/UsersPage';
import { BrandsPage } from './pages/brands/BrandsPage';
import { CategoriesPage } from './pages/categories/CategoriesPage';
import { ForkliftsPage } from './pages/forklifts/ForkliftsPage';
import { ClientsPage } from './pages/clients/ClientsPage';
import { OrdersPage } from './pages/orders/OrdersPage';
import { SettingsPage } from './pages/settings/SettingsPage';
import { PaintingPage } from './pages/painting/PaintingPage';
import { MastsPage } from './pages/masts/MastsPage';
import { EquipmentPage } from './pages/equipment/EquipmentPage';
import { EquipmentBrandsPage } from './pages/equipmentBrands/EquipmentBrandsPage';
import { EquipmentCategoriesPage } from './pages/equipmentCategories/EquipmentCategoriesPage';
import { GeneralSettingsPage } from './pages/general/GeneralSettingsPage';
import { ReportsPage } from './pages/reports/ReportsPage';
import { OffersPage } from './pages/offers/OffersPage';

import './index.scss';

export const App: React.FC = () => {
  return (
    <AbilityProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={Paths.LOGIN_PAGE}
            element={<LoginPage />}
          />
          <Route
            path={Paths.RESET_PASSWORD}
            element={<ResetPasswordPage />}
          />
          <Route
            path={Paths.SET_PASSWORD}
            element={<SetPasswordPage />}
          />
          <Route
            path={Paths.HOME_PAGE}
            element={
              <AuthRoute>
                <ForkliftsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.BRANDS}
            element={
              <AuthRoute>
                <BrandsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.CATEGORIES}
            element={
              <AuthRoute>
                <CategoriesPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.USERS}
            element={
              <AuthRoute>
                <UsersPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.CLIENTS}
            element={
              <AuthRoute>
                <ClientsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.ORDERS}
            element={
              <AuthRoute>
                <OrdersPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.SETTINGS}
            element={
              <AuthRoute>
                <SettingsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.PAINTING}
            element={
              <AuthRoute>
                <PaintingPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.MASTS}
            element={
              <AuthRoute>
                <MastsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.EQUIPMENT}
            element={
              <AuthRoute>
                <EquipmentPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.EQUIPMENT_BRANDS}
            element={
              <AuthRoute>
                <EquipmentBrandsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.EQUIPMENT_CATEGORIES}
            element={
              <AuthRoute>
                <EquipmentCategoriesPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.GENERAL_SETTINGS}
            element={
              <AuthRoute>
                <GeneralSettingsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.REPORTS}
            element={
              <AuthRoute>
                <ReportsPage />
              </AuthRoute>
            }
          />
          <Route
            path={Paths.OFFERS}
            element={
              <AuthRoute>
                <OffersPage />
              </AuthRoute>
            }
          />
          <Route element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </AbilityProvider>
  );
};

export default App;
