import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { ReportSellModel } from './model';

export type ReportsAction = ActionType<typeof actions>;
export type ReportsState = {
  readonly isFetching: boolean;
  readonly reportsList: ReportSellModel[];
};

export const reportsReducer = combineReducers<ReportsState, ReportsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_REPORTS_REQUEST:
        return true;

      case CONST.GET_REPORTS_SUCCESS:
      case CONST.GET_REPORTS_ERROR:
        return false;

      default:
        return state;
    }
  },
  reportsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_REPORTS_SUCCESS:
        return action.payload.brands;

      default:
        return state;
    }
  },
});
