import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const AssetTextSC = styled(Text)`
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  font-weight: 700;
  padding: 8px 0;
  width: 100%;
`;
