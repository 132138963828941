export const GET_CLIENTS_REQUEST = '@clients/GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = '@clients/GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_ERROR = '@clients/GET_CLIENTS_ERROR';

export const ADD_CLIENT_REQUEST = '@clients/ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = '@clients/ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR = '@clients/ADD_CLIENT_ERROR';

export const UPDATE_CLIENT_REQUEST = '@clients/UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = '@clients/UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_ERROR = '@clients/UPDATE_CLIENT_ERROR';

export const DELETE_CLIENT_REQUEST = '@clients/DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = '@clients/DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_ERROR = '@clients/DELETE_CLIENT_ERROR';

export const GET_CEIDG_CLIENT_REQUEST = '@clients/GET_CEIDG_CLIENT_REQUEST';
export const GET_CEIDG_CLIENT_SUCCESS = '@clients/GET_CEIDG_CLIENT_SUCCESS';
export const GET_CEIDG_CLIENT_ERROR = '@clients/GET_CEIDG_CLIENT_ERROR';
