import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { ReservationModel } from './model';

export type ReservationsAction = ActionType<typeof actions>;
export type ReservationsState = {
  readonly isFetching: boolean;
  readonly reservationsList: ReservationModel[];
};

export const reservationsReducer = combineReducers<ReservationsState, ReservationsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.ADD_RESERVATION_REQUEST:
        return true;

      case CONST.ADD_RESERVATION_SUCCESS:
      case CONST.ADD_RESERVATION_ERROR:
        return false;

      default:
        return state;
    }
  },
  reservationsList: (state = [], action) => {
    switch (action.type) {
      case CONST.ADD_RESERVATION_SUCCESS:
        return [action.payload.reservation, ...state];

      default:
        return state;
    }
  },
});
