import React, { FC, useState } from 'react';
import { ResetPasswordForm, ResetPasswordFormValues } from '../../../forms/resetPassword';
import { resetPasswordRequest } from '../../../reducers/user/actions';
import { useDispatch } from 'react-redux';

export const ResetPasswordPage: FC = () => {
  const dispatch = useDispatch();

  const [showMessage, setShowMessage] = useState<boolean>(false);

  const handleResetPasswordSubmit = (values: ResetPasswordFormValues) => {
    dispatch(
      resetPasswordRequest(values.email, () => {
        setShowMessage(true);
      }),
    );
  };

  return (
    <ResetPasswordForm
      handleSubmit={handleResetPasswordSubmit}
      showMessage={showMessage}
    />
  );
};
