import { all } from 'redux-saga/effects';
import { watchUserSaga } from './user/saga';
import { watchAssetsSaga } from './assets/saga';
import { watchBrandsSaga } from './brands/saga';
import { watchCategoriesSaga } from './categories/saga';
import { watchForkliftsSaga } from './forklift/saga';
import { watchClientsSaga } from './clients/saga';
import { watchOrdersSaga } from './orders/saga';
import { watchPaintingsSaga } from './settings/saga';
import { watchCostsSaga } from './costs/saga';
import { watchMastsSaga } from './masts/saga';
import { watchEquipmentsSaga } from './equipment/saga';
import { watchEquipmentBrandsSaga } from './equipmentBrands/saga';
import { watchEquipmentCategoriesSaga } from './equipmentCategories/saga';
import { watchReportsSaga } from './reports/saga';
import { watchReservationsSaga } from './reservations/saga';
import { watchAdvancePaymentsSaga } from './advancePayments/saga';
import { watchOffersSaga } from './offers/saga';

export default function* rootSaga(): Generator {
  yield all([
    watchUserSaga(),
    watchAssetsSaga(),
    watchBrandsSaga(),
    watchCategoriesSaga(),
    watchForkliftsSaga(),
    watchClientsSaga(),
    watchOrdersSaga(),
    watchPaintingsSaga(),
    watchCostsSaga(),
    watchMastsSaga(),
    watchEquipmentsSaga(),
    watchEquipmentBrandsSaga(),
    watchEquipmentCategoriesSaga(),
    watchReportsSaga(),
    watchReservationsSaga(),
    watchAdvancePaymentsSaga(),
    watchOffersSaga(),
  ]);
}
