import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { BrandFormProps, BrandFormValues } from '.';
import { BrandModel } from '../../reducers/brands/model';

export const initFormik = (props: BrandFormProps, brand: BrandModel | undefined): FormikConfig<BrandFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: brand?.name ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Nazwa jest wymagana'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
