import { Button, Col, Dropdown, MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

export const LogoutButton: FC<MenuProps> = props => {
  const { items } = props;
  return (
    <Col>
      <Dropdown menu={{ items }}>
        <Button
          shape='circle'
          icon={<UserOutlined />}
        />
      </Dropdown>
    </Col>
  );
};
