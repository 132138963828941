import React, { FC, useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { EquipmentCategoryModel } from '../../reducers/equipmentCategories/model';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { EquipmentCategoryFormValues } from '../../forms/equipmentCategory';
import {
  addEquipmentCategoryRequest,
  deleteEquipmentCategoryRequest,
  getEquipmentCategoriesRequest,
  updateEquipmentCategoryRequest,
} from '../../reducers/equipmentCategories/actions';
import { EquipmentCategoryForm } from '../../forms/equipmentCategory';

type EquipmentCategoriesListProps = {
  openEquipmentCategoryModal: boolean;
  clickOpenEquipmentCategoryModal: (openEquipmentCategoryModal: boolean) => void;
};

export const EquipmentCategoriesList: FC<EquipmentCategoriesListProps> = props => {
  const { openEquipmentCategoryModal, clickOpenEquipmentCategoryModal } = props;
  const equipmentCategoriesList = useSelector<RootReducerState, EquipmentCategoryModel[]>(
    state => state.equipmentCategories.categoriesList,
  );
  const isFetching = useSelector<RootReducerState, boolean>(state => state.equipmentCategories.isFetching);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();
  const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState<EquipmentCategoryModel | undefined>(
    undefined,
  );
  const [, setDeleteEquipmentCategoryModal] = useState(false);

  useEffect(() => {
    dispatch(getEquipmentCategoriesRequest());
  }, [dispatch]);

  const handleEditEquipmentCategoryModal = (categoryId: string) => {
    const category = equipmentCategoriesList.find(item => item._id === categoryId);
    setSelectedEquipmentCategory(category);
    clickOpenEquipmentCategoryModal(true);
  };

  const handleCloseEquipmentCategoryModal = () => {
    setSelectedEquipmentCategory(undefined);
    clickOpenEquipmentCategoryModal(false);
  };

  const handleDeleteEquipmentCategoryModal = (categoryId: string) => {
    dispatch(
      deleteEquipmentCategoryRequest(categoryId, () => {
        setDeleteEquipmentCategoryModal(false);
      }),
    );
  };

  const handleSubmit = (values: EquipmentCategoryFormValues) => {
    if (selectedEquipmentCategory) {
      dispatch(
        updateEquipmentCategoryRequest(
          {
            ...selectedEquipmentCategory,
            ...values,
          },
          () => {
            setSelectedEquipmentCategory(undefined);
            clickOpenEquipmentCategoryModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addEquipmentCategoryRequest(values, () => {
          setSelectedEquipmentCategory(undefined);
          clickOpenEquipmentCategoryModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<EquipmentCategoryModel> = [
    {
      title: 'Kategoria',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ...getColumnSearchProps('name', 'kategorii'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj kategorię'
              onClick={() => handleEditEquipmentCategoryModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie kategorii'
              description='Czy na pewno chcesz usunąć kategorię?'
              onConfirm={() => handleDeleteEquipmentCategoryModal(_id)}
              onCancel={() => setDeleteEquipmentCategoryModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń kategorię'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={equipmentCategoriesList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane kategorii'
        titleAdd='Dodaj kategorię'
        openModal={openEquipmentCategoryModal}
        handleCloseModal={handleCloseEquipmentCategoryModal}
        selectedItem={selectedEquipmentCategory}
      >
        <EquipmentCategoryForm
          equipmentCategory={selectedEquipmentCategory}
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseEquipmentCategoryModal}
        />
      </DrawerAddEdit>
    </>
  );
};
