import { BaseAPI } from '../base';
import { ReservationModel } from '../../../reducers/reservations/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientReservations extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/reservations`;
  }

  public addReservation = async (data: Partial<ReservationModel>): Promise<ResponseModel<ReservationModel>> => {
    delete data.reservationNr;
    return this.authFetch<ResponseModel<ReservationModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };
}

export const apiClientReservations = new APIClientReservations();
