import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Form, Input, Radio, Row, Select } from 'antd';

import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';
import { ClientModel, ClientType, ClientTypeNames } from '../../reducers/clients/model';
import { countriesMap } from '../../utils/countries/countries';
import type { SearchProps } from 'antd/es/input/Search';
import { getCeidgClientRequest } from '../../reducers/clients/actions';
import { TCountryCode } from 'countries-list';

const { Item } = Form;
const { Search } = Input;

export type ClientFormValues = {
  companyNIP: string;
  companyName: string;
  companyStreet: string;
  companyPostCode: string;
  companyCity: string;
  companyCountry: TCountryCode;
  contactName: string;
  contactSurname: string;
  contactEmail: string;
  contactPhone: string;
  clientType: ClientType;
};

export type ClientFormProps = {
  client?: ClientModel;
  handleSubmit: (values: ClientFormValues) => void;
  handleCancelForm: () => void;
};

export const ClientForm: FC<ClientFormProps> = props => {
  const { client, handleCancelForm } = props;
  const [clientForForm, setClientForForm] = useState<ClientModel | undefined>(client);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.clients.isFetching);
  const isFetchingCeidgClient = useSelector<RootReducerState, boolean>(state => state.clients.isFetchingCeidgClient);

  const dispatch = useDispatch();
  const formik = useFormik(initFormik(props, clientForForm));

  const handleSearchClient: SearchProps['onSearch'] = value => {
    dispatch(
      getCeidgClientRequest(value, data => {
        setClientForForm(data);
      }),
    );
  };

  return (
    <div className='wrapperForm'>
      <Form
        layout='vertical'
        disabled={isFetchingCeidgClient}
      >
        <Item
          label='NIP'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.companyNIP && formik.touched.companyNIP ? 'error' : ''}
          help={formik.errors.companyNIP && formik.touched.companyNIP ? formik.errors.companyNIP : null}
        >
          <Search
            name='companyNIP'
            value={formik.values.companyNIP}
            onChange={formik.handleChange}
            placeholder='NIP'
            onSearch={handleSearchClient}
          />
        </Item>
        <Item
          label='Pełna nazwa firmy'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.companyName && formik.touched.companyName ? 'error' : ''}
          help={formik.errors.companyName && formik.touched.companyName ? formik.errors.companyName : null}
        >
          <Input
            name='companyName'
            value={formik.values.companyName}
            placeholder='Pełna nazwa firmy'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Typ klienta'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.clientType && formik.touched.clientType ? 'error' : ''}
          help={formik.errors.clientType && formik.touched.clientType ? formik.errors.clientType : null}
        >
          <Radio.Group
            name='clientType'
            onChange={formik.handleChange}
            value={formik.values.clientType}
            options={Object.values(ClientType).map(client => ({
              label: ClientTypeNames[client],
              value: client,
            }))}
          />
        </Item>
        <Item
          label='Ulica (nr budynku/nr lokalu)'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.companyStreet && formik.touched.companyStreet ? 'error' : ''}
          help={formik.errors.companyStreet && formik.touched.companyStreet ? formik.errors.companyStreet : null}
        >
          <Input
            name='companyStreet'
            value={formik.values.companyStreet}
            placeholder='Ulica (nr budynku/nr lokalu)'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Kod pocztowy'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.companyPostCode && formik.touched.companyPostCode ? 'error' : ''}
          help={formik.errors.companyPostCode && formik.touched.companyPostCode ? formik.errors.companyPostCode : null}
        >
          <Input
            name='companyPostCode'
            value={formik.values.companyPostCode}
            placeholder='Kod pocztowy'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Miejscowość'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.companyCity && formik.touched.companyCity ? 'error' : ''}
          help={formik.errors.companyCity && formik.touched.companyCity ? formik.errors.companyCity : null}
        >
          <Input
            name='companyCity'
            value={formik.values.companyCity}
            placeholder='Miejscowość'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Kraj'
          required={true}
          validateStatus={formik.errors.companyCountry && formik.touched.companyCountry ? 'error' : ''}
          hasFeedback={true}
          help={formik.errors.companyCountry && formik.touched.companyCountry ? formik.errors.companyCountry : null}
        >
          <Select
            showSearch
            value={formik.values.companyCountry}
            onChange={(value: string) => formik.setFieldValue('companyCountry', value)}
            filterOption={(input: string, option: any) =>
              (option?.value ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={Object.values(countriesMap).map(country => ({
              label: `${country.flag} ${country.name}`,
              value: country.shortName,
            }))}
          />
        </Item>
        <Item
          label='Imię osoby kontaktowej'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.contactName && formik.touched.contactName ? 'error' : ''}
          help={formik.errors.contactName && formik.touched.contactName ? formik.errors.contactName : null}
        >
          <Input
            name='contactName'
            value={formik.values.contactName}
            placeholder='Imię osoby kontaktowej'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Nazwisko osoby kontaktowej'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.contactSurname && formik.touched.contactSurname ? 'error' : ''}
          help={formik.errors.contactSurname && formik.touched.contactSurname ? formik.errors.contactSurname : null}
        >
          <Input
            name='contactSurname'
            value={formik.values.contactSurname}
            placeholder='Nazwisko osoby kontaktowej'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='E-mail osoby kontaktowej'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.contactEmail && formik.touched.contactEmail ? 'error' : ''}
          help={formik.errors.contactEmail && formik.touched.contactEmail ? formik.errors.contactEmail : null}
        >
          <Input
            name='contactEmail'
            value={formik.values.contactEmail}
            placeholder='E-mail osoby kontaktowej'
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Telefon osoby kontaktowej'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.contactPhone && formik.touched.contactPhone ? 'error' : ''}
          help={formik.errors.contactPhone && formik.touched.contactPhone ? formik.errors.contactPhone : null}
        >
          <Input
            name='contactPhone'
            value={formik.values.contactPhone}
            placeholder='Telefon osoby kontaktowej'
            onChange={formik.handleChange}
          />
        </Item>
        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {client ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
