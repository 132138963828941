import styled from 'styled-components';
import { Layout } from 'antd';
import { breakpoints } from '../../styles/breakpoints';

const { Header } = Layout;

export const HeaderSC = styled(Header)`
  background-color: #33558b;
  height: 60px;
  padding: 0 10px 0 12px;

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 20px 0 30px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding: 0 20px 0 40px;
  }

  h2 {
    color: #fff;
    font-size: 20px;

    @media screen and (min-width: ${breakpoints.xl}) {
      font-size: 30px;
      margin: 7px 0 0 0;
    }
  }
`;
