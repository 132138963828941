import { BaseAPI } from '../base';
import { BrandModel } from '../../../reducers/brands/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientBrands extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/brands`;
  }

  public getBrands = async (): Promise<ResponseModel<BrandModel[]>> => {
    return this.authFetch<ResponseModel<BrandModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addBrand = async (
    data: Omit<BrandModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<BrandModel>> => {
    return this.authFetch<ResponseModel<BrandModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateBrand = async (data: Partial<BrandModel>): Promise<ResponseModel<BrandModel>> => {
    return this.authFetch<ResponseModel<BrandModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteBrand = async (brandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${brandId}`, { method: 'DELETE' });
  };
}

export const apiClientBrands = new APIClientBrands();
