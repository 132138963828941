export const GET_BRANDS_REQUEST = '@brands/GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = '@brands/GET_BRANDS_SUCCESS';
export const GET_BRANDS_ERROR = '@brands/GET_BRANDS_ERROR';

export const ADD_BRAND_REQUEST = '@brands/ADD_BRAND_REQUEST';
export const ADD_BRAND_SUCCESS = '@brands/ADD_BRAND_SUCCESS';
export const ADD_BRAND_ERROR = '@brands/ADD_BRAND_ERROR';

export const UPDATE_BRAND_REQUEST = '@brands/UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = '@brands/UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = '@brands/UPDATE_BRAND_ERROR';

export const DELETE_BRAND_REQUEST = '@brands/DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = '@brands/DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = '@brands/DELETE_BRAND_ERROR';
