export const GET_FORKLIFTS_REQUEST = '@forklifts/GET_FORKLIFTS_REQUEST';
export const GET_FORKLIFTS_SUCCESS = '@forklifts/GET_FORKLIFTS_SUCCESS';
export const GET_FORKLIFTS_ERROR = '@forklifts/GET_FORKLIFTS_ERROR';

export const GET_AVAILABLE_FORKLIFTS_REQUEST = '@forklifts/GET_AVAILABLE_FORKLIFTS_FORKLIFTS_REQUEST';
export const GET_AVAILABLE_FORKLIFTS_SUCCESS = '@forklifts/GET_AVAILABLE_FORKLIFTS_FORKLIFTS_SUCCESS';
export const GET_AVAILABLE_FORKLIFTS_ERROR = '@forklifts/GET_AVAILABLE_FORKLIFTS_FORKLIFTS_ERROR';

export const ADD_FORKLIFT_REQUEST = '@forklifts/ADD_FORKLIFT_REQUEST';
export const ADD_FORKLIFT_SUCCESS = '@forklifts/ADD_FORKLIFT_SUCCESS';
export const ADD_FORKLIFT_ERROR = '@forklifts/ADD_FORKLIFT_ERROR';

export const UPDATE_FORKLIFT_REQUEST = '@forklifts/UPDATE_FORKLIFT_REQUEST';
export const UPDATE_FORKLIFT_SUCCESS = '@forklifts/UPDATE_FORKLIFT_SUCCESS';
export const UPDATE_FORKLIFT_ERROR = '@forklifts/UPDATE_FORKLIFT_ERROR';

export const DELETE_FORKLIFT_REQUEST = '@forklifts/DELETE_FORKLIFT_REQUEST';
export const DELETE_FORKLIFT_SUCCESS = '@forklifts/DELETE_FORKLIFT_SUCCESS';
export const DELETE_FORKLIFT_ERROR = '@forklifts/DELETE_FORKLIFT_ERROR';

export const UPDATE_FORKLIFTS_STATUSES_REQUEST = '@forklifts/UPDATE_FORKLIFTS_STATUSES_REQUEST';
export const UPDATE_FORKLIFTS_STATUSES_SUCCESS = '@forklifts/UPDATE_FORKLIFTS_STATUSES_SUCCESS';
export const UPDATE_FORKLIFTS_STATUSES_ERROR = '@forklifts/UPDATE_FORKLIFTS_STATUSES_ERROR';

export const GET_FORKLIFT_REQUEST = '@forklifts/GET_FORKLIFT_REQUEST';
export const GET_FORKLIFT_SUCCESS = '@forklifts/GET_FORKLIFT_SUCCESS';
export const GET_FORKLIFT_ERROR = '@forklifts/GET_FORKLIFT_ERROR';

export const GET_FORKLIFT_INTERNAL_ID_REQUEST = '@forklifts/GET_FORKLIFT_INTERNAL_ID_REQUEST';
export const GET_FORKLIFT_INTERNAL_ID_SUCCESS = '@forklifts/GET_FORKLIFT_INTERNAL_ID_SUCCESS';
export const GET_FORKLIFT_INTERNAL_ID_ERROR = '@forklifts/GET_FORKLIFT_INTERNAL_ID_ERROR';

export const GET_FORKLIFTS_STOCK_LIST_REQUEST = '@forklifts/GET_FORKLIFTS_STOCK_LIST_REQUEST';
export const GET_FORKLIFTS_STOCK_LIST_SUCCESS = '@forklifts/GET_FORKLIFTS_STOCK_LIST_SUCCESS';
export const GET_FORKLIFTS_STOCK_LIST_ERROR = '@forklifts/GET_FORKLIFTS_STOCK_LIST_ERROR';
