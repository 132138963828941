import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { ClientModel } from './model';

export type ClientsAction = ActionType<typeof actions>;
export type ClientsState = {
  readonly isFetching: boolean;
  readonly clientsList: ClientModel[];
  readonly isFetchingCeidgClient: boolean;
};

export const clientsReducer = combineReducers<ClientsState, ClientsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_CLIENTS_REQUEST:
      case CONST.ADD_CLIENT_REQUEST:
      case CONST.UPDATE_CLIENT_REQUEST:
      case CONST.DELETE_CLIENT_REQUEST:
        return true;

      case CONST.GET_CLIENTS_SUCCESS:
      case CONST.GET_CLIENTS_ERROR:
      case CONST.ADD_CLIENT_SUCCESS:
      case CONST.ADD_CLIENT_ERROR:
      case CONST.UPDATE_CLIENT_SUCCESS:
      case CONST.UPDATE_CLIENT_ERROR:
      case CONST.DELETE_CLIENT_SUCCESS:
      case CONST.DELETE_CLIENT_ERROR:
        return false;

      default:
        return state;
    }
  },
  clientsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_CLIENTS_SUCCESS:
        return action.payload.clients;

      case CONST.ADD_CLIENT_SUCCESS:
        return [action.payload.client, ...state];

      case CONST.UPDATE_CLIENT_SUCCESS: {
        const clientIdx = state.findIndex(item => item._id === action.payload.client._id);
        state.splice(clientIdx, 1, action.payload.client);

        return [...state];
      }

      case CONST.DELETE_CLIENT_SUCCESS:
        return state.filter(client => client._id !== action.payload.clientId);

      default:
        return state;
    }
  },
  isFetchingCeidgClient: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_CEIDG_CLIENT_REQUEST:
        return true;

      case CONST.GET_CEIDG_CLIENT_SUCCESS:
      case CONST.GET_CEIDG_CLIENT_ERROR:
        return false;

      default:
        return state;
    }
  },
});
