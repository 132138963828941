export const GET_ORDERS_REQUEST = '@orders/GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = '@orders/GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = '@orders/GET_ORDERS_ERROR';

export const ADD_ORDER_REQUEST = '@orders/ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = '@orders/ADD_ORDER_SUCCESS';
export const ADD_ORDER_ERROR = '@orders/ADD_ORDER_ERROR';

export const UPDATE_ORDER_REQUEST = '@orders/UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = '@orders/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = '@orders/UPDATE_ORDER_ERROR';

export const DELETE_ORDER_REQUEST = '@orders/DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = '@orders/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR = '@orders/DELETE_ORDER_ERROR';

export const GET_ORDER_PDF_REQUEST = '@orders/GET_ORDER_PDF_REQUEST';
export const GET_ORDER_PDF_SUCCESS = '@orders/GET_ORDER_PDF_SUCCESS';
export const GET_ORDER_PDF_ERROR = '@orders/GET_ORDER_PDF_ERROR';
