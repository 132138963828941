import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { OrderFormProps, OrderFormValues } from '.';
import { CurrencyType, OrderStatus, ProductType, TransportType } from '../../reducers/orders/model';
import { ClientType } from '../../reducers/clients/model';

export const initFormik = (props: OrderFormProps): FormikConfig<OrderFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    orderNr: props.order?.orderNr ?? '',
    clientId: props.order?.clientId ?? '',
    status: props.order?.status ?? OrderStatus.NOT_PAID,
    productsInOrder:
      props.order?.productsInOrder?.map(product => ({
        ...product,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        type: product.type ?? ProductType.FORKLIFT,
      })) ?? [],
    paidAt: props.order?.paidAt ?? '',
    notes: props.order?.notes ?? '',
    clientType: props.order?.clientType as ClientType,
    transport: props.order?.transport as TransportType,
    transportAddress: props.order?.transportAddress ?? '',
    currency: props.order?.currency ?? CurrencyType.PLN,
    invoice: props.order?.invoice ?? '',
  },
  validationSchema: () =>
    yup.object({
      clientId: yup.string().required('Pole jest wymagane'),
      status: yup.string().required('Pole jest wymagane'),
      paidAt: yup.string().required('Pole jest wymagane'),
      productsInOrder: yup
        .array()
        .min(1, 'Co najmniej 1 produkt jest wymagany')
        .of(
          yup
            .object({
              productId: yup.string().required('Pole jest wymagane'),
              price: yup.number().required('Pole jest wymagane'),
            })
            .required('Pole jest wymagane'),
        ),
      clientType: yup.string().required('Pole jest wymagane'),
      transport: yup.string().required('Pole jest wymagane'),
      currency: yup.string().required('Pole jest wymagane'),
      transportAddress: yup.string().when('transport', ([transport], schema) => {
        return transport === TransportType.SELLER ? schema.required('Pole jest wymagane') : schema.min(0);
      }),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
