import { FC } from 'react';
import { loginRequest } from '../../reducers/user/actions';
import { LoginFormValues, LoginForm } from '../../forms/login';
import { useDispatch } from 'react-redux';

export const LoginPage: FC = () => {
  const dispatch = useDispatch();

  const handleLoginSubmit = (values: LoginFormValues) => {
    dispatch(loginRequest(values.username, values.password));
  };

  return <LoginForm handleSubmit={handleLoginSubmit} />;
};
