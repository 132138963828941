import React, { useState } from 'react';

import { LayoutMain } from '../../layout/main/Main';
import { OffersList } from '../../component/offers/OffersList';
import { OffersHeader } from './OffersHeader';

export const OffersPage = () => {
  const [openOfferModal, setOpenOfferModal] = useState(false);

  return (
    <LayoutMain>
      <OffersHeader onSetOpenOfferModal={openOfferModal => setOpenOfferModal(openOfferModal)} />
      <OffersList
        openOfferModal={openOfferModal}
        clickOpenOfferModal={openOfferModal => setOpenOfferModal(openOfferModal)}
      />
    </LayoutMain>
  );
};
