import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { MastsAdditionalMenu } from './MastsAdditionalMenu';
//import { BrandsAdditionalMenu } from './BrandsAdditionalMenu';

type MastsHeaderProps = {
  onSetOpenMastModal: (openMastModal: boolean) => void;
};

export const MastsHeader: FC<MastsHeaderProps> = props => {
  const { onSetOpenMastModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Maszty'
      onAddElementOpenModal={onSetOpenMastModal}
    >
      <MastsAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Maszty'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenMastModal(true)}
          >
            Dodaj maszt
          </Button>
        </Col>
        <MastsAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
