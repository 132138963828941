export const GET_ADVANCE_PAYMENTS_REQUEST = '@advancePayments/GET_ADVANCE_PAYMENTS_REQUEST';
export const GET_ADVANCE_PAYMENTS_SUCCESS = '@advancePayments/GET_ADVANCE_PAYMENTS_SUCCESS';
export const GET_ADVANCE_PAYMENTS_ERROR = '@advancePayments/GET_ADVANCE_PAYMENTS_ERROR';

export const GET_ORDER_ADVANCE_PAYMENTS_REQUEST = '@advancePayments/GET_ORDER_ADVANCE_PAYMENTS_REQUEST';
export const GET_ORDER_ADVANCE_PAYMENTS_SUCCESS = '@advancePayments/GET_ORDER_ADVANCE_PAYMENTS_SUCCESS';
export const GET_ORDER_ADVANCE_PAYMENTS_ERROR = '@advancePayments/GET_ORDER_ADVANCE_PAYMENTS_ERROR';

export const ADD_ADVANCE_PAYMENT_REQUEST = '@advancePayments/ADD_ADVANCE_PAYMENT_REQUEST';
export const ADD_ADVANCE_PAYMENT_SUCCESS = '@advancePayments/ADD_ADVANCE_PAYMENT_SUCCESS';
export const ADD_ADVANCE_PAYMENT_ERROR = '@advancePayments/ADD_ADVANCE_PAYMENT_ERROR';

export const UPDATE_ADVANCE_PAYMENT_REQUEST = '@advancePayments/UPDATE_ADVANCE_PAYMENT_REQUEST';
export const UPDATE_ADVANCE_PAYMENT_SUCCESS = '@advancePayments/UPDATE_ADVANCE_PAYMENT_SUCCESS';
export const UPDATE_ADVANCE_PAYMENT_ERROR = '@advancePayments/UPDATE_ADVANCE_PAYMENT_ERROR';

export const DELETE_ADVANCE_PAYMENT_REQUEST = '@advancePayments/DELETE_ADVANCE_PAYMENT_REQUEST';
export const DELETE_ADVANCE_PAYMENT_SUCCESS = '@advancePayments/DELETE_ADVANCE_PAYMENT_SUCCESS';
export const DELETE_ADVANCE_PAYMENT_ERROR = '@advancePayments/DELETE_ADVANCE_PAYMENT_ERROR';

export const GET_ADVANCE_PAYMENT_REQUEST = '@advancePayments/GET_ADVANCE_PAYMENT_REQUEST';
export const GET_ADVANCE_PAYMENT_SUCCESS = '@advancePayments/GET_ADVANCE_PAYMENT_SUCCESS';
export const GET_ADVANCE_PAYMENT_ERROR = '@advancePayments/GET_ADVANCE_PAYMENT_ERROR';
