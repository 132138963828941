import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addEquipmentError,
  addEquipmentRequest,
  addEquipmentSuccess,
  deleteEquipmentError,
  deleteEquipmentRequest,
  deleteEquipmentSuccess,
  getEquipmentError,
  getEquipmentInternalIdError,
  getEquipmentInternalIdSuccess,
  getEquipmentRequest,
  getEquipmentsError,
  getEquipmentsSuccess,
  getEquipmentStockistError,
  getEquipmentStockistRequest,
  getEquipmentStockistSuccess,
  getEquipmentSuccess,
  updateEquipmentError,
  updateEquipmentRequest,
  updateEquipmentsStatusesError,
  updateEquipmentsStatusesRequest,
  updateEquipmentsStatusesSuccess,
  updateEquipmentSuccess,
} from './actions';

import * as CONST from './consts';
import { EquipmentInternalId, EquipmentModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientEquipments } from '../../services/apiClient/equipments';
import { getOrdersRequest } from '../orders/actions';

function* getEquipments(): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentModel[]>> {
  try {
    const response = yield call(apiClientEquipments.getEquipments);

    yield put(getEquipmentsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get equipments',
      description: getErrorMessage(err),
    });
    yield put(getEquipmentsError(err));
  }
}

function* addEquipment(
  action: ActionType<typeof addEquipmentRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentModel>> {
  try {
    const { equipment, onSuccess } = action.payload;
    const response = yield call(apiClientEquipments.addEquipment, equipment);

    yield put(addEquipmentSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding equipment',
      description: getErrorMessage(err),
    });
    yield put(addEquipmentError(err));
  }
}

function* updateEquipment(
  action: ActionType<typeof updateEquipmentRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentModel>> {
  try {
    const { equipment, onSuccess } = action.payload;
    const response = yield call(apiClientEquipments.updateEquipment, equipment);

    yield put(updateEquipmentSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating equipment',
      description: getErrorMessage(err),
    });
    yield put(updateEquipmentError(err));
  }
}

function* deleteEquipment(
  action: ActionType<typeof deleteEquipmentRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { equipmentId, onSuccess } = action.payload;
    yield call(apiClientEquipments.deleteEquipment, equipmentId);

    yield put(deleteEquipmentSuccess(equipmentId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting equipment',
      description: getErrorMessage(err),
    });
    yield put(deleteEquipmentError(err));
  }
}

function* updateEquipmentsStatuses(
  action: ActionType<typeof updateEquipmentsStatusesRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentModel[]>> {
  try {
    const { equipmentsStatuses, onSuccess } = action.payload;
    const response = yield call(apiClientEquipments.updateEquipmentStatus, equipmentsStatuses);

    yield put(updateEquipmentsStatusesSuccess(response.data));
    yield put(getOrdersRequest());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating equipments statuses',
      description: getErrorMessage(err),
    });
    yield put(updateEquipmentsStatusesError(err));
  }
}

function* getEquipment(
  action: ActionType<typeof getEquipmentRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentModel>> {
  try {
    const { equipmentId } = action.payload;
    const response = yield call(apiClientEquipments.getEquipment, equipmentId);

    yield put(getEquipmentSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get equipment',
      description: getErrorMessage(err),
    });
    yield put(getEquipmentError(err));
  }
}

function* getEquipmentId(): Generator<CallEffect | PutEffect, void, ResponseModel<EquipmentInternalId>> {
  try {
    const response = yield call(apiClientEquipments.getEquipmentInternalId);

    yield put(getEquipmentInternalIdSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get equipment Id',
      description: getErrorMessage(err),
    });
    yield put(getEquipmentInternalIdError(err));
  }
}

function* getEquipmentStockList(
  action: ActionType<typeof getEquipmentStockistRequest>,
): Generator<CallEffect | PutEffect, void, ArrayBuffer> {
  try {
    const { onSuccess } = action.payload;
    const response = yield call(apiClientEquipments.getStockList);
    const blobUrl = URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    );
    window.open(blobUrl, '_blank');
    yield put(getEquipmentStockistSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during generating equipment stock list',
      description: getErrorMessage(err),
    });
    yield put(getEquipmentStockistError(err));
  }
}

export function* watchEquipmentsSaga(): Generator {
  yield takeLatest(CONST.GET_EQUIPMENTS_REQUEST, getEquipments);
  yield takeLatest(CONST.ADD_EQUIPMENT_REQUEST, addEquipment);
  yield takeLatest(CONST.UPDATE_EQUIPMENT_REQUEST, updateEquipment);
  yield takeLatest(CONST.DELETE_EQUIPMENT_REQUEST, deleteEquipment);
  yield takeLatest(CONST.UPDATE_EQUIPMENTS_STATUSES_REQUEST, updateEquipmentsStatuses);
  yield takeLatest(CONST.GET_EQUIPMENT_REQUEST, getEquipment);
  yield takeLatest(CONST.GET_EQUIPMENT_INTERNAL_ID_REQUEST, getEquipmentId);
  yield takeLatest(CONST.GET_EQUIPMENT_STOCK_LIST_REQUEST, getEquipmentStockList);
}
