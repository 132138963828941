import React from 'react';
import { Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { GeneralAdditionalMenu } from './GeneralAdditionalMenu';

export const GeneralHeader = () => {
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile title='Ustawienia ogólne'>
      <GeneralAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Ustawienia ogólne'>
      <Row gutter={16}>
        <GeneralAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
