import { BrandModel } from '../brands/model';
import { CategoryModel } from '../categories/model';
import { CostModel } from '../costs/model';
import { AssetModel } from '../assets/model';
import { OrderModel } from '../orders/model';
import { MastModel } from '../masts/model';
import { ReservationModel } from '../reservations/model';

export enum ProductStatus {
  TRANSPORT_TO_ME = 'transport-to-me',
  MAGAZINE = 'magazine',
  WORKSHOP = 'workshop',
  PAINTSHOP = 'paintshop',
  PHOTO = 'photo',
  TO_TRANSPORT_TO_CLIENT = 'to-transport-to-client',
  TRANSPORT_TO_CLIENT = 'transport-to-client',
  GOT_BY_CLIENT = 'got-by-client',
}

export const ProductStatusNames = {
  [ProductStatus.TRANSPORT_TO_ME]: 'Transport do Superlift',
  [ProductStatus.MAGAZINE]: 'Magazyn',
  [ProductStatus.WORKSHOP]: 'Warsztat',
  [ProductStatus.PAINTSHOP]: 'Lakiernia',
  [ProductStatus.PHOTO]: 'Zdjęcia',
  [ProductStatus.TO_TRANSPORT_TO_CLIENT]: 'Gotowe do transportu do klienta',
  [ProductStatus.TRANSPORT_TO_CLIENT]: 'W transporcie do klienta',
  [ProductStatus.GOT_BY_CLIENT]: 'Odebrane przez klienta',
};

export enum ProductStatusListType {
  ALL = 'all',
  AVAILABLE = 'available',
  SOLD = 'sold',
  IN_ORDER_RESERVED = 'inOrderReserved',
  READY_FOR_ORDER = 'readyForOrder',
}

export enum ForkliftEngine {
  DIESEL = 'diesel',
  LPG = 'lpg',
  CNG = 'cng',
  ELECTRIC_PB = 'electric-pb',
  ELECTRIC_LI_ION = 'electric-li-ion',
  NONE = 'none',
}

export const ForkliftEngineNames = {
  [ForkliftEngine.DIESEL]: 'Diesel',
  [ForkliftEngine.LPG]: 'LPG',
  [ForkliftEngine.CNG]: 'CNG',
  [ForkliftEngine.ELECTRIC_PB]: 'Elektryczny PB',
  [ForkliftEngine.ELECTRIC_LI_ION]: 'Elektryczny Li-ION',
  [ForkliftEngine.NONE]: 'Inny',
};

export enum ForkliftTable {
  BRAND = 'brand',
  TYPE = 'type',
  SERIAL_NUMBER = 'serialNumber',
  CATEGORY = 'category',
  LIFTING_CAPACITY = 'liftingCapacity',
  STATUS = 'status',
  ENGINE = 'engine',
  PRODUCTION_YEAR = 'productionYear',
  HOURS = 'hours',
  MAST = 'mast',
  MAST_FOLD_HEIGHT = 'mastFoldedHeight',
  PRICE_MIN = 'priceMin',
  PRICE_SUGGESTED = 'priceSuggested',
  PRICE_WHOLESALE_MIN = 'priceWholesaleMin',
  PRICE_WHOLESALE_SUGGESTED = 'priceWholesaleSuggested',
  PRICE_INIT_WITH_COSTS = 'priceInitWithCosts',
  PRICE_WITH_PREPARATION_MIN = 'priceWithPreparationMin',
  PRICE_WITH_PREPARATION_SUGGESTED = 'priceWithPreparationSuggested',
  ORDER = 'order',
}

export const ForkliftTableNames = {
  [ForkliftTable.BRAND]: 'Marka',
  [ForkliftTable.TYPE]: 'Typ',
  [ForkliftTable.SERIAL_NUMBER]: 'Nr seryjny',
  [ForkliftTable.CATEGORY]: 'Kategoria',
  [ForkliftTable.LIFTING_CAPACITY]: 'Udźwig',
  [ForkliftTable.STATUS]: 'Status',
  [ForkliftTable.ENGINE]: 'Silnik',
  [ForkliftTable.PRODUCTION_YEAR]: 'Rok',
  [ForkliftTable.HOURS]: 'MG',
  [ForkliftTable.MAST]: 'Maszt',
  [ForkliftTable.MAST_FOLD_HEIGHT]: 'Wysokość masztu',
  [ForkliftTable.PRICE_MIN]: 'Cena minimalna',
  [ForkliftTable.PRICE_SUGGESTED]: 'Cena wyjściowa',
  [ForkliftTable.PRICE_WHOLESALE_MIN]: 'Cena hurt min',
  [ForkliftTable.PRICE_WHOLESALE_SUGGESTED]: 'Cena hurt sugerowana',
  [ForkliftTable.PRICE_INIT_WITH_COSTS]: 'Zakup + koszty',
  [ForkliftTable.PRICE_WITH_PREPARATION_MIN]: 'Cena min z przygotowaniem',
  [ForkliftTable.PRICE_WITH_PREPARATION_SUGGESTED]: 'Cena z przygotowaniem',
  [ForkliftTable.ORDER]: 'Zamówienie',
};

export type ForkliftModel = {
  _id: string;
  internalId: string;
  status: ProductStatus;
  brandId: string;
  brand?: BrandModel;
  categoryId: string;
  category?: CategoryModel;
  type: string;
  serialNumber: string;
  liftingCapacity: number;
  mast?: MastModel;
  mastId?: string | null;
  engine: ForkliftEngine;
  productionYear: number;
  hours: number;
  priceInit?: number; //cena zakupu
  priceMin: number; // min cena, po jakiej można sprzedać (cena zakupu + remont)
  priceSuggested: number; // po jakiej powinniśmy sprzedać
  deleted: string;
  createdAt: string;
  updatedAt: string;
  costs: CostModel[];
  assets: AssetModel[];
  order?: OrderModel;
  costsAmount?: number;
  mastFoldedHeight?: number;
  liftingHeight: number;
  accessories?: string;
  notes?: string;
  priceWholesaleMin?: number;
  priceWholesaleSuggested?: number;
  mastFreeLifting?: number;
  priceWithPreparationMin?: number; //PLN
  priceWithPreparationSuggested?: number; //PLN
  boughtFrom?: string;
  reservation?: ReservationModel;
};

export type ForkliftStatusesModel = {
  ids: string[];
  status: ProductStatus;
};

export enum LocalStorageKeys {
  FORKLIFT_TABLE_SETTINGS = 'ForkliftTableSettings',
}

export type ForkliftInternalId = {
  nextId: string;
};
