import { BaseAPI } from '../base';
import { MastModel } from '../../../reducers/masts/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientMasts extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/masts`;
  }

  public getMasts = async (): Promise<ResponseModel<MastModel[]>> => {
    return this.authFetch<ResponseModel<MastModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addMast = async (
    data: Omit<MastModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<MastModel>> => {
    return this.authFetch<ResponseModel<MastModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateMast = async (data: Partial<MastModel>): Promise<ResponseModel<void>> => {
    return this.authFetch<ResponseModel<void>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteMast = async (mastId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${mastId}`, { method: 'DELETE' });
  };
}

export const apiClientMasts = new APIClientMasts();
