import React from 'react';
import { Button, Col, Dropdown, Row, type MenuProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { ForkliftModel } from '../../reducers/forklift/model';
import { CurrencyType } from '../../reducers/orders/model';
import { formatPrice } from '../../utils/formatPrice';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { useViewport } from '../../utils/customHooks/useViewport';
import { MenuSC } from '../header/styles';

import './style.scss';

export const SummaryForklifts = () => {
  const forkliftsList = useSelector<RootReducerState, ForkliftModel[]>(state => state.forklifts.forkliftsList);
  const { isMobile } = useViewport();

  let priceInitForklifts = 0;
  let priceWholesaleMin = 0;
  let priceInitWithCosts = 0;

  forkliftsList.forEach(forklift => {
    priceInitForklifts += forklift.priceInit ?? 0;
    priceWholesaleMin += forklift.priceWholesaleMin ?? 0;
    priceInitWithCosts += (forklift.priceInit ? +forklift.priceInit : 0) + (forklift.costsAmount ?? 0);
  });

  const items: MenuProps['items'] = [
    {
      label: (
        <Row justify='space-between'>
          <Col>{isMobile ? 'Cena min hurtowa:' : 'Cena minimalna hurtowa:'}</Col>{' '}
          <Col>
            <b className='marginLeft'>{formatPrice(priceWholesaleMin, CurrencyType.EUR)}</b>
          </Col>
        </Row>
      ),
      key: '1',
    },
    {
      label: (
        <Row justify='space-between'>
          <Col>
            <div>{isMobile ? 'Cena min z przygot.:' : 'Cena minimalna z przygotowaniem:'}</div>
          </Col>{' '}
          <Col>
            <b className='marginLeft'>{formatPrice(priceInitWithCosts, CurrencyType.PLN)}</b>
          </Col>
        </Row>
      ),
      key: '2',
    },
    {
      label: (
        <Row justify='space-between'>
          <Col>Cena zakupu:</Col>{' '}
          <Col>
            <b className='marginLeft'>{formatPrice(priceInitForklifts, CurrencyType.PLN)}</b>
          </Col>
        </Row>
      ),
      key: '3',
    },
  ];

  return isMobile ? (
    <MenuSC items={items} />
  ) : (
    <Col>
      <Dropdown
        menu={{ items }}
        placement='bottomRight'
        arrow
      >
        <Button
          shape='circle'
          icon={
            <FontAwesomeIcon
              icon={faHandHoldingUsd}
              size='xl'
            />
          }
        />
      </Dropdown>
    </Col>
  );
};
