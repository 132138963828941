import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { PaintingFormProps, PaintingFormValues } from '.';
import { PaintingCostModel } from '../../../reducers/settings/model';

export const initFormik = (
  props: PaintingFormProps,
  painting: PaintingCostModel | undefined,
): FormikConfig<PaintingFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    price: painting?.price ?? 0,
    forkliftCategoryId: painting?.forkliftCategoryId ?? '',
    liftCapacityFrom: painting?.liftCapacityFrom ?? 0,
    liftCapacityTo: painting?.liftCapacityTo ?? 0,
  },
  validationSchema: () =>
    yup.object({
      price: yup.number().positive('Cena musi być większa od zera').required('Pole jest wymagane'),
      forkliftCategoryId: yup.string().required('Pole jest wymagane'),
      liftCapacityFrom: yup.number().required('Pole jest wymagane'),
      liftCapacityTo: yup.number().positive('Wartość musi być większa od 0').required('Pole jest wymagane'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
