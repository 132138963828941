import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { EquipmentCategoryFormProps, EquipmentCategoryFormValues } from '.';
import { EquipmentCategoryModel } from '../../reducers/equipmentCategories/model';

export const initFormik = (
  props: EquipmentCategoryFormProps,
  equipmentCategory: EquipmentCategoryModel | undefined,
): FormikConfig<EquipmentCategoryFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: equipmentCategory?.name ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Nazwa jest wymagana'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
