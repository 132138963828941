import { FilterValue } from 'antd/es/table/interface';

import { ForkliftModel, ProductStatus, ProductStatusListType } from '../reducers/forklift/model';
import { EquipmentModel } from '../reducers/equipment/model';

export const isProductStatus = (product: ForkliftModel | EquipmentModel, statusType: ProductStatusListType) => {
  switch (statusType) {
    case ProductStatusListType.AVAILABLE:
      return (
        !product.order &&
        [
          ProductStatus.WORKSHOP,
          ProductStatus.PAINTSHOP,
          ProductStatus.TRANSPORT_TO_ME,
          ProductStatus.MAGAZINE,
          ProductStatus.PHOTO,
        ].includes(product.status)
      );
    case ProductStatusListType.SOLD:
      return (
        product.status === ProductStatus.TO_TRANSPORT_TO_CLIENT ||
        product.status === ProductStatus.TRANSPORT_TO_CLIENT ||
        product.status === ProductStatus.GOT_BY_CLIENT
      );
    case ProductStatusListType.IN_ORDER_RESERVED:
      return (
        product.order &&
        ![
          ProductStatus.TO_TRANSPORT_TO_CLIENT,
          ProductStatus.TRANSPORT_TO_CLIENT,
          ProductStatus.GOT_BY_CLIENT,
        ].includes(product.status)
      );
    case ProductStatusListType.READY_FOR_ORDER:
      return !product.order;
    default:
      return false;
  }
};

type ProductsStatusFilters = {
  order: number | undefined;
  status: ProductStatus[];
};

export const getProductStatusTypeFilters = (statusType: ProductStatusListType) => {
  const productStatusFilters: ProductsStatusFilters = {
    order: undefined,
    status: [],
  };

  switch (statusType) {
    case ProductStatusListType.AVAILABLE:
      productStatusFilters.order = 0;
      productStatusFilters.status = [
        ProductStatus.WORKSHOP,
        ProductStatus.PAINTSHOP,
        ProductStatus.TRANSPORT_TO_ME,
        ProductStatus.MAGAZINE,
        ProductStatus.PHOTO,
      ];
      break;
    case ProductStatusListType.SOLD:
      productStatusFilters.status = [
        ProductStatus.TO_TRANSPORT_TO_CLIENT,
        ProductStatus.TRANSPORT_TO_CLIENT,
        ProductStatus.GOT_BY_CLIENT,
      ];
      break;
    case ProductStatusListType.IN_ORDER_RESERVED:
      productStatusFilters.order = 1;
      productStatusFilters.status = [
        ProductStatus.TRANSPORT_TO_ME,
        ProductStatus.MAGAZINE,
        ProductStatus.WORKSHOP,
        ProductStatus.PAINTSHOP,
        ProductStatus.PHOTO,
      ];
      break;
  }

  return productStatusFilters;
};

export const mutateForkliftFiltersWitProductStatus = (
  statusType: ProductStatusListType,
  queryMap: { filters: Record<string, FilterValue | undefined> },
) => {
  const productStatusFilters: ProductsStatusFilters = getProductStatusTypeFilters(statusType);

  if (productStatusFilters.order !== undefined) {
    queryMap.filters.order = [productStatusFilters.order];
  }
  if (!queryMap.filters.status?.length) {
    queryMap.filters.status = productStatusFilters.status;
  }
};
