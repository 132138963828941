import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { ReservationModel } from './model';

export const addReservationRequest = (
  reservation: Omit<ReservationModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'ownerId'>,
  onSuccess?: () => void,
) => action(CONST.ADD_RESERVATION_REQUEST, { reservation, onSuccess });
export const addReservationSuccess = (reservation: ReservationModel) =>
  action(CONST.ADD_RESERVATION_SUCCESS, { reservation });
export const addReservationError = (error: unknown) => action(CONST.ADD_RESERVATION_ERROR, { error });
