import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
export type appAction = ActionType<typeof actions>;
export type AppState = {
  //This is not used
  readonly selectedMenuId: string | null;
};

export const appReducer = combineReducers<AppState, appAction>({
  selectedMenuId: (state = null, action) => {
    switch (action.type) {
      case CONST.SET_SELECTED_ITEM_MENU:
        return action.payload.menuId;

      default:
        return state;
    }
  },
});
