import { useCallback, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, InputNumber, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type Filters = {
  text: string;
  value: string | number | boolean;
};
type FilterMode = 'menu' | 'tree' | undefined;

type RangeSearch = {
  from: number | null;
  to: number | null;
};

export const useFilterAntTable = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = useCallback(
    (selectedKeys: string[], confirm: () => {}, dataIndex: string) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    },
    [setSearchText, setSearchedColumn],
  );

  const handleSearchRage = useCallback(
    (_selectedKeys: RangeSearch[], confirm: () => {}, dataIndex: string) => {
      confirm();
      setSearchedColumn(dataIndex);
    },
    [setSearchedColumn],
  );

  const handleReset = useCallback(
    clearFilters => {
      clearFilters();
      setSearchText('');
    },
    [setSearchText],
  );

  const getColumnSearchProps = (dataIndex: string, title: string, onSearch?: (value, record) => boolean) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Szukaj ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              onClick={() => handleReset(clearFilters)}
              size='small'
              style={{ width: 90 }}
            >
              Wyczyść
            </Button>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Szukaj
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: onSearch
      ? onSearch
      : (value, record) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape={true}
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchRangeProps = (dataIndex: string, title: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <div>
            <Space>
              <InputNumber<number>
                placeholder={`${title} od`}
                value={selectedKeys[0]?.from ?? null}
                onChange={value => setSelectedKeys([{ from: value, to: selectedKeys[0]?.to ?? null }])}
                onPressEnter={() => handleSearchRage(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <InputNumber
                placeholder={`${title} do`}
                value={selectedKeys[0]?.to ?? null}
                onChange={value => setSelectedKeys([{ from: selectedKeys[0]?.from ?? null, to: value }])}
                onPressEnter={() => handleSearchRage(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
            </Space>
          </div>
          <Space>
            <Button
              onClick={() => handleReset(clearFilters)}
              size='small'
              style={{ width: 90 }}
            >
              Wyczyść
            </Button>
            <Button
              type='primary'
              onClick={() => handleSearchRage(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Szukaj
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (range, record) => {
      if (range?.from !== null && range?.to !== null) {
        return record[dataIndex] && record[dataIndex] >= range.from && record[dataIndex] <= range.to;
      } else if (range?.from !== null) {
        return record[dataIndex] && record[dataIndex] >= range.from;
      } else if (range?.to !== null) {
        return record[dataIndex] && record[dataIndex] <= range.to;
      }

      return true;
    },
    render: (text: string) => text,
  });

  const getColumnFilterProps = (
    dataIndex: string,
    filters: Filters[],
    onFilter?: (value: string | number | boolean, record) => boolean,
  ) => {
    return {
      filters: filters,
      filterMode: 'menu' as FilterMode,
      filterSearch: true,
      onFilter: onFilter
        ? onFilter
        : (value: string | number | boolean, record): boolean => {
            return record[dataIndex] === value;
          },
    };
  };

  return {
    getColumnSearchProps,
    getColumnSearchRangeProps,
    getColumnFilterProps,
  };
};
