import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { UserFormProps, UserFormValues } from '.';
import { UserModel, UserRole } from '../../reducers/user/model';

export const initFormik = (props: UserFormProps, user: UserModel | undefined): FormikConfig<UserFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: user?.name ?? '',
    surname: user?.surname ?? '',
    email: user?.email ?? '',
    phone: {
      prefix: user?.phone?.prefix ?? '+48',
      value: user?.phone?.value ?? '',
    },
    role: user?.role ?? ('' as UserRole),
    active: user?.active ?? false,
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Imię jest wymagane'),
      surname: yup.string().required('Nazwisko jest wymagane'),
      email: yup.string().required('Email jest wymagany'),
      phone: yup.object({
        value: yup.string().required('Telefon jest wymagany'),
      }),
      role: yup.string().required('Rola jest wymagana'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
