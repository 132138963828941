import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faMoneyCheckDollar,
  faBuildingColumns,
  faBan,
  faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons';
import { OrderStatus, OrderStatusNames } from '../reducers/orders/model';

export const getStatusOrdersIcon = (status: string) => {
  switch (status) {
    case OrderStatus.NOT_PAID:
      return (
        <Tooltip title={OrderStatusNames[OrderStatus.NOT_PAID]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faThumbsDown}
          />
        </Tooltip>
      );
    case OrderStatus.PAID:
      return (
        <Tooltip title={OrderStatusNames[OrderStatus.PAID]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faThumbsUp}
          />
        </Tooltip>
      );
    case OrderStatus.PAID_IN_ADVANCE:
      return (
        <Tooltip title={OrderStatusNames[OrderStatus.PAID_IN_ADVANCE]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faMoneyCheckDollar}
          />
        </Tooltip>
      );
    case OrderStatus.LEASING:
      return (
        <Tooltip title={OrderStatusNames[OrderStatus.LEASING]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faBuildingColumns}
          />
        </Tooltip>
      );

    case OrderStatus.CANCEL:
      return (
        <Tooltip title={OrderStatusNames[OrderStatus.CANCEL]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faBan}
          />
        </Tooltip>
      );

    case OrderStatus.DONE:
      return (
        <Tooltip title={OrderStatusNames[OrderStatus.DONE]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faCalendarCheck}
          />
        </Tooltip>
      );

    default:
      return <></>;
  }
};
