import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import { RootReducerState } from '../../reducers';
import { UserModel, UserRole, UserRoleNames } from '../../reducers/user/model';
import {
  addUserRequest,
  deleteUserRequest,
  getUsersRequest,
  loginAsUserRequest,
  updateUserRequest,
} from '../../reducers/user/actions';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { AlignType } from 'rc-table/lib/interface';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { UserFormValues, UserForm } from '../../forms/user';

import './style.scss';

const { Link, Text } = Typography;

type UsersListProps = {
  openUserModal: boolean;
  clickOpenUserModal: (openUserModal) => void;
};

export const UsersList: FC<UsersListProps> = props => {
  const { openUserModal, clickOpenUserModal } = props;
  const usersList = useSelector<RootReducerState, UserModel[]>(state => state.users.usersList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.users.isFetching);
  const dispatch = useDispatch();
  const { getColumnSearchProps, getColumnFilterProps } = useFilterAntTable();
  const [selectedUser, setSelectedUser] = useState<UserModel | undefined>(undefined);
  const [, setDeleteUserModal] = useState(false);
  const [, setOpenLoginAsUserModal] = useState(false);

  useEffect(() => {
    dispatch(getUsersRequest());
  }, [dispatch]);

  const handleEditUserModal = (userId: string) => {
    const user = usersList.find(item => item._id === userId);
    setSelectedUser(user);
    clickOpenUserModal(true);
  };

  const handleDeleteUserModal = (userId: string) => {
    dispatch(
      deleteUserRequest(userId, () => {
        setDeleteUserModal(false);
      }),
    );
  };

  const handleCloseUserModal = () => {
    setSelectedUser(undefined);
    clickOpenUserModal(false);
  };

  const handleLoginAsUser = () => {
    setOpenLoginAsUserModal(true);
  };

  const handleConfirmLoginAsUser = (email: string) => {
    dispatch(loginAsUserRequest(email));
  };

  const handleSubmit = (values: UserFormValues) => {
    if (selectedUser) {
      dispatch(
        updateUserRequest(
          {
            ...selectedUser,
            ...values,
          },
          () => {
            setSelectedUser(undefined);
            clickOpenUserModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addUserRequest(values, () => {
          setSelectedUser(undefined);
          clickOpenUserModal(false);
        }),
      );
    }
  };

  const columns = [
    {
      title: 'Imię',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ...getColumnSearchProps('name', 'imię'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Nazwisko',
      dataIndex: 'surname',
      key: 'surname',
      width: 150,
      ...getColumnSearchProps('surname', 'nazwisko'),
      sorter: (a, b) => a.surname.localeCompare(b.surname),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', 'email'),
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (email: string) => (
        <Text ellipsis={true}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Text>
      ),
    },
    {
      title: 'Rola',
      dataIndex: 'role',
      key: 'role',
      width: 120,
      ...getColumnFilterProps(
        'role',
        Object.values(UserRole).map(role => ({
          value: role,
          text: UserRoleNames[role],
        })),
      ),
      render: (role, user) => UserRoleNames[user.role],
      sorter: (a, b) => UserRoleNames[a.role].localeCompare(UserRoleNames[b.role]),
    },
    {
      title: 'Ostatnie logowanie',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      width: 170,
      render: lastLogin => lastLogin && dayjs(lastLogin).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.lastLogin || '').getTime() - new Date(b.lastLogin || '').getTime(),
    },
    {
      title: 'Aktywny',
      dataIndex: 'active',
      key: 'active',
      align: 'center' as AlignType,
      width: 120,
      ...getColumnFilterProps('active', [
        {
          value: true,
          text: 'Aktywny',
        },
        {
          value: false,
          text: 'Nieaktywny',
        },
      ]),
      render: active =>
        active ? <CheckCircleOutlined className='iconPositive' /> : <CloseCircleOutlined className='iconNegative' />,
      sorter: (a, b) => a.active - b.active,
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      width: 120,
      render: (_id: string, user) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj użytkownika'
              onClick={() => handleEditUserModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie użytkownika'
              description='Czy na pewno chcesz usunąć użytkownika?'
              onConfirm={() => handleDeleteUserModal(user._id)}
              onCancel={() => setDeleteUserModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń użytkownika'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip
            title='Zaloguj'
            placement='bottom'
          >
            <Popconfirm
              title={`Czy na pewno chcesz zalogować się jako: ${user.email as string}`}
              description='Zostaniesz zalogowany jako inny użytkownik i będziesz korzystać z danych tego użytkownika.'
              onConfirm={() => handleConfirmLoginAsUser(user.email)}
              onCancel={() => setOpenLoginAsUserModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Zaloguj się jako użytkownik'
                onClick={() => handleLoginAsUser()}
                icon={<LoginOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={usersList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        sticky
        className='usersTable'
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane użytkownika'
        titleAdd='Dodaj użytkownika'
        openModal={openUserModal}
        handleCloseModal={handleCloseUserModal}
        selectedItem={selectedUser}
      >
        <UserForm
          handleSubmit={handleSubmit}
          user={selectedUser}
          handleCancelForm={handleCloseUserModal}
        />
      </DrawerAddEdit>
    </>
  );
};
