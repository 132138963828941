import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { AdvancePaymentModel } from '../../../reducers/advancePayments/model';

class APIClientAdvancePayments extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/advance-payments`;
  }

  public getAdvancePayments = async (): Promise<ResponseModel<AdvancePaymentModel[]>> => {
    return this.authFetch<ResponseModel<AdvancePaymentModel[]>>(this.apiURL, { method: 'GET' });
  };

  public getOrderAdvancePayments = async (orderId: string): Promise<ResponseModel<AdvancePaymentModel[]>> => {
    return this.authFetch<ResponseModel<AdvancePaymentModel[]>>(`${this.apiURL}/orders/${orderId}`, { method: 'GET' });
  };

  public addAdvancePayment = async (
    data: Omit<AdvancePaymentModel, '_id' | 'createdAt' | 'ownerId' | 'updatedAt'>,
  ): Promise<ResponseModel<AdvancePaymentModel>> => {
    return this.authFetch<ResponseModel<AdvancePaymentModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateAdvancePayment = async (
    data: Partial<AdvancePaymentModel>,
  ): Promise<ResponseModel<AdvancePaymentModel>> => {
    delete data.ownerId;
    return this.authFetch<ResponseModel<AdvancePaymentModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteAdvancePayment = async (advancePaymentId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${advancePaymentId}`, { method: 'DELETE' });
  };

  public getAdvancePayment = async (advancePaymentId: string): Promise<ResponseModel<AdvancePaymentModel>> => {
    return this.authFetch<ResponseModel<AdvancePaymentModel>>(`${this.apiURL}/${advancePaymentId}`, { method: 'GET' });
  };
}

export const apiClientAdvancePayments = new APIClientAdvancePayments();
