import { FormikConfig } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { ForkliftFormProps, ForkliftFormValues } from '.';
import { ForkliftEngine, ForkliftModel, ProductStatus } from '../../reducers/forklift/model';

export const initFormik = (
  props: ForkliftFormProps,
  forklift: ForkliftModel | undefined,
): FormikConfig<ForkliftFormValues> => ({
  enableReinitialize: false,
  initialValues: {
    internalId: forklift?.internalId ?? '',
    brandId: forklift?.brandId ?? '',
    categoryId: forklift?.categoryId ?? '',
    engine: forklift?.engine ?? ForkliftEngine.DIESEL,
    productionYear: forklift?.productionYear ?? dayjs().get('year'),
    hours: forklift?.hours ?? 0,
    priceInit: forklift?.priceInit ?? 0,
    priceMin: forklift?.priceMin ?? 0,
    priceSuggested: forklift?.priceSuggested ?? 0,
    type: forklift?.type ?? '',
    serialNumber: forklift?.serialNumber ?? '',
    mastId: forklift?.mastId ?? '',
    status: forklift?.status ?? ProductStatus.MAGAZINE,
    liftingCapacity: forklift?.liftingCapacity ?? 0,
    assets: forklift?.assets ?? [],
    mastFoldedHeight: forklift?.mastFoldedHeight ?? 0,
    liftingHeight: forklift?.liftingHeight ?? 0,
    accessories: forklift?.accessories ?? '',
    notes: forklift?.notes ?? '',
    priceWholesaleMin: forklift?.priceWholesaleMin ?? 0,
    priceWholesaleSuggested: forklift?.priceWholesaleSuggested ?? 0,
    mastFreeLifting: forklift?.mastFreeLifting ?? 0,
    priceWithPreparationMin: forklift?.priceWithPreparationMin ?? 0,
    priceWithPreparationSuggested: forklift?.priceWithPreparationSuggested ?? 0,
    boughtFrom: forklift?.boughtFrom,
  },
  validationSchema: () =>
    yup.object({
      brandId: yup.string().required('Marka jest wymagana'),
      categoryId: yup.string().required('Kategoria jest wymagana'),
      engine: yup.string().required('Rodzaj silnika jest wymagany'),
      productionYear: yup.string().required('Rok produkcji jest wymagany'),
      hours: yup
        .number()
        .positive('Liczba motogodzin musi być większa od zera')
        .required('Liczba motogodzin jest wymagana'),
      type: yup.string().required('Typ jest wymagany'),
      priceInit: yup.number().typeError('Pole musi być liczbą'),
      priceMin: yup
        .number()
        .positive('Cena musi być większa od zera')
        .required('Minimalna cena sprzedaży jest wymagana'),
      priceSuggested: yup
        .number()
        .positive('Cena musi być większa od zera')
        .required('Sugerowana cena sprzedaży jest wymagana'),
      serialNumber: yup.string().required('Numer seryjny jest wymagany'),
      mastId: yup.string(),
      status: yup.string().required('Status jest wymagany'),
      liftingCapacity: yup.number().positive('Udźwig musi być większy od zera').required('Udźwig jest wymagany'),
      mastFreeLifting: yup.number().typeError('Pole musi być liczbą'),
    }),
  onSubmit: (values, { setFieldValue }) => {
    props.handleSubmit(values, () => {
      void setFieldValue('serialNumber', '');
      void setFieldValue('assets', []);
    });
  },
});
