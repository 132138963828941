import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export const additionalServiceIcon = (service: string) => {
  switch (service) {
    case 'technicalReview':
      return (
        <div className='serviceOrderIcon'>
          <FontAwesomeIcon
            icon={faCheck}
            size='sm'
            className='iconPositive'
          />{' '}
          Przegląd
        </div>
      );

    case 'guarantee':
      return (
        <div className='serviceOrderIcon'>
          <FontAwesomeIcon
            icon={faCheck}
            size='sm'
            className='iconPositive'
          />{' '}
          Gwarancja
        </div>
      );

    case 'udt':
      return (
        <div className='serviceOrderIcon'>
          <FontAwesomeIcon
            icon={faCheck}
            size='sm'
            className='iconPositive'
          />{' '}
          UDT
        </div>
      );

    case 'rectifier':
      return (
        <div className='serviceOrderIcon'>
          <FontAwesomeIcon
            icon={faCheck}
            size='sm'
            className='iconPositive'
          />{' '}
          Prostownik
        </div>
      );

    default:
      return <></>;
  }
};
