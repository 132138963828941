import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { ClientList } from '../../component/clients/ClientsList';
import { ClientsHeader } from './ClientsHeader';

export const ClientsPage = () => {
  const [openClientModal, setOpenClientModal] = useState(false);

  return (
    <LayoutMain>
      <ClientsHeader onSetOpenClientModal={setOpenClientModal} />
      <ClientList
        openClientModal={openClientModal}
        clickOpenClientModal={openClientModal => setOpenClientModal(openClientModal)}
      />
    </LayoutMain>
  );
};
