import React, { useState } from 'react';
import { UsersList } from '../../component/users/UsersList';
import { LayoutMain } from '../../layout/main/Main';
import { UsersHeader } from './UsersHeader';

export const UsersPage = () => {
  const [openUserModal, setOpenUserModal] = useState(false);

  return (
    <LayoutMain>
      <UsersHeader onSetOpenUserModal={setOpenUserModal} />
      <UsersList
        openUserModal={openUserModal}
        clickOpenUserModal={openUserModal => setOpenUserModal(openUserModal)}
      />
    </LayoutMain>
  );
};
