import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { CostFormProps, CostFormValues } from '.';
import { CostType } from '../../reducers/costs/model';

export const initFormik = (props: CostFormProps): FormikConfig<CostFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.cost?.name ?? '',
    cost: props.cost?.cost ?? 0,
    type: '' as CostType,
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Nazwa jest wymagana'),
      cost: yup.number().positive('Cena musi być większa od zera').required('Koszt jest wymagana'),
    }),
  onSubmit: (values, { resetForm }) => {
    props.handleSubmit(values, () => {
      resetForm();
    });
  },
});
