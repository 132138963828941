import { RcFile } from 'antd/es/upload';
import Resizer from 'react-image-file-resizer';
import { AssetModel } from '../../reducers/assets/model';

export const assetsToUploadedFile = (asset: AssetModel) => ({
  uid: asset._id,
  size: asset.size,
  name: asset.originalName,
  fileName: asset.originalName,
  lastModifiedDate: new Date(asset.updatedAt),
  url: `${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${asset.uri}`,
  type: asset.mimeType,
});

export const getBase64 = async (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const resizeFile = async (file: File, compressFormat: 'jpeg' | 'png'): Promise<File> => {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      2000,
      2000,
      compressFormat,
      80,
      0,
      file => {
        resolve(file as File);
      },
      'file',
    );
  });
};

export const getCompressFormat = (fileName: string): 'jpeg' | 'png' | null => {
  const fileExt = fileName.split('.').pop();
  switch (fileExt?.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      return 'jpeg';
    case 'png':
      return 'png';
    default:
      return null;
  }
};
