import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { ProductStatusListType } from '../reducers/forklift/model';
import { mutateForkliftFiltersWitProductStatus } from './productsStatus';

export type TableSettingsHandler<T = unknown> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<T> | SorterResult<T>[],
) => void;

type PrepareFilterQueryStringArg<T = unknown> = {
  pagination?: TablePaginationConfig;
  filters?: Record<string, FilterValue | null>;
  sorter?: SorterResult<T> | SorterResult<T>[];
  productStatusTab: ProductStatusListType;
};

export function prepareFilterQueryString<T = unknown>(args: PrepareFilterQueryStringArg<T>) {
  const sorterItem = Array.isArray(args.sorter) ? args.sorter[0] : args.sorter;

  const queryMap = {
    page: args.pagination?.current ?? 1,
    limit: args.pagination?.pageSize,
    sort: sorterItem?.field ?? 'createdAt',
    sortOrder: sorterItem?.order ?? 'descend',
    filters: Object.entries(args.filters ?? {}).reduce<Record<string, FilterValue>>(
      (filtersMap, [filterName, filterValue]) => {
        if (filterValue) {
          filtersMap[filterName] = filterValue;
        }
        return filtersMap;
      },
      {},
    ),
  };
  mutateForkliftFiltersWitProductStatus(args.productStatusTab, queryMap);

  return `?page=${queryMap.page}${
    queryMap.limit ? `&limit=${queryMap.limit}` : ''
  }&sort=${queryMap.sort.toString()}&sortOrder=${queryMap.sortOrder}&filters=${encodeURI(
    JSON.stringify(queryMap.filters),
  )}`;
}
