export const GET_EQUIPMENT_BRANDS_REQUEST = '@equipmentBrands/GET_EQUIPMENT_BRANDS_REQUEST';
export const GET_EQUIPMENT_BRANDS_SUCCESS = '@equipmentBrands/GET_EQUIPMENT_BRANDS_SUCCESS';
export const GET_EQUIPMENT_BRANDS_ERROR = '@equipmentBrands/GET_EQUIPMENT_BRANDS_ERROR';

export const ADD_EQUIPMENT_BRAND_REQUEST = '@equipmentBrands/ADD_EQUIPMENT_BRAND_REQUEST';
export const ADD_EQUIPMENT_BRAND_SUCCESS = '@equipmentBrands/ADD_EQUIPMENT_BRAND_SUCCESS';
export const ADD_EQUIPMENT_BRAND_ERROR = '@equipmentBrands/ADD_EQUIPMENT_BRAND_ERROR';

export const UPDATE_EQUIPMENT_BRAND_REQUEST = '@equipmentBrands/UPDATE_EQUIPMENT_BRAND_REQUEST';
export const UPDATE_EQUIPMENT_BRAND_SUCCESS = '@equipmentBrands/UPDATE_EQUIPMENT_BRAND_SUCCESS';
export const UPDATE_EQUIPMENT_BRAND_ERROR = '@equipmentBrands/UPDATE_EQUIPMENT_BRAND_ERROR';

export const DELETE_EQUIPMENT_BRAND_REQUEST = '@equipmentBrands/DELETE_EQUIPMENT_BRAND_REQUEST';
export const DELETE_EQUIPMENT_BRAND_SUCCESS = '@equipmentBrands/DELETE_EQUIPMENT_BRAND_SUCCESS';
export const DELETE_EQUIPMENT_BRAND_ERROR = '@equipmentBrands/DELETE_EQUIPMENT_BRAND_ERROR';
