import { action } from 'typesafe-actions';

import * as CONST from './consts';

export const deleteAssetRequest = (assetId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_ASSET_REQUEST, { assetId, onSuccess });
export const deleteAssetPrivateRequest = (assetId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_ASSET_PRIVATE_REQUEST, { assetId, onSuccess });
export const deleteAssetSuccess = (assetId: string) => action(CONST.DELETE_ASSET_SUCCESS, { assetId });
export const deleteAssetError = (error: Error) => action(CONST.DELETE_ASSET_ERROR, { error });
