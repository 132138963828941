import jwt_decode from 'jwt-decode';

import { PhoneModel, UserModel, UserRole } from '../../reducers/user/model';
import { store } from '../../store';
import { logoutUser } from '../../reducers/user/actions';

type UserInfo = {
  _id: string;
  email: string;
  phone: PhoneModel;
  name: string;
  surname: string;
  role: UserRole;
  active: true;
  createdAt: number;
  updatedAt: number;
  exp: number;
  iat: number;
  resetPasswordToken?: string;
};

class AuthService {
  public setToken = (token: string): void => {
    localStorage.setItem('token', token);
  };

  public getToken = () => {
    return localStorage.getItem('token');
  };

  public logout = (): void => {
    localStorage.removeItem('token');
    store.dispatch(logoutUser());
  };

  public getUser = (): UserModel | null => {
    const token = this.getToken();

    if (token) {
      const decodedToken: UserInfo = jwt_decode(token);

      return {
        _id: decodedToken._id,
        email: decodedToken.email,
        phone: decodedToken.phone,
        name: decodedToken.name,
        surname: decodedToken.surname,
        role: decodedToken.role,
        active: decodedToken.active,
        createdAt: decodedToken.createdAt,
        updatedAt: decodedToken.updatedAt,
        ownerId: '',
        resetPasswordToken: '',
      };
    } else {
      return null;
    }
  };

  public validateToken = (): boolean => {
    const token = this.getToken();
    if (token) {
      const user: UserInfo = jwt_decode(token);

      return user.exp > new Date().getTime() / 1000;
    }

    return false;
  };

  public isAdmin = (): boolean => {
    if (this.validateToken()) {
      const user = this.getUser();

      return user?.role === UserRole.ADMIN;
    }

    return false;
  };

  public isLoggedIn = (): boolean => {
    return this.validateToken();
  };
}

export const authService = new AuthService();
