import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { AdvancePaymentsFormProps, AdvancePaymentFormValues } from '.';
import { AdvancePaymentModel } from '../../reducers/advancePayments/model';
import { CurrencyType } from '../../reducers/orders/model';

export const initFormik = (
  props: AdvancePaymentsFormProps,
  advancePayment: AdvancePaymentModel | undefined,
): FormikConfig<AdvancePaymentFormValues> => {
  const currentDate = new Date();
  return {
    enableReinitialize: false,
    initialValues: {
      price: advancePayment?.price ?? 0,
      currency: advancePayment?.currency as CurrencyType,
      orderId: advancePayment?.orderId ?? '',
      paidAt: advancePayment?.paidAt ?? currentDate.toString(),
    },
    validationSchema: () =>
      yup.object({
        price: yup.number().positive('Zaliczka musi być większa od zera').required('Pole jest wymagane'),
      }),
    onSubmit: values => {
      props.handleSubmit(values);
    },
  };
};
