export type LoginModel = {
  token: string;
  user: UserModel;
};

export enum UserRole {
  ADMIN = 'admin',
  SALES_DIRECTOR = 'sales-director',
  SELLER = 'seller',
  ACCOUNTANT = 'accountant',
  MECHANIC = 'mechanic',
  LOGISTIC = 'logistic',
}

export const UserRoleNames = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.SALES_DIRECTOR]: 'Dyrektor sprzedaży',
  [UserRole.SELLER]: 'Sprzedawca',
  [UserRole.ACCOUNTANT]: 'Księgowy',
  [UserRole.MECHANIC]: 'Mechanik',
  [UserRole.LOGISTIC]: 'Logistyka',
};

export type PhoneModel = {
  prefix?: string;
  value?: string;
};

// TODO change model with backend
export type UserModel = {
  _id: string;
  name: string;
  surname: string;
  email: string;
  phone?: PhoneModel;
  role: UserRole;
  // assets: AssetModel[];
  active: boolean;
  ownerId: string;
  resetPasswordToken?: string;
  createdAt: number;
  updatedAt: number;
};
