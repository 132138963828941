import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, DatePicker, Form, Input, Row } from 'antd';
import dayjs from 'dayjs';

import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';
import { AdvancePaymentModel } from '../../reducers/advancePayments/model';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';

const { Item } = Form;

export type AdvancePaymentFormValues = {
  price: number;
  currency: CurrencyType;
  orderId: string;
  paidAt: string;
};

export type AdvancePaymentsFormProps = {
  currency: CurrencyType;
  advancePayment?: AdvancePaymentModel;
  handleSubmit: (values: AdvancePaymentFormValues) => void;
  handleCancelForm: () => void;
};

export const AdvancePaymentForm: FC<AdvancePaymentsFormProps> = props => {
  const { currency, advancePayment, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.advancePayment.isFetching);

  const formik = useFormik(initFormik(props, advancePayment));

  const onChange = async (value: dayjs.Dayjs) => {
    await formik.setFieldValue('paidAt', value.toDate());
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Item
          label='Zaliczka (kwota brutto)'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.price && formik.touched.price ? 'error' : ''}
          help={formik.errors.price && formik.touched.price ? formik.errors.price : null}
        >
          <Input
            name='price'
            value={formik.values.price}
            placeholder='Zaliczka'
            onChange={formik.handleChange}
            addonAfter={CurrencyTypeNames[currency]}
          />
        </Item>
        <Item
          label='Data otrzymania zaliczki'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.paidAt && formik.touched.paidAt ? 'error' : ''}
          help={formik.errors.paidAt && formik.touched.paidAt ? formik.errors.paidAt : null}
        >
          <DatePicker
            format='DD/MM/YYYY'
            defaultValue={dayjs()}
            value={formik.values.paidAt ? dayjs(formik.values.paidAt) : dayjs()}
            onChange={value => onChange(value)}
            allowClear={false}
          />
        </Item>

        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {advancePayment ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
