import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { EquipmentBrandsList } from '../../component/equipemntBrands/EquipmentBrandsList';
import { EquipmentBrandsHeader } from './EquipmentBrandsHeader';

export const EquipmentBrandsPage = () => {
  const [openEquipmentBrandModal, setOpenEquipmentBrandModal] = useState(false);

  return (
    <LayoutMain>
      <EquipmentBrandsHeader onSetOpenEquipmentBrandModal={setOpenEquipmentBrandModal} />
      <EquipmentBrandsList
        openEquipmentBrandModal={openEquipmentBrandModal}
        clickOpenEquipmentBrandModal={openBrandModal => setOpenEquipmentBrandModal(openBrandModal)}
      />
    </LayoutMain>
  );
};
