import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';

import {
  getOffersSuccess,
  getOffersError,
  addOfferRequest,
  addOfferSuccess,
  addOfferError,
  updateOfferRequest,
  updateOfferSuccess,
  updateOfferError,
  deleteOfferRequest,
  deleteOfferSuccess,
  deleteOfferError,
  getOfferPDFRequest,
  getOfferPDFSuccess,
  getOfferPDFError,
  getOfferSuccess,
  getOfferError,
  getOfferRequest,
} from './actions';
import * as CONST from './consts';
import { OfferModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { apiClientOffers } from '../../services/apiClient/offers';
import { getForkliftsRequest } from '../forklift/actions';
import { getEquipmentsRequest } from '../equipment/actions';

function* getOffers(): Generator<CallEffect | PutEffect, void, ResponseModel<OfferModel[]>> {
  try {
    const response = yield call(apiClientOffers.getOffers);

    yield put(getOffersSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get offers',
      description: getErrorMessage(err),
    });
    yield put(getOffersError(err));
  }
}

function* getOffer(
  action: ActionType<typeof getOfferRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<OfferModel>> {
  try {
    const response = yield call(apiClientOffers.getOffer, action.payload.id);

    yield put(getOfferSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get offer',
      description: getErrorMessage(err),
    });
    yield put(getOfferError(err));
  }
}

function* addOffer(
  action: ActionType<typeof addOfferRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<OfferModel>> {
  try {
    const { offer, onSuccess } = action.payload;
    const response = yield call(apiClientOffers.addOffer, offer);

    yield put(addOfferSuccess(response.data));
    yield put(getForkliftsRequest());
    yield put(getEquipmentsRequest());
    if (onSuccess) {
      onSuccess(response.data._id);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding offer',
      description: getErrorMessage(err),
    });
    yield put(addOfferError(err));
  }
}

function* updateOffer(
  action: ActionType<typeof updateOfferRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<OfferModel>> {
  try {
    const { offer, onSuccess } = action.payload;
    const response = yield call(apiClientOffers.updateOffer, offer);

    yield put(updateOfferSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating offer',
      description: getErrorMessage(err),
    });
    yield put(updateOfferError(err));
  }
}

function* deleteOffer(action: ActionType<typeof deleteOfferRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { offerId, onSuccess } = action.payload;
    yield call(apiClientOffers.deleteOffer, offerId);

    yield put(deleteOfferSuccess(offerId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting offer',
      description: getErrorMessage(err),
    });
    yield put(deleteOfferError(err));
  }
}

function* getOfferPDF(
  action: ActionType<typeof getOfferPDFRequest>,
): Generator<CallEffect | PutEffect, void, ArrayBuffer> {
  try {
    const { offerId, onSuccess } = action.payload;
    const response = yield call(apiClientOffers.getOfferPDF, offerId);
    const blobUrl = URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
    window.open(blobUrl, '_blank');
    yield put(getOfferPDFSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during generating offer PDF',
      description: getErrorMessage(err),
    });
    yield put(getOfferPDFError(err));
  }
}

export function* watchOffersSaga(): Generator {
  yield takeLatest(CONST.GET_OFFERS_REQUEST, getOffers);
  yield takeLatest(CONST.GET_OFFER_REQUEST, getOffer);
  yield takeLatest(CONST.ADD_OFFER_REQUEST, addOffer);
  yield takeLatest(CONST.UPDATE_OFFER_REQUEST, updateOffer);
  yield takeLatest(CONST.DELETE_OFFER_REQUEST, deleteOffer);
  yield takeLatest(CONST.GET_OFFER_PDF_REQUEST, getOfferPDF);
}
