export enum CostProduct {
  FORKLIFT = 'forklift',
}

export enum CostType {
  PAINTING = 'Lakierowanie',
  OTHER = 'Inne',
}

export type CostModel = {
  _id: string;
  name: string;
  cost: number;
  productId: string;
  product: CostProduct;
  deleted: string;
  createdAt: string;
  updatedAt: string;
};
