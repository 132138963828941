import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { BrandModel } from './model';

export const getBrandsRequest = () => action(CONST.GET_BRANDS_REQUEST);
export const getBrandsSuccess = (brands: BrandModel[]) => action(CONST.GET_BRANDS_SUCCESS, { brands });
export const getBrandsError = (error: unknown) => action(CONST.GET_BRANDS_ERROR, { error });

export const addBrandRequest = (
  brand: Omit<BrandModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: (brand?: BrandModel) => void,
) => action(CONST.ADD_BRAND_REQUEST, { brand, onSuccess });
export const addBrandSuccess = (brand: BrandModel) => action(CONST.ADD_BRAND_SUCCESS, { brand });
export const addBrandError = (error: unknown) => action(CONST.ADD_BRAND_ERROR, { error });

export const updateBrandRequest = (brand: BrandModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_BRAND_REQUEST, { brand, onSuccess });
export const updateBrandSuccess = (brand: BrandModel) => action(CONST.UPDATE_BRAND_SUCCESS, { brand });
export const updateBrandError = (error: unknown) => action(CONST.UPDATE_BRAND_ERROR, { error });

export const deleteBrandRequest = (brandId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_BRAND_REQUEST, { brandId, onSuccess });
export const deleteBrandSuccess = (brandId: string) => action(CONST.DELETE_BRAND_SUCCESS, { brandId });
export const deleteBrandError = (error: unknown) => action(CONST.DELETE_BRAND_ERROR, { error });
