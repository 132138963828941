import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { AdvancePaymentModel } from './model';

export type AdvancePaymentAction = ActionType<typeof actions>;
export type AdvancePaymentState = {
  readonly isFetching: boolean;
  readonly advancePaymentList: AdvancePaymentModel[];
  readonly orderAdvancePaymentList: AdvancePaymentModel[];
};

export const advancePaymentReducer = combineReducers<AdvancePaymentState, AdvancePaymentAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_ADVANCE_PAYMENTS_REQUEST:
      case CONST.GET_ORDER_ADVANCE_PAYMENTS_REQUEST:
      case CONST.ADD_ADVANCE_PAYMENT_REQUEST:
      case CONST.UPDATE_ADVANCE_PAYMENT_REQUEST:
      case CONST.DELETE_ADVANCE_PAYMENT_REQUEST:
      case CONST.GET_ADVANCE_PAYMENT_REQUEST:
        return true;

      case CONST.GET_ADVANCE_PAYMENTS_SUCCESS:
      case CONST.GET_ADVANCE_PAYMENTS_ERROR:
      case CONST.GET_ORDER_ADVANCE_PAYMENTS_SUCCESS:
      case CONST.GET_ORDER_ADVANCE_PAYMENTS_ERROR:
      case CONST.ADD_ADVANCE_PAYMENT_SUCCESS:
      case CONST.ADD_ADVANCE_PAYMENT_ERROR:
      case CONST.UPDATE_ADVANCE_PAYMENT_SUCCESS:
      case CONST.UPDATE_ADVANCE_PAYMENT_ERROR:
      case CONST.DELETE_ADVANCE_PAYMENT_SUCCESS:
      case CONST.DELETE_ADVANCE_PAYMENT_ERROR:
      case CONST.GET_ADVANCE_PAYMENT_SUCCESS:
      case CONST.GET_ADVANCE_PAYMENT_ERROR:
        return false;

      default:
        return state;
    }
  },
  advancePaymentList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_ADVANCE_PAYMENTS_SUCCESS:
        return action.payload.advancePayments;

      case CONST.ADD_ADVANCE_PAYMENT_SUCCESS:
        return [action.payload.advancePayment, ...state];

      case CONST.GET_ADVANCE_PAYMENT_SUCCESS:
      case CONST.UPDATE_ADVANCE_PAYMENT_SUCCESS: {
        const equipmentIdx = state.findIndex(item => item._id === action.payload.advancePayment._id);
        state.splice(equipmentIdx, 1, action.payload.advancePayment);

        return [...state];
      }

      case CONST.DELETE_ADVANCE_PAYMENT_SUCCESS:
        return state.filter(equipment => equipment._id !== action.payload.advancePaymentId);

      default:
        return state;
    }
  },
  orderAdvancePaymentList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_ORDER_ADVANCE_PAYMENTS_SUCCESS:
        return action.payload.advancePayments;

      case CONST.ADD_ADVANCE_PAYMENT_SUCCESS:
        return [action.payload.advancePayment, ...state];

      case CONST.UPDATE_ADVANCE_PAYMENT_SUCCESS: {
        const equipmentIdx = state.findIndex(item => item._id === action.payload.advancePayment._id);
        state.splice(equipmentIdx, 1, action.payload.advancePayment);

        return [...state];
      }

      case CONST.DELETE_ADVANCE_PAYMENT_SUCCESS:
        return state.filter(equipment => equipment._id !== action.payload.advancePaymentId);

      default:
        return state;
    }
  },
});
