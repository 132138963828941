import styled from 'styled-components';
import { Row } from 'antd';

export const RowGallerySC = styled(Row)`
  display: flex;
  margin-top: 5px;

  .imageWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 102px;
    height: 102px;
    margin-block: 0 8px;
    margin-inline: 0 8px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 8px;

    div {
      height: 86px;

      .imageGalleryElement {
        height: 100%;
        object-fit: contain;
      }
    }
  }
`;
