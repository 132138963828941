import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { Button } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
export const SettingsPage = () => {
  const [, setOpenSettingModal] = useState(false);

  return (
    <LayoutMain>
      <HeaderComponent title='Ustawienia'>
        <Button
          type='primary'
          onClick={() => setOpenSettingModal(true)}
        >
          Dodaj ustawienie
        </Button>
      </HeaderComponent>
      Ustawienia
    </LayoutMain>
  );
};
