import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { GeneralSettingModel, PaintingCostModel } from './model';

export type SettingsAction = ActionType<typeof actions>;
export type SettingsState = {
  readonly isFetchingSettings: boolean;
  readonly paintingList: PaintingCostModel[];
  readonly settingsList: GeneralSettingModel[];
};

export const settingsReducer = combineReducers<SettingsState, SettingsAction>({
  isFetchingSettings: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_PAINTING_LIST_REQUEST:
      case CONST.ADD_PAINTING_REQUEST:
      case CONST.UPDATE_PAINTING_REQUEST:
      case CONST.DELETE_PAINTING_REQUEST:
      case CONST.GET_SETTINGS_LIST_REQUEST:
        return true;

      case CONST.GET_PAINTING_LIST_SUCCESS:
      case CONST.GET_PAINTING_LIST_ERROR:
      case CONST.ADD_PAINTING_SUCCESS:
      case CONST.ADD_PAINTING_ERROR:
      case CONST.UPDATE_PAINTING_SUCCESS:
      case CONST.UPDATE_PAINTING_ERROR:
      case CONST.DELETE_PAINTING_SUCCESS:
      case CONST.DELETE_PAINTING_ERROR:
      case CONST.GET_SETTINGS_LIST_SUCCESS:
      case CONST.GET_SETTINGS_LIST_ERROR:
        return false;

      default:
        return state;
    }
  },
  paintingList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_PAINTING_LIST_SUCCESS:
        return action.payload.paintings;

      case CONST.ADD_PAINTING_SUCCESS:
        return [...state, action.payload.painting];

      case CONST.UPDATE_PAINTING_SUCCESS: {
        const paintingIdx = state.findIndex(item => item._id === action.payload.painting._id);
        state.splice(paintingIdx, 1, action.payload.painting);

        return [...state];
      }

      case CONST.DELETE_PAINTING_SUCCESS:
        return state.filter(painting => painting._id !== action.payload.paintingId);

      default:
        return state;
    }
  },
  settingsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_SETTINGS_LIST_SUCCESS:
        return action.payload.settings;

      case CONST.UPDATE_SETTING_SUCCESS: {
        const settingIdx = state.findIndex(item => item._id === action.payload.setting._id);
        state.splice(settingIdx, 1, action.payload.setting);

        return [...state];
      }

      default:
        return state;
    }
  },
});
