import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { UsersAdditionalMenu } from './UsersAdditionalMenu';

type UsersHeaderProps = {
  onSetOpenUserModal: (openUserModal: boolean) => void;
};

export const UsersHeader: FC<UsersHeaderProps> = props => {
  const { onSetOpenUserModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Użytkownicy'
      onAddElementOpenModal={onSetOpenUserModal}
    >
      <UsersAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Użytkownicy'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenUserModal(true)}
          >
            Dodaj użytkownika
          </Button>
        </Col>
        <UsersAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
