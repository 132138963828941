import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { ReportSellModel } from '../../../reducers/reports/model';

class APIClientReports extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/reports`;
  }

  public getReports = async (): Promise<ResponseModel<ReportSellModel[]>> => {
    return this.authFetch<ResponseModel<ReportSellModel[]>>(`${this.apiURL}/monthly-sells`, { method: 'GET' });
  };
}

export const apiClientReports = new APIClientReports();
