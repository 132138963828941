export const GET_EQUIPMENT_CATEGORIES_REQUEST = '@equipmentCategories/GET_EQUIPMENT_CATEGORIES_REQUEST';
export const GET_EQUIPMENT_CATEGORIES_SUCCESS = '@equipmentCategories/GET_EQUIPMENT_CATEGORIES_SUCCESS';
export const GET_EQUIPMENT_CATEGORIES_ERROR = '@equipmentCategories/GET_EQUIPMENT_CATEGORIES_ERROR';

export const ADD_EQUIPMENT_CATEGORY_REQUEST = '@equipmentCategories/ADD_EQUIPMENT_CATEGORY_REQUEST';
export const ADD_EQUIPMENT_CATEGORY_SUCCESS = '@equipmentCategories/ADD_EQUIPMENT_CATEGORY_SUCCESS';
export const ADD_EQUIPMENT_CATEGORY_ERROR = '@equipmentCategories/ADD_EQUIPMENT_CATEGORY_ERROR';

export const UPDATE_EQUIPMENT_CATEGORY_REQUEST = '@equipmentCategories/UPDATE_EQUIPMENT_CATEGORY_REQUEST';
export const UPDATE_EQUIPMENT_CATEGORY_SUCCESS = '@equipmentCategories/UPDATE_EQUIPMENT_CATEGORY_SUCCESS';
export const UPDATE_EQUIPMENT_CATEGORY_ERROR = '@equipmentCategories/UPDATE_EQUIPMENT_CATEGORY_ERROR';

export const DELETE_EQUIPMENT_CATEGORY_REQUEST = '@equipmentCategories/DELETE_EQUIPMENT_CATEGORY_REQUEST';
export const DELETE_EQUIPMENT_CATEGORY_SUCCESS = '@equipmentCategories/DELETE_EQUIPMENT_CATEGORY_SUCCESS';
export const DELETE_EQUIPMENT_CATEGORY_ERROR = '@equipmentCategories/DELETE_EQUIPMENT_CATEGORY_ERROR';
