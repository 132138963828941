export const GET_MASTS_REQUEST = '@masts/GET_MASTS_REQUEST';
export const GET_MASTS_SUCCESS = '@masts/GET_MASTS_SUCCESS';
export const GET_MASTS_ERROR = '@masts/GET_MASTS_ERROR';

export const ADD_MAST_REQUEST = '@masts/ADD_MAST_REQUEST';
export const ADD_MAST_SUCCESS = '@masts/ADD_MAST_SUCCESS';
export const ADD_MAST_ERROR = '@masts/ADD_MAST_ERROR';

export const UPDATE_MAST_REQUEST = '@masts/UPDATE_MAST_REQUEST';
export const UPDATE_MAST_SUCCESS = '@masts/UPDATE_MAST_SUCCESS';
export const UPDATE_MAST_ERROR = '@masts/UPDATE_MAST_ERROR';

export const DELETE_MAST_REQUEST = '@masts/DELETE_MAST_REQUEST';
export const DELETE_MAST_SUCCESS = '@masts/DELETE_MAST_SUCCESS';
export const DELETE_MAST_ERROR = '@masts/DELETE_MAST_ERROR';
