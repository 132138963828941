export const GET_PAINTING_LIST_REQUEST = '@settings/GET_PAINTING_LIST_REQUEST';
export const GET_PAINTING_LIST_SUCCESS = '@settings/GET_PAINTING_LIST_SUCCESS';
export const GET_PAINTING_LIST_ERROR = '@settings/GET_PAINTING_LIST_ERROR';

export const ADD_PAINTING_REQUEST = '@settings/ADD_PAINTING_REQUEST';
export const ADD_PAINTING_SUCCESS = '@settings/ADD_PAINTING_SUCCESS';
export const ADD_PAINTING_ERROR = '@settings/ADD_PAINTING_ERROR';

export const UPDATE_PAINTING_REQUEST = '@settings/UPDATE_PAINTING_REQUEST';
export const UPDATE_PAINTING_SUCCESS = '@settings/UPDATE_PAINTING_SUCCESS';
export const UPDATE_PAINTING_ERROR = '@settings/UPDATE_PAINTING_ERROR';

export const DELETE_PAINTING_REQUEST = '@settings/DELETE_PAINTING_REQUEST';
export const DELETE_PAINTING_SUCCESS = '@settings/DELETE_PAINTING_SUCCESS';
export const DELETE_PAINTING_ERROR = '@settings/DELETE_PAINTING_ERROR';

export const GET_SETTINGS_LIST_REQUEST = '@settings/GET_SETTINGS_LIST_REQUEST';
export const GET_SETTINGS_LIST_SUCCESS = '@settings/GET_SETTINGS_LIST_SUCCESS';
export const GET_SETTINGS_LIST_ERROR = '@settings/GET_SETTINGS_LIST_ERROR';

export const UPDATE_SETTING_REQUEST = '@settings/UPDATE_SETTING_REQUEST';
export const UPDATE_SETTING_SUCCESS = '@settings/UPDATE_SETTING_SUCCESS';
export const UPDATE_SETTING_ERROR = '@settings/UPDATE_SETTING_ERROR';
