import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { GeneralSettingModel, PaintingCostModel } from './model';

export const getPaintingsRequest = () => action(CONST.GET_PAINTING_LIST_REQUEST);
export const getPaintingsSuccess = (paintings: PaintingCostModel[]) =>
  action(CONST.GET_PAINTING_LIST_SUCCESS, { paintings });
export const getPaintingsError = (error: unknown) => action(CONST.GET_PAINTING_LIST_ERROR, { error });

export const addPaintingRequest = (
  painting: Omit<
    PaintingCostModel,
    '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'advancePrice' | 'ownerId' | 'price'
  >,
  onSuccess?: () => void,
) => action(CONST.ADD_PAINTING_REQUEST, { painting, onSuccess });
export const addPaintingSuccess = (painting: PaintingCostModel) => action(CONST.ADD_PAINTING_SUCCESS, { painting });
export const addPaintingError = (error: unknown) => action(CONST.ADD_PAINTING_ERROR, { error });

export const updatePaintingRequest = (
  painting: Omit<PaintingCostModel, 'client' | 'ownerId' | 'price'>,
  onSuccess?: () => void,
) => action(CONST.UPDATE_PAINTING_REQUEST, { painting, onSuccess });
export const updatePaintingSuccess = (painting: PaintingCostModel) =>
  action(CONST.UPDATE_PAINTING_SUCCESS, { painting });
export const updatePaintingError = (error: unknown) => action(CONST.UPDATE_PAINTING_ERROR, { error });

export const deletePaintingRequest = (paintingId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_PAINTING_REQUEST, { paintingId, onSuccess });
export const deletePaintingSuccess = (paintingId: string) => action(CONST.DELETE_PAINTING_SUCCESS, { paintingId });
export const deletePaintingError = (error: unknown) => action(CONST.DELETE_PAINTING_ERROR, { error });

export const getSettingsRequest = () => action(CONST.GET_SETTINGS_LIST_REQUEST);
export const getSettingsSuccess = (settings: GeneralSettingModel[]) =>
  action(CONST.GET_SETTINGS_LIST_SUCCESS, { settings });
export const getSettingsError = (error: unknown) => action(CONST.GET_SETTINGS_LIST_ERROR, { error });

export const updateSettingRequest = (
  setting: Omit<GeneralSettingModel, 'client' | 'ownerId' | 'price'>,
  onSuccess?: () => void,
) => action(CONST.UPDATE_SETTING_REQUEST, { setting, onSuccess });
export const updateSettingSuccess = (setting: GeneralSettingModel) => action(CONST.UPDATE_SETTING_SUCCESS, { setting });
export const updateSettingError = (error: unknown) => action(CONST.UPDATE_SETTING_ERROR, { error });
