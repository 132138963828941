export const GET_EQUIPMENTS_REQUEST = '@equipments/GET_EQUIPMENTS_REQUEST';
export const GET_EQUIPMENTS_SUCCESS = '@equipments/GET_EQUIPMENTS_SUCCESS';
export const GET_EQUIPMENTS_ERROR = '@equipments/GET_EQUIPMENTS_ERROR';

export const ADD_EQUIPMENT_REQUEST = '@equipments/ADD_EQUIPMENT_REQUEST';
export const ADD_EQUIPMENT_SUCCESS = '@equipments/ADD_EQUIPMENT_SUCCESS';
export const ADD_EQUIPMENT_ERROR = '@equipments/ADD_EQUIPMENT_ERROR';

export const UPDATE_EQUIPMENT_REQUEST = '@equipments/UPDATE_EQUIPMENT_REQUEST';
export const UPDATE_EQUIPMENT_SUCCESS = '@equipments/UPDATE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_ERROR = '@equipments/UPDATE_EQUIPMENT_ERROR';

export const DELETE_EQUIPMENT_REQUEST = '@equipments/DELETE_EQUIPMENT_REQUEST';
export const DELETE_EQUIPMENT_SUCCESS = '@equipments/DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_ERROR = '@equipments/DELETE_EQUIPMENT_ERROR';

export const UPDATE_EQUIPMENTS_STATUSES_REQUEST = '@equipments/UPDATE_EQUIPMENTS_STATUSES_REQUEST';
export const UPDATE_EQUIPMENTS_STATUSES_SUCCESS = '@equipments/UPDATE_EQUIPMENTS_STATUSES_SUCCESS';
export const UPDATE_EQUIPMENTS_STATUSES_ERROR = '@equipments/UPDATE_EQUIPMENTS_STATUSES_ERROR';

export const GET_EQUIPMENT_REQUEST = '@equipments/GET_EQUIPMENT_REQUEST';
export const GET_EQUIPMENT_SUCCESS = '@equipments/GET_EQUIPMENT_SUCCESS';
export const GET_EQUIPMENT_ERROR = '@equipments/GET_EQUIPMENT_ERROR';

export const GET_EQUIPMENT_INTERNAL_ID_REQUEST = '@equipments/GET_EQUIPMENT_INTERNAL_ID_REQUEST';
export const GET_EQUIPMENT_INTERNAL_ID_SUCCESS = '@equipments/GET_EQUIPMENT_INTERNAL_ID_SUCCESS';
export const GET_EQUIPMENT_INTERNAL_ID_ERROR = '@equipments/GET_EQUIPMENT_INTERNAL_ID_ERROR';

export const GET_EQUIPMENT_STOCK_LIST_REQUEST = '@equipments/GET_EQUIPMENT_STOCK_LIST_REQUEST';
export const GET_EQUIPMENT_STOCK_LIST_SUCCESS = '@equipments/GET_EQUIPMENT_STOCK_LIST_SUCCESS';
export const GET_EQUIPMENT_STOCK_LIST_ERROR = '@equipments/GET_EQUIPMENT_STOCK_LIST_ERROR';
