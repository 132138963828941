import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Form, Input, Row, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { RootReducerState } from '../../reducers';
import { LayoutLogin } from '../../layout/login/Login';
import { Paths } from '../../router/paths';
import { initFormik } from './formik';

import './style.scss';

const { Item } = Form;
const { Text } = Typography;

export type SetPasswordFormValues = {
  password: string;
  passwordConfirmation: string;
};

export type SetPasswordFormProps = {
  showMessage: boolean;
  handleSubmit: (values: SetPasswordFormValues) => void;
};

export const SetPasswordForm = (props: SetPasswordFormProps) => {
  const { showMessage } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.users.isFetching);
  const formik = useFormik(initFormik(props));

  return (
    <LayoutLogin>
      {!showMessage && (
        <Form
          layout='vertical'
          className='loginForm'
        >
          <Text className='loginHeaderText'>Ustawienie nowego hasła</Text>
          <Item
            className='inputWrapper'
            label='Hasło'
            hasFeedback={true}
            validateStatus={formik.errors.password && formik.touched.password ? 'error' : ''}
            help={formik.errors.password && formik.touched.password ? formik.errors.password : null}
          >
            <Input.Password
              name='password'
              value={formik.values.password}
              placeholder='Hasło'
              prefix={<LockOutlined />}
              onChange={formik.handleChange}
            />
          </Item>

          <Item
            className='inputWrapper'
            label='Potwierdź hasło'
            hasFeedback={true}
            validateStatus={formik.errors.passwordConfirmation && formik.touched.passwordConfirmation ? 'error' : ''}
            help={
              formik.errors.passwordConfirmation && formik.touched.passwordConfirmation
                ? formik.errors.passwordConfirmation
                : null
            }
          >
            <Input.Password
              name='passwordConfirmation'
              value={formik.values.passwordConfirmation}
              placeholder='Potwierdź hasło'
              prefix={<LockOutlined />}
              onChange={formik.handleChange}
            />
          </Item>

          <Button
            type='primary'
            loading={isFetching}
            htmlType='submit'
            onClick={formik.submitForm}
          >
            Zresetuj hasło
          </Button>
        </Form>
      )}
      {showMessage && (
        <div className='informMessage'>
          Zmieniliśmy Twoje hasło przejdź do strony logowania.
          <Row
            justify='end'
            align='middle'
          >
            <Link
              className='link'
              to={Paths.LOGIN_PAGE}
            >
              Zaloguj się
            </Link>
          </Row>
        </div>
      )}
    </LayoutLogin>
  );
};
