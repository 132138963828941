import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { CategoriesList } from '../../component/categories/CategoriesList';
import { CategoriesHeader } from './CategoriesHeader';

export const CategoriesPage = () => {
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  return (
    <LayoutMain>
      <CategoriesHeader onSetOpenCategoryModal={setOpenCategoryModal} />
      <CategoriesList
        openCategoryModal={openCategoryModal}
        clickOpenCategoryModal={openCategoryModal => setOpenCategoryModal(openCategoryModal)}
      />
    </LayoutMain>
  );
};
