import { useQuery } from 'react-query';
import { apiClientForklifts } from './index';

const queryKeys = {
  getForklift: (id: string) => `forklifts/${id}`,
  getAvailableForklifts: 'forklifts/available',
};

export const useGetForklift = (id: string) => {
  return useQuery(queryKeys.getForklift(id), async () => {
    const response = await apiClientForklifts.getForklift(id);
    return response.data;
  });
};

export const useGetAvailableForklifts = () => {
  return useQuery(
    queryKeys.getAvailableForklifts,
    async () => {
      const response = await apiClientForklifts.getAvailableForklifts();
      return response.data;
    },
    { initialData: [] },
  );
};
