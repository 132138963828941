import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { MastFormProps, MastFormValues } from '.';
import { MastModel } from '../../reducers/masts/model';

export const initFormik = (props: MastFormProps, mast: MastModel | undefined): FormikConfig<MastFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: mast?.name ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Nazwa jest wymagana'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
