import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { ForkliftList } from '../../component/forklift/ForkliftList';
import { LayoutMain } from '../../layout/main/Main';
import { RootReducerState } from '../../reducers';
import { ForkliftModel, ProductStatusListType, LocalStorageKeys } from '../../reducers/forklift/model';
import { getForkliftsRequest } from '../../reducers/forklift/actions';
import { isProductStatus } from '../../utils/productsStatus';
import { OrderForm, OrderFormValues } from '../../forms/orders';
import { addOrderRequest } from '../../reducers/orders/actions';
import { ForkliftsTabs } from './ForkliftsTabs';
import { ProductType } from '../../reducers/orders/model';
import { ReservationForm, ReservationFormValues } from '../../forms/reservations';
import { addReservationRequest } from '../../reducers/reservations/actions';
import { useViewport } from '../../utils/customHooks/useViewport';
import { ForkliftMobileList } from '../../component/forklift/mobile/ForkliftMobileList';
import { OfferForm, OfferFormValues } from '../../forms/offers';
import { addOfferRequest, getOfferPDFRequest } from '../../reducers/offers/actions';

import { ForkliftsHeader } from './ForkliftsHeader';

import './style.scss';

export const ForkliftsPage = () => {
  const forkliftsList = useSelector<RootReducerState, ForkliftModel[]>(state => state.forklifts.forkliftsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.forklifts.isFetching);
  const [openForkliftModal, setOpenForkliftModal] = useState(false);
  const [status, setStatus] = useState<ProductStatusListType>(ProductStatusListType.AVAILABLE);
  const [groupEditForklifts, setGroupEditForklifts] = useState<string[]>([]);
  const [tableSettings, setTableSettings] = useState<string[]>(
    JSON.parse(window.localStorage.getItem(LocalStorageKeys.FORKLIFT_TABLE_SETTINGS) ?? '[]') || [],
  );
  const dispatch = useDispatch();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openReservationModal, setOpenReservationModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const { isMobile } = useViewport();
  const { forkliftsSold, forkliftsReserved, forkliftsAvailable } = useMemo(() => {
    const forkliftsSoldAmount = forkliftsList.filter(forklift => isProductStatus(forklift, ProductStatusListType.SOLD));
    const forkliftsReserved = forkliftsList.filter(forklift =>
      isProductStatus(forklift, ProductStatusListType.IN_ORDER_RESERVED),
    );
    const forkliftsAvailable = forkliftsList.filter(forklift =>
      isProductStatus(forklift, ProductStatusListType.AVAILABLE),
    );

    return {
      forkliftsSold: forkliftsSoldAmount,
      forkliftsReserved: forkliftsReserved,
      forkliftsAvailable: forkliftsAvailable,
    };
  }, [forkliftsList]);
  const filteredForkliftList = useMemo(() => {
    let filteredForkliftList: ForkliftModel[] = [];
    switch (status) {
      case ProductStatusListType.ALL:
        filteredForkliftList = forkliftsList;
        break;
      case ProductStatusListType.AVAILABLE:
        filteredForkliftList = forkliftsAvailable;
        break;
      case ProductStatusListType.IN_ORDER_RESERVED:
        filteredForkliftList = forkliftsReserved;
        break;
      case ProductStatusListType.SOLD:
        filteredForkliftList = forkliftsSold;
        break;
    }

    return filteredForkliftList;
  }, [status, forkliftsList, forkliftsAvailable, forkliftsReserved, forkliftsSold]);

  const selectedForklifts = forkliftsList.filter(forklift => groupEditForklifts.includes(forklift._id));

  useEffect(() => {
    dispatch(getForkliftsRequest());
  }, [dispatch]);

  const handleSubmit = (values: OrderFormValues) => {
    dispatch(
      addOrderRequest({ ...values }, () => {
        setOpenOrderModal(false);
      }),
    );
  };

  const handleSubmitReservation = (values: ReservationFormValues) => {
    dispatch(
      addReservationRequest(
        {
          ...values,
        },
        () => {
          setOpenReservationModal(false);
        },
      ),
    );
  };

  const handleSubmitOffer = (values: OfferFormValues) => {
    const offerValue = {
      clientId: values.clientId ? values.clientId : null,
      currency: values.currency,
      expiredAt: values.expiredAt,
      notes: values.notes,
      offerNr: values.offerNr,
      productsInOffer: values.productsInOffer,
    };
    dispatch(
      addOfferRequest({ ...offerValue }, offerId => {
        dispatch(getOfferPDFRequest(offerId));
        setOpenOfferModal(false);
      }),
    );
  };

  return (
    <LayoutMain>
      <ForkliftsHeader
        setOpenForkliftModal={setOpenForkliftModal}
        setOpenOrderModal={setOpenOrderModal}
        setOpenReservationModal={setOpenReservationModal}
        setOpenOfferModal={setOpenOfferModal}
        selectedForklifts={selectedForklifts}
        groupEditForklifts={groupEditForklifts}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
      />
      <ForkliftsTabs
        forkliftsAmount={forkliftsList.length}
        forkliftsSoldAmount={forkliftsSold.length}
        forkliftsAvailableAmount={forkliftsAvailable.length}
        forkliftsReservedAmount={forkliftsReserved.length}
        onChange={status => {
          setStatus(status);
          setGroupEditForklifts([]);
        }}
      />
      {isMobile && (
        <ForkliftMobileList
          isFetchingForkliftsList={isFetching}
          forkliftsList={filteredForkliftList}
          openForkliftModal={openForkliftModal}
          clickOpenForkliftModal={openForkliftModal => setOpenForkliftModal(openForkliftModal)}
        />
      )}
      {!isMobile && (
        <ForkliftList
          openForkliftModal={openForkliftModal}
          clickOpenForkliftModal={openForkliftModal => setOpenForkliftModal(openForkliftModal)}
          forkliftsList={filteredForkliftList}
          groupEditStatus={groupEditForklifts}
          setGroupEditStatus={selectedKeys => setGroupEditForklifts(selectedKeys)}
          tableSettings={tableSettings}
          selectedForkliftTab={status}
        />
      )}
      <DrawerAddEdit
        titleEdit='Edytuj dane zamówienia'
        titleAdd='Dodaj zamówienie'
        width={'60%'}
        openModal={openOrderModal}
        handleCloseModal={() => setOpenOrderModal(false)}
      >
        <OrderForm
          handleSubmit={handleSubmit}
          order={{
            productsInOrder: selectedForklifts.map(forklift => ({
              productId: forklift._id,
              price: forklift.priceSuggested,
              notes: '',
              technicalReview: false,
              guarantee: false,
              udt: false,
              rectifier: false,
              type: ProductType.FORKLIFT,
            })),
          }}
          handleCancelForm={() => setOpenOrderModal(false)}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Zarezerwuj wózki'
        titleAdd='Zarezerwuj wózki'
        width={'60%'}
        openModal={openReservationModal}
        handleCloseModal={() => setOpenReservationModal(false)}
      >
        <ReservationForm
          handleSubmit={handleSubmitReservation}
          handleCancelForm={() => setOpenReservationModal(false)}
          reservation={{
            productsInReservation: selectedForklifts.map(forklift => ({
              productId: forklift._id,
              type: ProductType.FORKLIFT,
            })),
          }}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Edytuj ofertę'
        titleAdd='Utwórz ofertę'
        width={'60%'}
        openModal={openOfferModal}
        handleCloseModal={() => setOpenOfferModal(false)}
      >
        <OfferForm
          handleSubmit={handleSubmitOffer}
          handleCancelForm={() => setOpenOfferModal(false)}
          offer={{
            productsInOffer: selectedForklifts.map(forklift => ({
              productId: forklift._id,
              price: forklift.priceSuggested,
              notes: '',
              type: ProductType.FORKLIFT,
              assetId: forklift.assets[0]?._id,
            })),
          }}
        />
      </DrawerAddEdit>
    </LayoutMain>
  );
};
