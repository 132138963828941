import React from 'react';
import { type MenuProps } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useViewport } from '../../utils/customHooks/useViewport';
import { authService } from '../../services/authService';
import { Paths } from '../../router/paths';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { UserModel } from '../../reducers/user/model';
import { useNavigate } from 'react-router-dom';
import { ButtonLogoutSC, MenuItemUserTextSC, MenuSC } from './styles';
import { LogoutButton } from './LogoutButton';

export const LogoutMenuButton = () => {
  const user = useSelector<RootReducerState, UserModel | null>(state => state.users.userLoggedIn);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.users.isFetching);
  const navigate = useNavigate();
  const { isMobile } = useViewport();

  const handleLogout = () => {
    authService.logout();
    navigate(Paths.LOGIN_PAGE);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          Witaj {user?.name} {user?.surname}
          <br />
          <MenuItemUserTextSC type='secondary'>{user?.email}</MenuItemUserTextSC>
        </>
      ),
    },
    {
      key: '2',
      label: (
        <ButtonLogoutSC
          type='link'
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          loading={isFetching}
        >
          Wyloguj
        </ButtonLogoutSC>
      ),
    },
  ];

  return !isMobile ? <LogoutButton items={items} /> : <MenuSC items={items}></MenuSC>;
};
