import { createContext, FC, useEffect, useMemo } from 'react';
import { UserModel } from '../../reducers/user/model';
import { defineAbilitiesFor } from '../../services/authService/ability';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { getMeRequest } from '../../reducers/user/actions';

export const AbilityContext = createContext<{
  can: (abilityAction: string, abilitySubject: string) => boolean;
  cannot: (abilityAction: string, abilitySubject: string) => boolean;
  // @ts-expect-error
}>({});

export const AbilityProvider: FC = props => {
  const { children } = props;
  const user = useSelector<RootReducerState, UserModel | null>(state => state.users.userLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMeRequest());
  }, [dispatch]);

  const ability = useMemo(() => {
    return defineAbilitiesFor(user);
  }, [user]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
