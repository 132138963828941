import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTableList } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentStockistRequest } from '../../reducers/equipment/actions';
import { RootReducerState } from '../../reducers';
import { useViewport } from '../../utils/customHooks/useViewport';

export const EquipmentStockList: FC = () => {
  const isFetching = useSelector<RootReducerState, boolean>(state => state.equipment.isFetchingStockList);
  const dispatch = useDispatch();
  const { isMobile } = useViewport();

  const renderButton = (isMobile: boolean) => (
    <Button
      type={isMobile ? 'link' : 'default'}
      shape={isMobile ? 'default' : 'circle'}
      icon={
        <FontAwesomeIcon
          icon={!isFetching ? faTableList : faCircleNotch}
          size='xl'
          spin={isFetching}
        />
      }
      onClick={() => dispatch(getEquipmentStockistRequest())}
      style={isMobile ? { padding: 0 } : {}}
    >
      {isMobile ? 'Pobierz stocklistę' : ''}
    </Button>
  );

  return isMobile ? (
    renderButton(isMobile)
  ) : (
    <Col>
      <Tooltip
        title='Pobierz stock listę'
        placement='bottomRight'
      >
        {renderButton(isMobile)}
      </Tooltip>
    </Col>
  );
};
