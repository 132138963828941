import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { RootReducerState } from '../../reducers';
import { CostModel } from '../../reducers/costs/model';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { deleteCostsRequest, getProductCostRequest } from '../../reducers/costs/actions';

export type CostsListProps = {
  forkliftId?: string;
  setSelectedCost: (cost: CostModel | undefined) => void;
};

export const CostsList: FC<CostsListProps> = props => {
  const { forkliftId, setSelectedCost } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.costs.isFetching);
  const costsList = useSelector<RootReducerState, CostModel[]>(state => state.costs.productCosts);
  const [, setDeleteCostModal] = useState(false);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();

  useEffect(() => {
    if (forkliftId) {
      dispatch(getProductCostRequest(forkliftId));
    }
  }, [dispatch, forkliftId]);

  const handleDeleteCostModal = (cost: CostModel, callback?: () => void) => {
    dispatch(
      deleteCostsRequest(cost, () => {
        if (typeof callback === 'function') {
          callback();
        }
      }),
    );
  };

  const columns: ColumnsType<CostModel> = [
    {
      title: 'Nazwa kosztu',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', 'nazwy'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Koszt',
      dataIndex: 'cost',
      key: 'cost',
      ...getColumnSearchProps('cost', 'kosztu'),
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string, cost) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj koszt'
              onClick={() => setSelectedCost(cost)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie kosztu'
              description='Czy na pewno chcesz usunąć koszt?'
              onConfirm={() => handleDeleteCostModal(cost)}
              onCancel={() => setDeleteCostModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń koszt'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={costsList}
      loading={isFetching}
      rowKey={record => record._id || ''}
      size='small'
    />
  );
};
