import React from 'react';
import { Button } from 'antd';
//import { Typography } from '@mui/material';
import { Paths } from '../../router/paths';

import './style.scss';

export const Page404: React.FC = () => {
  return (
    <div className='appContainer'>
      <div className='page-404'>
        <img
          className='logo-404'
          alt='Forklift Manager'
          src='/images/logo.png'
        />
        {/*<Typography variant='h2'>Ta przestrzeń jest pusta</Typography>*/}
        <p>
          Strona, której szukasz, <b>nie istnieje</b> (błąd 404)
        </p>
        <Button href={Paths.HOME_PAGE}>Wróć do Strony Głównej</Button>
      </div>
    </div>
  );
};
