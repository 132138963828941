import { FormikConfig } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { EquipmentFormProps, EquipmentFormValues } from '.';
import { EquipmentInternalId, EquipmentModel } from '../../reducers/equipment/model';
import { ProductStatus } from '../../reducers/forklift/model';

export const initFormik = (
  props: EquipmentFormProps,
  equipment: EquipmentModel | undefined,
  equipmentInternalId: EquipmentInternalId | null,
): FormikConfig<EquipmentFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    internalId: equipment?.internalId ?? equipmentInternalId?.nextId ?? '',
    brandId: equipment?.brandId ?? '',
    categoryId: equipment?.categoryId ?? '',
    productionYear: equipment?.productionYear ?? dayjs().get('year'),
    price: equipment?.price ?? 0,
    priceWholesale: equipment?.priceWholesale ?? 0,
    type: equipment?.type ?? '',
    serialNumber: equipment?.serialNumber ?? '',
    status: equipment?.status ?? ProductStatus.MAGAZINE,
    liftingCapacity: equipment?.liftingCapacity ?? 0,
    mounting: equipment?.mounting ?? '',
    width: equipment?.width ?? 0,
    minOperatingRange: equipment?.minOperatingRange ?? 0,
    maxOperatingRange: equipment?.maxOperatingRange ?? 0,
    lengthFork: equipment?.lengthFork ?? 0,
    notes: equipment?.notes ?? '',
    assets: equipment?.assets ?? [],
    boughtFrom: equipment?.boughtFrom,
  },
  validationSchema: () =>
    yup.object({
      brandId: yup.string().required('Marka jest wymagana'),
      categoryId: yup.string().required('Kategoria jest wymagana'),
      productionYear: yup.string().required('Rok produkcji jest wymagany'),
      type: yup.string().required('Typ jest wymagany'),
      price: yup.number().positive('Cena musi być większa od zera').required('Cena jest wymagana'),
      priceWholesale: yup.number().positive('Cena musi być większa od zera').required('Cena hurtowa jest wymagana'),
      serialNumber: yup.string().required('Numer seryjny jest wymagany'),
      mastId: yup.string(),
      status: yup.string().required('Status jest wymagany'),
      liftingCapacity: yup.number().positive('Udźwig musi być większy od zera').required('Udźwig jest wymagany'),
      mastFreeLifting: yup.number().typeError('Pole musi być liczbą'),
      mounting: yup.string().typeError('Pole jest wymagane'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
