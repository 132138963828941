import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { CostModel } from './model';

export type CostsAction = ActionType<typeof actions>;
export type CostsState = {
  readonly isFetching: boolean;
  readonly costsList: CostModel[];
  readonly productCosts: CostModel[];
};

export const costsReducer = combineReducers<CostsState, CostsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_COSTS_REQUEST:
      case CONST.GET_PRODUCT_COSTS_REQUEST:
      case CONST.ADD_COSTS_REQUEST:
      case CONST.UPDATE_COSTS_REQUEST:
      case CONST.DELETE_COSTS_REQUEST:
        return true;

      case CONST.GET_COSTS_SUCCESS:
      case CONST.GET_COSTS_ERROR:
      case CONST.GET_PRODUCT_COSTS_SUCCESS:
      case CONST.GET_PRODUCT_COSTS_ERROR:
      case CONST.ADD_COSTS_SUCCESS:
      case CONST.ADD_COSTS_ERROR:
      case CONST.UPDATE_COSTS_SUCCESS:
      case CONST.UPDATE_COSTS_ERROR:
      case CONST.DELETE_COSTS_SUCCESS:
      case CONST.DELETE_COSTS_ERROR:
        return false;

      default:
        return state;
    }
  },
  costsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_COSTS_SUCCESS:
        return action.payload.costs;

      case CONST.ADD_COSTS_SUCCESS:
        return [...state, action.payload.cost];

      case CONST.UPDATE_COSTS_SUCCESS: {
        const costIdx = state.findIndex(item => item._id === action.payload.cost._id);
        state.splice(costIdx, 1, action.payload.cost);

        return [...state];
      }

      case CONST.DELETE_COSTS_SUCCESS:
        return state.filter(cost => cost._id !== action.payload.costId);

      default:
        return state;
    }
  },
  productCosts: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_PRODUCT_COSTS_SUCCESS:
        return action.payload.costs;

      case CONST.ADD_COSTS_SUCCESS:
        return [...state, action.payload.cost];

      case CONST.UPDATE_COSTS_SUCCESS: {
        const costIdx = state.findIndex(item => item._id === action.payload.cost._id);
        state.splice(costIdx, 1, action.payload.cost);

        return [...state];
      }

      case CONST.DELETE_COSTS_SUCCESS:
        return state.filter(cost => cost._id !== action.payload.costId);

      default:
        return state;
    }
  },
});
