import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { OrdersAdditionalMenu } from './OrdersAdditionalMenu';

type OrdersHeaderProps = {
  onSetOpenOrderModal: (openOrderModal: boolean) => void;
};

export const OrdersHeader: FC<OrdersHeaderProps> = props => {
  const { onSetOpenOrderModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Zamówienia'
      onAddElementOpenModal={onSetOpenOrderModal}
    >
      <OrdersAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Zamówienia'>
      <Row
        align='middle'
        gutter={16}
      >
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenOrderModal(true)}
          >
            Dodaj zamówienie
          </Button>
        </Col>
        <OrdersAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
