import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../router/paths';

import './style.scss';

interface LogoProps {
  collapsed: boolean;
}

export const Logo: React.FC<LogoProps> = props => {
  const { collapsed } = props;
  return (
    <Link
      to={Paths.HOME_PAGE}
      className='logoWrapper'
    >
      {collapsed ? (
        <img
          src='/images/logo-mini.png'
          className='logoMini'
          alt='logo'
        />
      ) : (
        <img
          src='/images/logo-white.png'
          className='logo'
          alt='logo'
        />
      )}
    </Link>
  );
};
