import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../reducers';
import { PaintingCostModel } from '../../../reducers/settings/model';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useFilterAntTable } from '../../../utils/customHooks/useFilterAntTable';
import {
  addPaintingRequest,
  deletePaintingRequest,
  getPaintingsRequest,
  updatePaintingRequest,
} from '../../../reducers/settings/actions';
import { CategoryModel } from '../../../reducers/categories/model';
import { getCategoriesRequest } from '../../../reducers/categories/actions';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import { PaintingForm, PaintingFormValues } from '../../../forms/settings/painting';

type PaintingsListProps = {
  openPaintingModal: boolean;
  clickOpenPaintingModal: (openUserModal) => void;
};

type PaintingCostData = PaintingCostModel & {
  forkliftCategoryName: string;
};

export const PaintingsList: FC<PaintingsListProps> = props => {
  const { openPaintingModal, clickOpenPaintingModal } = props;
  const paintingsList = useSelector<RootReducerState, PaintingCostModel[]>(state => state.settings.paintingList);
  const categoryList = useSelector<RootReducerState, CategoryModel[]>(state => state.categories.categoriesList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.settings.isFetchingSettings);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();
  const [selectedPainting, setSelectedPainting] = useState<PaintingCostModel | undefined>(undefined);
  const [, setDeletePaintingModal] = useState(false);

  useEffect(() => {
    dispatch(getPaintingsRequest());
    dispatch(getCategoriesRequest());
  }, [dispatch]);

  const paintingsListData: PaintingCostData[] = useMemo(() => {
    return paintingsList.map(painting => ({
      ...painting,
      forkliftCategoryName: categoryList.find(({ _id }) => painting.forkliftCategoryId === _id)?.name ?? '',
    }));
  }, [paintingsList, categoryList]);

  const handleEditPaintingModal = (paintingId: string) => {
    const painting = paintingsList.find(item => item._id === paintingId);
    setSelectedPainting(painting);
    clickOpenPaintingModal(true);
  };

  const handleDeletePaintingModal = (paintingId: string) => {
    dispatch(
      deletePaintingRequest(paintingId, () => {
        setDeletePaintingModal(false);
      }),
    );
  };

  const handleClosePaintingModal = () => {
    setSelectedPainting(undefined);
    clickOpenPaintingModal(false);
  };

  const handleSubmit = (values: PaintingFormValues) => {
    if (selectedPainting) {
      dispatch(
        updatePaintingRequest(
          {
            ...selectedPainting,
            ...values,
          },
          () => {
            setSelectedPainting(undefined);
            clickOpenPaintingModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addPaintingRequest(values, () => {
          setSelectedPainting(undefined);
          clickOpenPaintingModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<PaintingCostData> = [
    {
      title: 'Kategoria',
      dataIndex: 'forkliftCategoryName',
      key: 'forkliftCategoryName',
      width: 150,
      ...getColumnSearchProps('forkliftCategoryName', 'kategorii'),
      sorter: (a, b) => a.forkliftCategoryName.localeCompare(b.forkliftCategoryName),
    },
    {
      title: 'Udźwig',
      dataIndex: 'liftCapacityFrom',
      key: 'liftCapacityFrom',
      width: 150,
      align: 'center' as AlignType,
      render: (liftCapacityFrom, painting) => (
        <>
          {liftCapacityFrom} - {painting.liftCapacityTo} kg
        </>
      ),
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      align: 'right' as AlignType,
      ...getColumnSearchProps('price', 'ceny'),
      render: price => <>{price} zł</>,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      width: 120,
      render: (_id: string, user) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj ustawienia'
              onClick={() => handleEditPaintingModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie ustawienia'
              description='Czy na pewno chcesz usunąć ustawienia?'
              onConfirm={() => handleDeletePaintingModal(user._id)}
              onCancel={() => setDeletePaintingModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń ustawienie'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={paintingsListData}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj ustawienia'
        titleAdd='Dodaj ustawienia'
        openModal={openPaintingModal}
        handleCloseModal={handleClosePaintingModal}
        selectedItem={selectedPainting}
      >
        <PaintingForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleClosePaintingModal}
          painting={selectedPainting}
        />
      </DrawerAddEdit>
    </>
  );
};
