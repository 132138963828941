import React, { useContext, useState, FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { DollarOutlined, SettingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartSimple,
  faMoneyCheckDollar,
  faUsers,
  faUsersGear,
  faScrewdriverWrench,
  faUserCog,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Paths } from '../../router/paths';
import { ReactComponent as ForkliftIcon } from '../../assets/icons/menu-forklift-icon.svg';
import { AbilityContext } from '../../providers/ability/AbilityProvider';
import { AbilityAction, AbilitySubject } from '../../services/authService/ability';
import { useViewport } from '../../utils/customHooks/useViewport';

import './style.scss';

type MenuComponentProps = {
  collapsed: boolean;
};

export const MenuComponent: FC<MenuComponentProps> = props => {
  const { collapsed } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const abilityCtx = useContext(AbilityContext);
  const { height } = useViewport();

  const [openKeys] = useState<string[]>(() => {
    switch (location.pathname as Paths) {
      case Paths.PAINTING:
      case Paths.GENERAL_SETTINGS:
        return [Paths.SETTINGS];
      case Paths.BRANDS:
      case Paths.MASTS:
      case Paths.CATEGORIES:
        return [Paths.HOME_PAGE];
      case Paths.EQUIPMENT_BRANDS:
      case Paths.EQUIPMENT_CATEGORIES:
        return [Paths.EQUIPMENT];
      default:
        return [];
    }
  });

  return (
    <Menu
      style={{ height: height - 104 }}
      onClick={e => {
        const array = e.key.split('_');
        const index = array.indexOf('sub');

        if (index > -1) {
          array.splice(index, 1);
        }

        return navigate(array[0]);
      }}
      triggerSubMenuAction='click'
      theme='dark'
      mode='inline'
      defaultSelectedKeys={[location.pathname]}
      defaultOpenKeys={openKeys}
      items={[
        {
          key: Paths.HOME_PAGE,
          icon: <ForkliftIcon className='forkliftMenuIcon' />,
          label: <Link to={Paths.HOME_PAGE}>Wózki widłowe</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.FORKLIFT),
          children: [
            {
              key: `sub_${Paths.HOME_PAGE}`,
              label: <Link to={Paths.HOME_PAGE}>Wózki widłowe</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.FORKLIFT) || !collapsed,
            },
            {
              key: Paths.BRANDS,
              label: <Link to={Paths.BRANDS}>Marki</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.FORKLIFT_BRAND),
            },
            {
              key: Paths.MASTS,
              label: <Link to={Paths.MASTS}>Maszty</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.FORKLIFT_MAST),
            },
            {
              key: Paths.CATEGORIES,
              label: <Link to={Paths.CATEGORIES}>Kategorie</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.FORKLIFT_CATEGORY),
            },
          ].filter(item => !item.disabled),
        },
        {
          key: Paths.EQUIPMENT,
          icon: (
            <FontAwesomeIcon
              icon={faScrewdriverWrench}
              size='xl'
            />
          ),
          label: <Link to={Paths.EQUIPMENT}>Osprzęt</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.EQUIPMENT),
          children: [
            {
              key: `sub_${Paths.EQUIPMENT}`,
              label: <Link to={Paths.EQUIPMENT}>Osprzęt</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.EQUIPMENT) || !collapsed,
            },
            {
              key: Paths.EQUIPMENT_BRANDS,
              label: <Link to={Paths.EQUIPMENT_BRANDS}>Marki</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.EQUIPMENT_BRAND),
            },
            {
              key: Paths.EQUIPMENT_CATEGORIES,
              label: <Link to={Paths.EQUIPMENT_CATEGORIES}>Kategorie</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.EQUIPMENT_CATEGORY),
            },
          ].filter(item => !item.disabled),
        },
        {
          key: Paths.CLIENTS,
          icon: (
            <FontAwesomeIcon
              icon={faUsers}
              size='xl'
            />
          ),
          label: <Link to={Paths.CLIENTS}>Klienci</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.CLIENT),
        },
        {
          key: Paths.ORDERS,
          icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />,
          label: <Link to={Paths.ORDERS}>Zamówienia</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.ORDER),
        },
        {
          key: Paths.OFFERS,
          icon: <FontAwesomeIcon icon={faFileAlt} />,
          label: <Link to={Paths.OFFERS}>Oferty</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.OFFER),
        },
        {
          key: Paths.USERS,
          icon: <FontAwesomeIcon icon={faUsersGear} />,
          label: <Link to={Paths.USERS}>Użytkownicy</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.USER),
        },
        {
          key: Paths.REPORTS,
          icon: <FontAwesomeIcon icon={faChartSimple} />,
          label: <Link to={Paths.REPORTS}>Raporty</Link>,
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.REPORT),
        },
        {
          key: Paths.SETTINGS,
          icon: <SettingOutlined />,
          label: 'Ustawienia',
          disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.SETTING),
          children: [
            {
              key: Paths.PAINTING,
              icon: <DollarOutlined />,
              label: <Link to={Paths.PAINTING}>Koszty lakierowania</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.SETTING_PAINTING),
            },
            {
              key: Paths.GENERAL_SETTINGS,
              icon: <FontAwesomeIcon icon={faUserCog} />,
              label: <Link to={Paths.GENERAL_SETTINGS}>Ogólne</Link>,
              disabled: abilityCtx.cannot(AbilityAction.READ, AbilitySubject.SETTING_GENERAL),
            },
          ].filter(item => !item.disabled),
        },
      ].filter(item => !item.disabled)}
    />
  );
};
