import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { CategoryModel } from './model';

export type CategoriesAction = ActionType<typeof actions>;
export type CategoriesState = {
  readonly isFetching: boolean;
  readonly categoriesList: CategoryModel[];
};

export const categoriesReducer = combineReducers<CategoriesState, CategoriesAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_CATEGORIES_REQUEST:
      case CONST.ADD_CATEGORY_REQUEST:
      case CONST.UPDATE_CATEGORY_REQUEST:
      case CONST.DELETE_CATEGORY_REQUEST:
        return true;

      case CONST.GET_CATEGORIES_SUCCESS:
      case CONST.GET_CATEGORIES_ERROR:
      case CONST.ADD_CATEGORY_SUCCESS:
      case CONST.ADD_CATEGORY_ERROR:
      case CONST.UPDATE_CATEGORY_SUCCESS:
      case CONST.UPDATE_CATEGORY_ERROR:
      case CONST.DELETE_CATEGORY_SUCCESS:
      case CONST.DELETE_CATEGORY_ERROR:
        return false;

      default:
        return state;
    }
  },
  categoriesList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_CATEGORIES_SUCCESS:
        return action.payload.categories;

      case CONST.ADD_CATEGORY_SUCCESS:
        return [action.payload.category, ...state];

      case CONST.UPDATE_CATEGORY_SUCCESS: {
        const categoryIdx = state.findIndex(item => item._id === action.payload.category._id);
        state.splice(categoryIdx, 1, action.payload.category);

        return [...state];
      }

      case CONST.DELETE_CATEGORY_SUCCESS:
        return state.filter(category => category._id !== action.payload.categoryId);

      default:
        return state;
    }
  },
});
