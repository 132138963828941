import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addCostsError,
  addCostsRequest,
  addCostsSuccess,
  deleteCostsError,
  deleteCostsRequest,
  deleteCostsSuccess,
  getCostsError,
  getCostsSuccess,
  getProductCostRequest,
  getProductCostsError,
  getProductCostsSuccess,
  updateCostsError,
  updateCostsRequest,
  updateCostsSuccess,
} from './actions';

import * as CONST from './consts';
import { CostModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientCosts } from '../../services/apiClient/costs';
import { apiClientForklifts } from '../../services/apiClient/forklifts';
import { getForkliftSuccess } from '../forklift/actions';

function* getCosts(): Generator<CallEffect | PutEffect, void, ResponseModel<CostModel[]>> {
  try {
    const response = yield call(apiClientCosts.getCosts);

    yield put(getCostsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get costs',
      description: getErrorMessage(err),
    });
    yield put(getCostsError(err));
  }
}

function* getProductCosts(
  action: ActionType<typeof getProductCostRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<CostModel[]>> {
  try {
    const { costId } = action.payload;
    const response = yield call(apiClientCosts.getProductCosts, costId);

    yield put(getProductCostsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get costs',
      description: getErrorMessage(err),
    });
    yield put(getProductCostsError(err));
  }
}

function* addCost(
  action: ActionType<typeof addCostsRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<CostModel>> {
  try {
    const { cost, onSuccess } = action.payload;
    const response = yield call(apiClientCosts.addCost, cost);
    const forkliftResponse = yield call(apiClientForklifts.getForklift, cost.productId);

    yield put(addCostsSuccess(response.data));
    // @ts-expect-error
    yield put(getForkliftSuccess(forkliftResponse.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding cost',
      description: getErrorMessage(err),
    });
    yield put(addCostsError(err));
  }
}

function* updateCost(
  action: ActionType<typeof updateCostsRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<CostModel>> {
  try {
    const { cost, onSuccess } = action.payload;
    const response = yield call(apiClientCosts.updateCost, cost);
    const forkliftResponse = yield call(apiClientForklifts.getForklift, cost.productId);

    yield put(updateCostsSuccess(response.data));
    // @ts-expect-error
    yield put(getForkliftSuccess(forkliftResponse.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating cost',
      description: getErrorMessage(err),
    });
    yield put(updateCostsError(err));
  }
}

function* deleteCost(action: ActionType<typeof deleteCostsRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { cost, onSuccess } = action.payload;
    yield call(apiClientCosts.deleteCost, cost._id);
    const forkliftResponse = yield call(apiClientForklifts.getForklift, cost.productId);

    yield put(deleteCostsSuccess(cost._id));
    // @ts-expect-error
    yield put(getForkliftSuccess(forkliftResponse.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting cost',
      description: getErrorMessage(err),
    });
    yield put(deleteCostsError(err));
  }
}

export function* watchCostsSaga(): Generator {
  yield takeLatest(CONST.GET_COSTS_REQUEST, getCosts);
  yield takeLatest(CONST.GET_PRODUCT_COSTS_REQUEST, getProductCosts);
  yield takeLatest(CONST.ADD_COSTS_REQUEST, addCost);
  yield takeLatest(CONST.UPDATE_COSTS_REQUEST, updateCost);
  yield takeLatest(CONST.DELETE_COSTS_REQUEST, deleteCost);
}
