export const GET_CATEGORIES_REQUEST = '@categories/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = '@categories/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = '@categories/GET_CATEGORIES_ERROR';

export const ADD_CATEGORY_REQUEST = '@categories/ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = '@categories/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = '@categories/ADD_CATEGORY_ERROR';

export const UPDATE_CATEGORY_REQUEST = '@categories/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = '@categories/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = '@categories/UPDATE_CATEGORY_ERROR';

export const DELETE_CATEGORY_REQUEST = '@categories/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = '@categories/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = '@categories/DELETE_CATEGORY_ERROR';
