import { useQuery } from 'react-query';
import { apiClientEquipments } from './index';

const queryKeys = {
  getEquipments: `equipments`,
};

export const useGetEquipments = () => {
  return useQuery(
    queryKeys.getEquipments,
    async () => {
      const response = await apiClientEquipments.getEquipments();
      return response.data;
    },
    { initialData: [] },
  );
};
