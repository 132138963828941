import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { AdvancePaymentModel } from './model';

export const getAdvancePaymentsRequest = () => action(CONST.GET_ADVANCE_PAYMENTS_REQUEST);
export const getAdvancePaymentsSuccess = (advancePayments: AdvancePaymentModel[]) =>
  action(CONST.GET_ADVANCE_PAYMENTS_SUCCESS, { advancePayments });
export const getAdvancePaymentsError = (error: unknown) => action(CONST.GET_ADVANCE_PAYMENTS_ERROR, { error });

export const getOrderAdvancePaymentsRequest = (orderId: string) =>
  action(CONST.GET_ORDER_ADVANCE_PAYMENTS_REQUEST, { orderId });
export const getOrderAdvancePaymentsSuccess = (advancePayments: AdvancePaymentModel[]) =>
  action(CONST.GET_ORDER_ADVANCE_PAYMENTS_SUCCESS, { advancePayments });
export const getOrderAdvancePaymentsError = (error: unknown) =>
  action(CONST.GET_ORDER_ADVANCE_PAYMENTS_ERROR, { error });

export const addAdvancePaymentRequest = (
  advancePayment: Omit<AdvancePaymentModel, '_id' | 'createdAt' | 'ownerId' | 'updatedAt'>,
  onSuccess?: () => void,
) => action(CONST.ADD_ADVANCE_PAYMENT_REQUEST, { advancePayment, onSuccess });
export const addAdvancePaymentSuccess = (advancePayment: AdvancePaymentModel) =>
  action(CONST.ADD_ADVANCE_PAYMENT_SUCCESS, { advancePayment });
export const addAdvancePaymentError = (error: unknown) => action(CONST.ADD_ADVANCE_PAYMENT_ERROR, { error });

export const updateAdvancePaymentRequest = (
  advancePayment: Omit<AdvancePaymentModel, 'ownerId'>,
  onSuccess?: () => void,
) => action(CONST.UPDATE_ADVANCE_PAYMENT_REQUEST, { advancePayment, onSuccess });
export const updateAdvancePaymentSuccess = (advancePayment: AdvancePaymentModel) =>
  action(CONST.UPDATE_ADVANCE_PAYMENT_SUCCESS, { advancePayment });
export const updateAdvancePaymentError = (error: unknown) => action(CONST.UPDATE_ADVANCE_PAYMENT_ERROR, { error });

export const deleteAdvancePaymentRequest = (advancePaymentId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_ADVANCE_PAYMENT_REQUEST, { advancePaymentId, onSuccess });
export const deleteAdvancePaymentSuccess = (advancePaymentId: string) =>
  action(CONST.DELETE_ADVANCE_PAYMENT_SUCCESS, { advancePaymentId });
export const deleteAdvancePaymentError = (error: unknown) => action(CONST.DELETE_ADVANCE_PAYMENT_ERROR, { error });

export const getAdvancePaymentRequest = (advancePaymentId: string) =>
  action(CONST.GET_ADVANCE_PAYMENT_REQUEST, { advancePaymentId });
export const getAdvancePaymentSuccess = (advancePayment: AdvancePaymentModel) =>
  action(CONST.GET_ADVANCE_PAYMENT_SUCCESS, { advancePayment });
export const getAdvancePaymentError = (error: unknown) => action(CONST.GET_ADVANCE_PAYMENT_ERROR, { error });
