import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addClientError,
  addClientRequest,
  addClientSuccess,
  deleteClientError,
  deleteClientRequest,
  deleteClientSuccess,
  getCeidgClientError,
  getCeidgClientRequest,
  getCeidgClientSuccess,
  getClientsError,
  getClientsSuccess,
  updateClientError,
  updateClientRequest,
  updateClientSuccess,
} from './actions';
import * as CONST from './consts';
import { ClientModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientClients } from '../../services/apiClient/clients';

function* getClients(): Generator<CallEffect | PutEffect, void, ResponseModel<ClientModel[]>> {
  try {
    const response = yield call(apiClientClients.getClients);

    yield put(getClientsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get clients',
      description: getErrorMessage(err),
    });
    yield put(getClientsError(err));
  }
}

function* addClient(
  action: ActionType<typeof addClientRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ClientModel>> {
  try {
    const { client, onSuccess } = action.payload;
    const response = yield call(apiClientClients.addClient, client);

    yield put(addClientSuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding client',
      description: getErrorMessage(err),
    });
    yield put(addClientError(err));
  }
}

function* updateClient(
  action: ActionType<typeof updateClientRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ClientModel>> {
  try {
    const { client, onSuccess } = action.payload;
    const response = yield call(apiClientClients.updateClient, client);

    yield put(updateClientSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating client',
      description: getErrorMessage(err),
    });
    yield put(updateClientError(err));
  }
}

function* deleteClient(action: ActionType<typeof deleteClientRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { clientId, onSuccess } = action.payload;
    yield call(apiClientClients.deleteClient, clientId);

    yield put(deleteClientSuccess(clientId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting client',
      description: getErrorMessage(err),
    });
    yield put(deleteClientError(err));
  }
}

function* getClientFromCeidg(
  action: ActionType<typeof getCeidgClientRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ClientModel>> {
  try {
    const { clientId, onSuccess } = action.payload;
    const response = yield call(apiClientClients.getClientFromCeidg, clientId);

    yield put(getCeidgClientSuccess(response.data));
    onSuccess(response.data);
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get client from CEIDG',
      description: getErrorMessage(err),
    });
    yield put(getCeidgClientError(err));
  }
}

export function* watchClientsSaga(): Generator {
  yield takeLatest(CONST.GET_CLIENTS_REQUEST, getClients);
  yield takeLatest(CONST.ADD_CLIENT_REQUEST, addClient);
  yield takeLatest(CONST.UPDATE_CLIENT_REQUEST, updateClient);
  yield takeLatest(CONST.DELETE_CLIENT_REQUEST, deleteClient);
  yield takeLatest(CONST.GET_CEIDG_CLIENT_REQUEST, getClientFromCeidg);
}
