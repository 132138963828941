export const GET_OFFERS_REQUEST = '@offers/GET_OFFERS_REQUEST';
export const GET_OFFERS_SUCCESS = '@offers/GET_OFFERS_SUCCESS';
export const GET_OFFERS_ERROR = '@offers/GET_OFFERS_ERROR';

export const GET_OFFER_REQUEST = '@offers/GET_OFFER_REQUEST';
export const GET_OFFER_SUCCESS = '@offers/GET_OFFER_SUCCESS';
export const GET_OFFER_ERROR = '@offers/GET_OFFER_ERROR';

export const ADD_OFFER_REQUEST = '@offers/ADD_OFFER_REQUEST';
export const ADD_OFFER_SUCCESS = '@offers/ADD_OFFER_SUCCESS';
export const ADD_OFFER_ERROR = '@offers/ADD_OFFER_ERROR';

export const UPDATE_OFFER_REQUEST = '@offers/UPDATE_OFFER_REQUEST';
export const UPDATE_OFFER_SUCCESS = '@offers/UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_ERROR = '@offers/UPDATE_OFFER_ERROR';

export const DELETE_OFFER_REQUEST = '@offers/DELETE_OFFER_REQUEST';
export const DELETE_OFFER_SUCCESS = '@offers/DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_ERROR = '@offers/DELETE_OFFER_ERROR';

export const GET_OFFER_PDF_REQUEST = '@offers/GET_OFFER_PDF_REQUEST';
export const GET_OFFER_PDF_SUCCESS = '@offers/GET_OFFER_PDF_SUCCESS';
export const GET_OFFER_PDF_ERROR = '@offers/GET_OFFER_PDF_ERROR';
