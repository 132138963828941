import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootReducer } from '../reducers';
import rootSaga from '../reducers/saga';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer(), composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
