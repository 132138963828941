import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { RootReducerState } from '../../reducers';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { MastModel } from '../../reducers/masts/model';
import { addMastRequest, deleteMastRequest, getMastsRequest, updateMastRequest } from '../../reducers/masts/actions';
import { MastForm, MastFormValues } from '../../forms/mast';

type MastsListProps = {
  openMastModal: boolean;
  clickOpenMastModal: (openMastModal: boolean) => void;
};

export const MastsList: FC<MastsListProps> = props => {
  const { openMastModal, clickOpenMastModal } = props;
  const mastsList = useSelector<RootReducerState, MastModel[]>(state => state.masts.mastsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.masts.isFetching);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();
  const [selectedMast, setSelectedMast] = useState<MastModel | undefined>(undefined);

  useEffect(() => {
    dispatch(getMastsRequest());
  }, [dispatch]);

  const handleEditMastModal = (mastId: string) => {
    const mast = mastsList.find(item => item._id === mastId);
    setSelectedMast(mast);
    clickOpenMastModal(true);
  };

  const handleCloseMastModal = () => {
    setSelectedMast(undefined);
    clickOpenMastModal(false);
  };

  const handleDeleteMastModal = (mastId: string) => {
    dispatch(deleteMastRequest(mastId));
  };

  const handleSubmit = (values: MastFormValues) => {
    if (selectedMast) {
      dispatch(
        updateMastRequest(
          {
            ...selectedMast,
            ...values,
          },
          () => {
            setSelectedMast(undefined);
            clickOpenMastModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addMastRequest(values, () => {
          setSelectedMast(undefined);
          clickOpenMastModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<MastModel> = [
    {
      title: 'Maszt',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ...getColumnSearchProps('name', 'marki'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj maszt'
              onClick={() => handleEditMastModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie masztu'
              description='Czy na pewno chcesz usunąć maszt?'
              onConfirm={() => handleDeleteMastModal(_id)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń maszt'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={mastsList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane masztu'
        titleAdd='Dodaj maszt'
        openModal={openMastModal}
        handleCloseModal={handleCloseMastModal}
        selectedItem={selectedMast}
      >
        <MastForm
          mast={selectedMast}
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseMastModal}
        />
      </DrawerAddEdit>
    </>
  );
};
