import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { BrandsList } from '../../component/brands/BrandsList';
import { BrandsHeader } from './BrandsHeader';

export const BrandsPage = () => {
  const [openBrandModal, setOpenBrandModal] = useState(false);

  return (
    <LayoutMain>
      <BrandsHeader onSetOpenBrandModal={setOpenBrandModal} />
      <BrandsList
        openBrandModal={openBrandModal}
        clickOpenBrandModal={openBrandModal => setOpenBrandModal(openBrandModal)}
      />
    </LayoutMain>
  );
};
