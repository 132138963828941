import { useQuery } from 'react-query';
import { apiClientClients } from './index';

const queryKeys = {
  getClients: `clients`,
};

export const useGetClients = () => {
  return useQuery(
    queryKeys.getClients,
    async () => {
      const response = await apiClientClients.getClients();
      return response.data;
    },
    { initialData: [] },
  );
};
