import { EquipmentStockList } from '../../component/equipment/EquipmentStockList';
import React from 'react';
import { LogoutMenuButton } from '../../component/header/LogoutMenuButton';

export const EquipmentAdditionalMenu = () => {
  return (
    <>
      <EquipmentStockList />
      <LogoutMenuButton />
    </>
  );
};
