import React, { useState, useMemo, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { SetPasswordForm, SetPasswordFormValues } from '../../../forms/setPassword';
import { setPasswordRequest } from '../../../reducers/user/actions';
import { useDispatch } from 'react-redux';

export const SetPasswordPage: FC = () => {
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const location = useLocation();
  const hash = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('hash');
  }, [location.search]);

  const handleSetPasswordSubmit = (values: SetPasswordFormValues) => {
    const setValues = {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      hash: hash ?? '',
    };

    dispatch(
      setPasswordRequest(setValues, () => {
        setShowMessage(true);
      }),
    );
  };

  return (
    <SetPasswordForm
      handleSubmit={handleSetPasswordSubmit}
      showMessage={showMessage}
    />
  );
};
