import { UserModel } from '../user/model';
import { ClientModel } from '../clients/model';

export enum ProductType {
  FORKLIFT = 'forklift',
  EQUIPMENT = 'equipment',
}

export type ProductInOffer = {
  type: ProductType;
  productId: string;
  price: number;
  notes: string;
};

export enum CurrencyType {
  PLN = 'pln',
  EUR = 'eur',
}

export const CurrencyTypeNames = {
  [CurrencyType.PLN]: 'PLN',
  [CurrencyType.EUR]: 'EUR',
};

export type OfferModel = {
  _id: string;
  offerNr: string;
  clientId: string | null;
  productsInOffer: ProductInOffer[];
  price: number;
  currency: CurrencyType;
  ownerId: string;
  expiredAt: string;
  createdAt: string;
  updatedAt: string;
  notes?: string;
  //Virtual fields
  owner?: Partial<UserModel>;
  client?: Partial<ClientModel>;
  products?: {
    _id: string;
    internalId: string;
    brandName?: string;
    productType: string;
    productionYear: number;
    serialNumber: string;
    assetUrl: string | null;
  }[];
};
