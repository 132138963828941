import React, { useEffect, useMemo } from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';

import { Column } from '@ant-design/plots';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../reducers';
import { ReportSellModel } from '../../../reducers/reports/model';
import { getReportsRequest } from '../../../reducers/reports/actions';
import { ColumnConfig } from '@ant-design/plots/es/components/column';

const { Title } = Typography;

export const MonthlyReports: React.FC = () => {
  const reportsList = useSelector<RootReducerState, ReportSellModel[]>(state => state.reports.reportsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.reports.isFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportsRequest());
  }, [dispatch]);

  const data = useMemo(() => {
    return reportsList.map(report => {
      return {
        ...report,
        date: dayjs(report.date).format('MM/YYYY'),
        soldForklifts: report.soldForklifts,
      };
    });
  }, [reportsList]);

  const config: ColumnConfig = {
    data,
    xField: 'date',
    yField: 'soldForklifts',
    label: {
      text: 'soldForklifts',
      textBaseline: 'bottom',
    },
    tooltip: {
      items: [
        {
          name: 'Sprzedane',
          field: 'soldForklifts',
        },
      ],
    },
    offset: 10,
    slider: {
      x: {
        values: [0, 1],
      },
    },
  };
  return (
    <>
      <Title
        level={5}
        style={{ padding: '0 0 16px 16px' }}
      >
        Ilość sprzedanych wózków miesięcznie
      </Title>
      <Column
        {...config}
        loading={isFetching}
      />
    </>
  );
};
