import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import './style.scss';

const { Title, Text } = Typography;

export const LayoutLogin: React.FC = props => (
  <div className='loginPage'>
    <div className='leftLoginPage'>
      <header className='loginHeader'>
        <Link
          title='Forklift Manager'
          to='/'
        >
          <img
            src='/images/logo.png'
            className='logo'
            alt='logo'
          />
        </Link>
      </header>
    </div>
    <div className='rightLoginPage'>
      <div className='rightLoginPageWrapper'>
        <Title>Forklift Manager</Title>
        <Text className='text'>
          Aplikacja do zarządzania przepływem danych
          <br /> sprzedaży pojazdów specjalistycznych
        </Text>
        {props.children}
      </div>
      <footer>
        <p>
          Forklift Manager v1.0
          <br />
          design by MBR
        </p>
      </footer>
    </div>
  </div>
);
