import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import { getReportsError, getReportsSuccess } from './actions';

import * as CONST from './consts';
import { ReportSellModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { apiClientReports } from '../../services/apiClient/reports';

function* getReports(): Generator<CallEffect | PutEffect, void, ResponseModel<ReportSellModel[]>> {
  try {
    const response = yield call(apiClientReports.getReports);

    yield put(getReportsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get reports',
      description: getErrorMessage(err),
    });
    yield put(getReportsError(err));
  }
}

export function* watchReportsSaga(): Generator {
  yield takeLatest(CONST.GET_REPORTS_REQUEST, getReports);
}
