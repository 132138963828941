import { BaseAPI } from '../base';
import { CategoryModel } from '../../../reducers/categories/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientCategories extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/categories`;
  }

  public getCategories = async (): Promise<ResponseModel<CategoryModel[]>> => {
    return this.authFetch<ResponseModel<CategoryModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addCategory = async (
    data: Omit<CategoryModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<CategoryModel>> => {
    return this.authFetch<ResponseModel<CategoryModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateCategory = async (data: Partial<CategoryModel>): Promise<ResponseModel<void>> => {
    return this.authFetch<ResponseModel<void>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteCategory = async (brandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${brandId}`, { method: 'DELETE' });
  };
}

export const apiClientCategories = new APIClientCategories();
