import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { LayoutLogin } from '../../layout/login/Login';
import { Paths } from '../../router/paths';
import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';

import './style.scss';

const { Item } = Form;
const { Text } = Typography;

export type ResetPasswordFormValues = {
  email: string;
};

export type ResetPasswordFormProps = {
  showMessage: boolean;
  handleSubmit: (values: ResetPasswordFormValues) => void;
};

export const ResetPasswordForm: FC<ResetPasswordFormProps> = props => {
  const { showMessage } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.users.isFetching);
  const formik = useFormik(initFormik(props));

  return (
    <LayoutLogin>
      {!showMessage && (
        <Form
          layout='vertical'
          className='loginForm'
        >
          <Text className='loginHeaderText'>Resetowanie hasła</Text>
          <Item
            label='E-mail'
            required={true}
            validateStatus={formik.errors.email && formik.touched.email ? 'error' : ''}
            hasFeedback={true}
            help={formik.errors.email && formik.touched.email ? formik.errors.email : null}
          >
            <Input
              name='email'
              value={formik.values.email}
              placeholder='E-mail'
              prefix={<UserOutlined />}
              onChange={formik.handleChange}
            />
          </Item>
          <Row
            justify='space-between'
            align='middle'
          >
            <Button
              type='primary'
              htmlType='submit'
              onClick={formik.submitForm}
              loading={isFetching}
            >
              Zresetuj hasło
            </Button>
            <Link
              className='link'
              to={Paths.LOGIN_PAGE}
            >
              Powrót do strony logowania
            </Link>
          </Row>
        </Form>
      )}
      {showMessage && (
        <div className='informMessage'>
          Wysłaliśmy Ci e-mail, z linkiem do resetu hasła. Sprawdź pocztę z podanym przez Ciebie adresem e-mail.
        </div>
      )}
    </LayoutLogin>
  );
};
