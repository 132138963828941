import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addAdvancePaymentError,
  addAdvancePaymentRequest,
  addAdvancePaymentSuccess,
  deleteAdvancePaymentError,
  deleteAdvancePaymentRequest,
  deleteAdvancePaymentSuccess,
  getAdvancePaymentError,
  getAdvancePaymentRequest,
  getAdvancePaymentsError,
  getAdvancePaymentsSuccess,
  getAdvancePaymentSuccess,
  getOrderAdvancePaymentsError,
  getOrderAdvancePaymentsRequest,
  getOrderAdvancePaymentsSuccess,
  updateAdvancePaymentError,
  updateAdvancePaymentRequest,
  updateAdvancePaymentSuccess,
} from './actions';

import * as CONST from './consts';
import { AdvancePaymentModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientAdvancePayments } from '../../services/apiClient/advancePayments';

function* getAdvancePayments(): Generator<CallEffect | PutEffect, void, ResponseModel<AdvancePaymentModel[]>> {
  try {
    const response = yield call(apiClientAdvancePayments.getAdvancePayments);

    yield put(getAdvancePaymentsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get advance payments',
      description: getErrorMessage(err),
    });
    yield put(getAdvancePaymentsError(err));
  }
}

function* getOrderAdvancePayments(
  action: ActionType<typeof getOrderAdvancePaymentsRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<AdvancePaymentModel[]>> {
  try {
    const response = yield call(apiClientAdvancePayments.getOrderAdvancePayments, action.payload.orderId);

    yield put(getOrderAdvancePaymentsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get advance payments',
      description: getErrorMessage(err),
    });
    yield put(getOrderAdvancePaymentsError(err));
  }
}

function* addAdvancePayment(
  action: ActionType<typeof addAdvancePaymentRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<AdvancePaymentModel>> {
  try {
    const { advancePayment, onSuccess } = action.payload;
    const response = yield call(apiClientAdvancePayments.addAdvancePayment, advancePayment);

    yield put(addAdvancePaymentSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding advance payment',
      description: getErrorMessage(err),
    });
    yield put(addAdvancePaymentError(err));
  }
}

function* updateAdvancePayment(
  action: ActionType<typeof updateAdvancePaymentRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<AdvancePaymentModel>> {
  try {
    const { advancePayment, onSuccess } = action.payload;
    const response = yield call(apiClientAdvancePayments.updateAdvancePayment, advancePayment);

    yield put(updateAdvancePaymentSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating advance payment',
      description: getErrorMessage(err),
    });
    yield put(updateAdvancePaymentError(err));
  }
}

function* deleteAdvancePayment(
  action: ActionType<typeof deleteAdvancePaymentRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { advancePaymentId, onSuccess } = action.payload;
    yield call(apiClientAdvancePayments.deleteAdvancePayment, advancePaymentId);

    yield put(deleteAdvancePaymentSuccess(advancePaymentId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting advance payment',
      description: getErrorMessage(err),
    });
    yield put(deleteAdvancePaymentError(err));
  }
}

function* getAdvancePayment(
  action: ActionType<typeof getAdvancePaymentRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<AdvancePaymentModel>> {
  try {
    const { advancePaymentId } = action.payload;
    const response = yield call(apiClientAdvancePayments.getAdvancePayment, advancePaymentId);

    yield put(getAdvancePaymentSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get advance payment',
      description: getErrorMessage(err),
    });
    yield put(getAdvancePaymentError(err));
  }
}

export function* watchAdvancePaymentsSaga(): Generator {
  yield takeLatest(CONST.GET_ADVANCE_PAYMENTS_REQUEST, getAdvancePayments);
  yield takeLatest(CONST.GET_ORDER_ADVANCE_PAYMENTS_REQUEST, getOrderAdvancePayments);
  yield takeLatest(CONST.ADD_ADVANCE_PAYMENT_REQUEST, addAdvancePayment);
  yield takeLatest(CONST.UPDATE_ADVANCE_PAYMENT_REQUEST, updateAdvancePayment);
  yield takeLatest(CONST.DELETE_ADVANCE_PAYMENT_REQUEST, deleteAdvancePayment);
  yield takeLatest(CONST.GET_ADVANCE_PAYMENT_REQUEST, getAdvancePayment);
}
