import React, { FC, useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import {
  addCategoryRequest,
  deleteCategoryRequest,
  getCategoriesRequest,
  updateCategoryRequest,
} from '../../reducers/categories/actions';
import { CategoryModel } from '../../reducers/categories/model';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { CategoryForm, CategoryFormValues } from '../../forms/category';
import { ColumnsType } from 'antd/es/table';

type CategoriesListProps = {
  openCategoryModal: boolean;
  clickOpenCategoryModal: (openCategoryModal) => void;
};

export const CategoriesList: FC<CategoriesListProps> = props => {
  const { openCategoryModal, clickOpenCategoryModal } = props;
  const categoriesList = useSelector<RootReducerState, CategoryModel[]>(state => state.categories.categoriesList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.categories.isFetching);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();
  const [selectedCategory, setSelectedCategory] = useState<CategoryModel | undefined>(undefined);
  const [, setDeleteCategoryModal] = useState(false);

  useEffect(() => {
    dispatch(getCategoriesRequest());
  }, [dispatch]);

  const handleEditCategoryModal = (categoryId: string) => {
    const category = categoriesList.find(item => item._id === categoryId);
    setSelectedCategory(category);
    clickOpenCategoryModal(true);
  };

  const handleCloseCategoryModal = () => {
    setSelectedCategory(undefined);
    clickOpenCategoryModal(false);
  };

  const handleDeleteCategoryModal = (categoryId: string) => {
    dispatch(
      deleteCategoryRequest(categoryId, () => {
        setDeleteCategoryModal(false);
      }),
    );
  };

  const handleSubmit = (values: CategoryFormValues) => {
    if (selectedCategory) {
      dispatch(
        updateCategoryRequest(
          {
            ...selectedCategory,
            ...values,
          },
          () => {
            setSelectedCategory(undefined);
            clickOpenCategoryModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addCategoryRequest(values, () => {
          setSelectedCategory(undefined);
          clickOpenCategoryModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<CategoryModel> = [
    {
      title: 'Kategoria',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ...getColumnSearchProps('name', 'kategorii'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj kategorię'
              onClick={() => handleEditCategoryModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie kategorii'
              description='Czy na pewno chcesz usunąć kategorię?'
              onConfirm={() => handleDeleteCategoryModal(_id)}
              onCancel={() => setDeleteCategoryModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń kategorię'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={categoriesList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane kategorii'
        titleAdd='Dodaj kategorię'
        openModal={openCategoryModal}
        handleCloseModal={handleCloseCategoryModal}
        selectedItem={selectedCategory}
      >
        <CategoryForm
          category={selectedCategory}
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseCategoryModal}
        />
      </DrawerAddEdit>
    </>
  );
};
