import React, { FC, useState } from 'react';
import { Button, Col, Dropdown, type MenuProps, Select, Space, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { SummaryForklifts } from '../../component/summeryForklifts/SummaryForklifts';
import { ForkliftsStockList } from '../../component/forklift/ForkliftsStockList';
import { useViewport } from '../../utils/customHooks/useViewport';
import { ForkliftTable, ForkliftTableNames, LocalStorageKeys } from '../../reducers/forklift/model';
import { LogoutMenuButton } from '../../component/header/LogoutMenuButton';

const { Text } = Typography;

type ForkliftsAdditionalMenuProps = {
  tableSettings: string[];
  setTableSettings: (settings: string[]) => void;
};

export const ForkliftsAdditionalMenu: FC<ForkliftsAdditionalMenuProps> = props => {
  const { tableSettings, setTableSettings } = props;
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const { isMobile } = useViewport();

  const forkliftTableSettings: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Space direction='vertical'>
          <Text strong>Ukryj kolumny</Text>
          <Select
            style={{ width: '300px', textTransform: 'lowercase' }}
            mode='multiple'
            showSearch
            optionFilterProp='children'
            value={tableSettings}
            onChange={(newValue: string[]) => onChangeTableSettings(newValue)}
            options={Object.values(ForkliftTable).map(option => ({
              label: ForkliftTableNames[option].toLowerCase(),
              value: option,
            }))}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Space>
      ),
    },
  ];

  const onChangeTableSettings = (newValue: string[]) => {
    setTableSettings(newValue);
    window.localStorage.setItem(LocalStorageKeys.FORKLIFT_TABLE_SETTINGS, JSON.stringify(newValue));
  };

  return (
    <>
      {!isMobile && (
        <Col>
          <Dropdown
            arrow
            menu={{ items: forkliftTableSettings }}
            open={showSettings}
          >
            <Button
              className='button'
              onClick={() => setShowSettings(!showSettings)}
              icon={<FontAwesomeIcon icon={faGear} />}
            >
              Widok
            </Button>
          </Dropdown>
        </Col>
      )}
      <SummaryForklifts />
      <ForkliftsStockList />
      <LogoutMenuButton />
    </>
  );
};
