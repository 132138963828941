export const getErrorMessage = (err: any) => {
  if (!err) {
    return '';
  }

  if (err.message) {
    return err.message;
  }

  return String(err);
};
