import React, { FC } from 'react';
import { Tabs, TabsProps, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSackDollar, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { OrderStatus, OrderStatusNames } from '../../reducers/orders/model';

import './style.scss';

type Props = {
  ordersPaidAmount: number;
  ordersNotPaidAmount: number;
  ordersDoneAmount: number;
  onChange: (status: OrderStatus) => void;
};
export const OrdersTabs: FC<Props> = ({ ordersPaidAmount, ordersNotPaidAmount, ordersDoneAmount, onChange }) => {
  const itemsTab: TabsProps['items'] = [
    {
      key: OrderStatus.NOT_PAID,
      label: (
        <>
          <FontAwesomeIcon
            icon={faTimes}
            size='sm'
            className='icon'
          />
          {OrderStatusNames[OrderStatus.NOT_PAID]} <Tag bordered={false}>{ordersNotPaidAmount}</Tag>
        </>
      ),
    },
    {
      key: OrderStatus.PAID,
      label: (
        <>
          <FontAwesomeIcon
            icon={faSackDollar}
            size='sm'
            className='icon'
          />
          {OrderStatusNames[OrderStatus.PAID]} <Tag bordered={false}>{ordersPaidAmount}</Tag>
        </>
      ),
    },
    {
      key: OrderStatus.DONE,
      label: (
        <>
          <FontAwesomeIcon
            icon={faCalendarCheck}
            size='sm'
            className='icon'
          />
          {OrderStatusNames[OrderStatus.DONE]} <Tag bordered={false}>{ordersDoneAmount}</Tag>
        </>
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={OrderStatus.NOT_PAID}
      items={itemsTab}
      onChange={key => {
        onChange(key as OrderStatus);
      }}
      className='tab'
    />
  );
};
