import styled from 'styled-components';
import { List } from 'antd';
import { breakpoints } from '../../../styles/breakpoints';

export const PreviewListSC = styled(List)`
  &.previewList li.quickViewListElement {
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;

    &:first-child {
      padding-top: 0;
    }

    .listItemMeta h4 {
      margin-bottom: 0;
      margin-right: 5px;
    }
  }
  &.previewList.firstList {
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    margin-bottom: 8px;
    @media screen and (min-width: ${breakpoints.md}) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  li.quickViewListElement {
    .ant-list-item-meta-content {
      display: flex;
    }
  }
`;
