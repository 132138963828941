import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { CategoryModel } from './model';

export const getCategoriesRequest = () => action(CONST.GET_CATEGORIES_REQUEST);
export const getCategoriesSuccess = (categories: CategoryModel[]) =>
  action(CONST.GET_CATEGORIES_SUCCESS, { categories });
export const getCategoriesError = (error: unknown) => action(CONST.GET_CATEGORIES_ERROR, { error });

export const addCategoryRequest = (
  category: Omit<CategoryModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: (category?: CategoryModel) => void,
) => action(CONST.ADD_CATEGORY_REQUEST, { category, onSuccess });
export const addCategorySuccess = (category: CategoryModel) => action(CONST.ADD_CATEGORY_SUCCESS, { category });
export const addCategoryError = (error: unknown) => action(CONST.ADD_CATEGORY_ERROR, { error });

export const updateCategoryRequest = (category: CategoryModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_CATEGORY_REQUEST, { category, onSuccess });
export const updateCategorySuccess = (category: CategoryModel) => action(CONST.UPDATE_CATEGORY_SUCCESS, { category });
export const updateCategoryError = (error: unknown) => action(CONST.UPDATE_CATEGORY_ERROR, { error });

export const deleteCategoryRequest = (categoryId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_CATEGORY_REQUEST, { categoryId, onSuccess });
export const deleteCategorySuccess = (categoryId: string) => action(CONST.DELETE_CATEGORY_SUCCESS, { categoryId });
export const deleteCategoryError = (error: unknown) => action(CONST.DELETE_CATEGORY_ERROR, { error });
