import React, { FC, useState } from 'react';
import { OrderModel } from '../../../reducers/orders/model';
import { Button, Dropdown, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { itemsStatuses } from '../../../utils/itemsStatus';
import { OrdersEquipmentsList } from './equipments/OrdersEquipmentsList';
import { OrdersForkliftsList } from './forklifts/OrdersForkliftsList';

type OrdersProductsListProps = {
  order: OrderModel | undefined;
};

export const OrdersProductsList: FC<OrdersProductsListProps> = props => {
  const { order } = props;
  const [groupEditForkliftsStatus, setGroupEditForkliftsStatus] = useState<string[]>([]);
  const [groupEditEquipmentsStatus, setGroupEditEquipmentsStatus] = useState<string[]>([]);
  const dispatch = useDispatch();

  const productInOrder = order?.productsInOrder.map(productId => productId.productId);
  const isEditDropdownEnabled = groupEditForkliftsStatus.length > 0 || groupEditEquipmentsStatus.length > 0;

  return (
    <>
      <Row justify='end'>
        <Dropdown
          menu={{ items: itemsStatuses(dispatch, groupEditForkliftsStatus, groupEditEquipmentsStatus) }}
          arrow
          disabled={!isEditDropdownEnabled}
        >
          <Button
            className='marginLeftButton'
            disabled={!isEditDropdownEnabled}
          >
            Edytuj
          </Button>
        </Dropdown>
      </Row>
      {productInOrder && (
        <>
          <OrdersForkliftsList
            productInOrder={productInOrder}
            groupEditForkliftsStatus={groupEditForkliftsStatus}
            onSelection={newSelectedRowKeys => setGroupEditForkliftsStatus(newSelectedRowKeys as string[])}
          />
          <OrdersEquipmentsList
            productInOrder={productInOrder}
            groupEditEquipmentsStatus={groupEditEquipmentsStatus}
            onSelection={newSelectedRowKeys => setGroupEditEquipmentsStatus(newSelectedRowKeys as string[])}
          />
        </>
      )}
    </>
  );
};
