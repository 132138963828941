import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { PaintingAdditionalMenu } from './PaintingAdditionalMenu';

type PaintingHeaderProps = {
  onSetOpenPaintingModal: (openPaintingModal: boolean) => void;
};

export const PaintingHeader: FC<PaintingHeaderProps> = props => {
  const { onSetOpenPaintingModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Koszty lakierni'
      onAddElementOpenModal={onSetOpenPaintingModal}
    >
      <PaintingAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Koszty lakierni'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenPaintingModal(true)}
          >
            Dodaj koszt
          </Button>
        </Col>
        <PaintingAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
