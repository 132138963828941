import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { RootReducerState } from '../../reducers';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { AlignType } from 'rc-table/lib/interface';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { OrderModel, OrderStatus, OrderStatusNames } from '../../reducers/orders/model';
import { addOrderRequest, deleteOrderRequest, updateOrderRequest } from '../../reducers/orders/actions';
import { getStatusOrdersIcon } from '../../utils/statusOrdersIcon';
import { OrderForm, OrderFormValues } from '../../forms/orders';
import { getStatusOrderClass } from '../../utils/statusOrderClass';
import { OrdersProductsList } from './productsList/OrdersProductsList';
import { UserModel, UserRole } from '../../reducers/user/model';
import { getUsersRequest } from '../../reducers/user/actions';
import { OrderPreview } from './OrderPreview';
import { ForkliftModel } from '../../reducers/forklift/model';
import { formatPrice } from '../../utils/formatPrice';
import { useViewport } from '../../utils/customHooks/useViewport';
import { TaxRates } from '../../utils/taxRates';

const { Text } = Typography;

type OrdersListProps = {
  ordersList: OrderModel[];
  openOrderModal: boolean;
  clickOpenOrderModal: (openOrderModal: boolean) => void;
};

type OrderListData = OrderModel & {
  companyName: string;
  sellerName: string;
  productsInOrderResult: number;
};

export const OrdersList: FC<OrdersListProps> = props => {
  const { ordersList, openOrderModal, clickOpenOrderModal } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.orders.isFetching);
  const sellersUserList = useSelector<RootReducerState, UserModel[]>(state =>
    state.users.usersList.filter(
      user => user.role === UserRole.ADMIN || user.role === UserRole.SELLER || user.role === UserRole.SALES_DIRECTOR,
    ),
  );
  const dispatch = useDispatch();
  const { height } = useViewport();
  const { getColumnSearchProps, getColumnFilterProps, getColumnSearchRangeProps } = useFilterAntTable();
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | undefined>(undefined);
  const [, setDeleteOrderModal] = useState(false);
  const [openProductListModal, setOpenProductListModal] = useState(false);
  const [selectedOrderForklifts, setSelectedOrderForklifts] = useState<OrderModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);

  useEffect(() => {
    dispatch(getUsersRequest());
  }, [dispatch]);

  const ordersListData: OrderListData[] = useMemo(() => {
    return ordersList.map(order => ({
      ...order,
      companyName: order.client?.companyName ?? '',
      sellerName: `${order.owner?.name ?? ''} ${order.owner?.surname ?? ''}`,
      productsInOrderResult: order.productsInOrder.length,
    }));
  }, [ordersList]);

  const handleEditOrderModal = (orderId: string) => {
    const order = ordersList.find(item => item._id === orderId);
    setSelectedOrder(order);
    clickOpenOrderModal(true);
  };

  const handleDeleteOrderModal = (orderId: string) => {
    dispatch(
      deleteOrderRequest(orderId, () => {
        setDeleteOrderModal(false);
      }),
    );
  };

  const handleCloseOrderModal = () => {
    setSelectedOrder(undefined);
    clickOpenOrderModal(false);
  };

  const handleSubmit = (values: OrderFormValues) => {
    if (selectedOrder) {
      dispatch(
        updateOrderRequest(
          {
            ...selectedOrder,
            ...values,
          },
          () => {
            setSelectedOrder(undefined);
            clickOpenOrderModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addOrderRequest(
          {
            ...values,
          },
          () => {
            setSelectedOrder(undefined);
            clickOpenOrderModal(false);
          },
        ),
      );
    }
  };

  const handleOpenForkliftListModal = (order: OrderModel) => {
    setOpenProductListModal(true);
    setSelectedOrderForklifts(order);
  };

  const handleQuickViewOrderModal = (orderId: string) => {
    const order = ordersList.find(item => item._id === orderId);
    setSelectedOrder(order);
    setOpenQuickViewModal(true);
  };

  const handleCloseQuickViewModal = () => {
    setSelectedOrder(undefined);
    setOpenQuickViewModal(false);
  };

  const columns: ColumnsType<OrderListData> = [
    {
      title: 'Nr zamówienia',
      dataIndex: 'orderNr',
      key: 'orderNr',
      width: 150,
      ...getColumnSearchProps('orderNr', 'zamówienie'),
      sorter: (a, b) => a.orderNr.localeCompare(b.orderNr),
    },
    {
      title: 'Klient',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 150,
      ...getColumnSearchProps('companyName', 'klienta'),
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: 'Sprzedawca',
      dataIndex: 'ownerId',
      key: 'ownerId',
      width: 150,
      ...getColumnFilterProps(
        'ownerId',
        sellersUserList.map(user => ({
          text: `${user.name} ${user.surname}`,
          value: user._id,
        })),
      ),
      render: (_ownerId: string, record: any) => <>{record.sellerName}</>,
      sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
    },
    {
      title: 'Utworzono',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Płatne do',
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: paidAt => paidAt && dayjs(paidAt).format('DD-MM-YYYY'),
      sorter: (a, b) => new Date(a.paidAt ?? '').getTime() - new Date(b.paidAt ?? '').getTime(),
    },
    {
      title: 'Nr faktury',
      dataIndex: 'invoice',
      key: 'invoice',
      align: 'center' as AlignType,
      ...getColumnSearchProps('invoice', 'nr faktury'),
    },
    {
      title: 'Produkty',
      dataIndex: 'productsInOrderResult',
      key: 'productsInOrderResult',
      align: 'center' as AlignType,
      ...getColumnSearchProps('productsInOrder', 'ID wózka', (value, record) =>
        record.products.some((product: ForkliftModel) => product.internalId.includes(value)),
      ),
      sorter: (a, b) => a.productsInOrderResult - b.productsInOrderResult,
      render: (productsInOrderResult, order: OrderModel) => (
        <Button
          type='link'
          onClick={() => handleOpenForkliftListModal(order)}
        >
          {productsInOrderResult}
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'status',
        Object.values(OrderStatus).map(status => ({
          value: status,
          text: OrderStatusNames[status],
        })),
      ),
      render: status => getStatusOrdersIcon(status),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Wartość',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      align: 'right' as AlignType,
      ...getColumnSearchRangeProps('price', 'Wartość'),
      render: (price: number, order: OrderModel) => (
        <>
          {formatPrice(price, order.currency)}
          {order.client?.companyCountry === 'PL' && (
            <Text
              className='grossPrice'
              type='secondary'
            >
              {formatPrice(price * TaxRates.TAX_23 + price, order.currency)}
            </Text>
          )}
        </>
      ),
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      width: 120,
      render: (_id: string, order: OrderModel) => (
        <>
          <Tooltip
            title='Podgląd'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Zobacz podgląd'
              onClick={() => handleQuickViewOrderModal(_id)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj zamówienie'
              onClick={() => handleEditOrderModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie zamówienia'
              description='Czy na pewno chcesz usunąć zamówienie?'
              onConfirm={() => handleDeleteOrderModal(order._id)}
              onCancel={() => setDeleteOrderModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń zamówienie'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowClassName={record => getStatusOrderClass(record.status, record.paidAt)}
        columns={columns}
        dataSource={ordersListData}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        sticky
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        scroll={{ y: height - 222 }}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane zamówienia'
        titleAdd='Dodaj zamówienie'
        openModal={openOrderModal}
        handleCloseModal={handleCloseOrderModal}
        selectedItem={selectedOrder}
        width='80%'
      >
        <OrderForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseOrderModal}
          order={selectedOrder}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Produkty'
        titleAdd='Produkty'
        openModal={openProductListModal}
        handleCloseModal={() => setOpenProductListModal(false)}
        width='80%'
      >
        <OrdersProductsList order={selectedOrderForklifts} />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit={`Podgląd zamówienia: ${selectedOrder?.orderNr ?? ''}`}
        titleAdd={`Podgląd zamówienia: ${selectedOrder?.orderNr ?? ''}`}
        openModal={openQuickViewModal}
        handleCloseModal={handleCloseQuickViewModal}
        selectedItem={selectedOrder}
        width='60%'
      >
        <OrderPreview order={selectedOrder} />
      </DrawerAddEdit>
    </>
  );
};
