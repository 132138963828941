import { BaseAPI } from '../base';
import { EquipmentCategoryModel } from '../../../reducers/equipmentCategories/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientEquipmentCategories extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/equipment-categories`;
  }

  public getEquipmentCategories = async (): Promise<ResponseModel<EquipmentCategoryModel[]>> => {
    return this.authFetch<ResponseModel<EquipmentCategoryModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addEquipmentCategory = async (
    data: Omit<EquipmentCategoryModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<EquipmentCategoryModel>> => {
    return this.authFetch<ResponseModel<EquipmentCategoryModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateEquipmentCategory = async (
    data: Partial<EquipmentCategoryModel>,
  ): Promise<ResponseModel<EquipmentCategoryModel>> => {
    return this.authFetch<ResponseModel<EquipmentCategoryModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteEquipmentCategory = async (brandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${brandId}`, { method: 'DELETE' });
  };
}

export const apiClientEquipmentCategories = new APIClientEquipmentCategories();
