import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { OfferFormProps, OfferFormValues } from '.';
import { CurrencyType, ProductType } from '../../reducers/offers/model';
import dayjs from 'dayjs';

export const initFormik = (props: OfferFormProps): FormikConfig<OfferFormValues> => {
  const expiredAt = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000);
  return {
    enableReinitialize: false,
    initialValues: {
      offerNr: props.offer?.offerNr ?? '',
      clientId: props.offer?.clientId ?? '',
      productsInOffer:
        props.offer?.productsInOffer?.map(product => ({
          ...product,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          type: product.type ?? ProductType.FORKLIFT,
        })) ?? [],
      notes: props.offer?.notes ?? '',
      currency: props.offer?.currency ?? CurrencyType.PLN,
      // @ts-expect-error
      expiredAt: props.offer?.expiredAt ?? dayjs(expiredAt.toISOString()),
    },
    validationSchema: () =>
      yup.object({
        productsInOffer: yup
          .array()
          .min(1, 'Co najmniej 1 produkt jest wymagany')
          .of(
            yup
              .object({
                productId: yup.string().required('Pole jest wymagane'),
                price: yup.number().required('Pole jest wymagane'),
              })
              .required('Pole jest wymagane'),
          ),
        currency: yup.string().required('Pole jest wymagane'),
      }),
    onSubmit: values => {
      props.handleSubmit(values);
    },
  };
};
