import { BaseAPI } from '../base';
import { OrderModel } from '../../../reducers/orders/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientOrders extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/orders`;
  }

  public getOrders = async (): Promise<ResponseModel<OrderModel[]>> => {
    return this.authFetch<ResponseModel<OrderModel[]>>(this.apiURL, { method: 'GET' });
  };

  public getOrder = async (id: string): Promise<ResponseModel<OrderModel | null>> => {
    return this.authFetch<ResponseModel<OrderModel | null>>(`${this.apiURL}/${id}`, { method: 'GET' });
  };

  public addOrder = async (data: Partial<OrderModel>): Promise<ResponseModel<OrderModel>> => {
    delete data.orderNr;
    return this.authFetch<ResponseModel<OrderModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateOrder = async (data: Partial<OrderModel>): Promise<ResponseModel<OrderModel>> => {
    delete data.price;
    delete data.ownerId;
    delete data.owner;
    delete data.client;
    return this.authFetch<ResponseModel<OrderModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteOrder = async (orderId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${orderId}`, { method: 'DELETE' });
  };

  public getOrderPDF = async (orderId: string): Promise<ArrayBuffer> => {
    return this.authFetchFileAsArrayBuffer(`${this.apiURL}/${orderId}/pdf`, { method: 'GET' });
  };
}

export const apiClientOrders = new APIClientOrders();
