import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { MastsList } from '../../component/masts/MastsList';
import { MastsHeader } from './MastsHeader';

export const MastsPage = () => {
  const [openMastModal, setOpenMastModal] = useState(false);

  return (
    <LayoutMain>
      <MastsHeader onSetOpenMastModal={setOpenMastModal} />
      <MastsList
        openMastModal={openMastModal}
        clickOpenMastModal={openMastModal => setOpenMastModal(openMastModal)}
      />
    </LayoutMain>
  );
};
