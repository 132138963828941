import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { UserModel } from './model';

export type UsersAction = ActionType<typeof actions>;
export type UsersState = {
  readonly isFetching: boolean;
  readonly loggedIn: boolean;
  readonly userLoggedIn: UserModel | null;
  readonly usersList: UserModel[];
};

export const usersReducer = combineReducers<UsersState, UsersAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.LOGIN_REQUEST:
      case CONST.GET_USERS_REQUEST:
      case CONST.ADD_USER_REQUEST:
      case CONST.UPDATE_USER_REQUEST:
      case CONST.DELETE_USER_REQUEST:
      case CONST.RESET_PASSWORD_USER_REQUEST:
      case CONST.SET_PASSWORD_USER_REQUEST:
      case CONST.LOGIN_AS_USER_REQUEST:
      case CONST.GET_ME_REQUEST:
        return true;

      case CONST.LOGIN_SUCCESS:
      case CONST.LOGIN_ERROR:
      case CONST.GET_USERS_SUCCESS:
      case CONST.GET_USERS_ERROR:
      case CONST.ADD_USER_SUCCESS:
      case CONST.ADD_USER_ERROR:
      case CONST.UPDATE_USER_SUCCESS:
      case CONST.UPDATE_USER_ERROR:
      case CONST.DELETE_USER_SUCCESS:
      case CONST.DELETE_USER_ERROR:
      case CONST.RESET_PASSWORD_USER_SUCCESS:
      case CONST.RESET_PASSWORD_USER_ERROR:
      case CONST.SET_PASSWORD_USER_SUCCESS:
      case CONST.SET_PASSWORD_USER_ERROR:
      case CONST.LOGIN_AS_USER_SUCCESS:
      case CONST.LOGIN_AS_USER_ERROR:
      case CONST.GET_ME_SUCCESS:
      case CONST.GET_ME_ERROR:
        return false;

      default:
        return state;
    }
  },
  loggedIn: (state = false, action) => {
    switch (action.type) {
      case CONST.LOGIN_SUCCESS:
      case CONST.LOGIN_AS_USER_SUCCESS:
        return true;

      case CONST.LOGOUT_USER:
        return false;

      default:
        return state;
    }
  },
  userLoggedIn: (state = null, action) => {
    switch (action.type) {
      case CONST.LOGOUT_USER:
        return null;

      case CONST.GET_ME_SUCCESS:
        return action.payload.user;

      default:
        return state;
    }
  },
  usersList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_USERS_SUCCESS:
        return action.payload.users;

      case CONST.ADD_USER_SUCCESS:
        return [...state, action.payload.user];

      case CONST.UPDATE_USER_SUCCESS: {
        const userIdx = state.findIndex(item => item._id === action.payload.user._id);
        state.splice(userIdx, 1, action.payload.user);

        return [...state];
      }

      case CONST.DELETE_USER_SUCCESS:
        return state.filter(user => user._id !== action.payload.userId);

      default:
        return state;
    }
  },
});
