import { ForkliftModel } from '../../reducers/forklift/model';

export type ForkliftDataModel = ForkliftModel & {
  brandName?: string;
  mastName?: string;
  orderNr: string;
  priceInitWithCosts: number;
};

export const getData = (forklifts: ForkliftModel[]): ForkliftDataModel[] => {
  return forklifts.map(forklift => ({
    ...forklift,
    brandName: forklift.brand?.name,
    mastName: forklift.mast?.name,
    orderNr: forklift.order?.orderNr ?? '',
    priceInitWithCosts: (forklift.priceInit ? +forklift.priceInit : 0) + (forklift.costsAmount ?? 0),
  }));
};
