import React, { FC, useState } from 'react';
import { Button, Col, Drawer, Row, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { HeaderSC } from './HeaderSC';

const { Title } = Typography;

type HeaderComponentMobileProps = {
  title: string;
  onAddElementOpenModal?: (addElementOpenModal: boolean) => void;
};

export const HeaderComponentMobile: FC<HeaderComponentMobileProps> = props => {
  const { title, children, onAddElementOpenModal } = props;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  return (
    <HeaderSC className='header'>
      <Row justify='space-between'>
        <Col lg={8}>
          <Title level={2}>{title}</Title>
        </Col>
        <Col lg={16}>
          <Row
            justify='end'
            gutter={16}
          >
            {onAddElementOpenModal && (
              <Col>
                <Button
                  type='primary'
                  onClick={() => onAddElementOpenModal(true)}
                >
                  +
                </Button>
              </Col>
            )}
            <Col>
              <Button
                onClick={() => setIsMobileMenuOpen(true)}
                icon={<FontAwesomeIcon icon={faBars} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Drawer
        open={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
      >
        {children}
      </Drawer>
    </HeaderSC>
  );
};
