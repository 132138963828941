import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { AlignType, FixedType } from 'rc-table/lib/interface';

import { RootReducerState } from '../../reducers';
import { OfferModel } from '../../reducers/offers/model';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import {
  addOfferRequest,
  deleteOfferRequest,
  getOfferPDFRequest,
  getOffersRequest,
  updateOfferRequest,
} from '../../reducers/offers/actions';
import { UserModel, UserRole } from '../../reducers/user/model';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { OfferForm, OfferFormValues } from '../../forms/offers';
import { OfferPreview } from './OfferPreview';

type OffersListProps = {
  openOfferModal: boolean;
  clickOpenOfferModal: (openOfferModal: boolean) => void;
};

type OffersListDateProps = OfferModel & {
  companyName: string;
  sellerName: string;
};

export const OffersList: FC<OffersListProps> = props => {
  const { openOfferModal, clickOpenOfferModal } = props;
  const offersList = useSelector<RootReducerState, OfferModel[]>(state => state.offers.offersList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.offers.isFetching);
  const sellersUserList = useSelector<RootReducerState, UserModel[]>(state =>
    state.users.usersList.filter(
      user => user.role === UserRole.ADMIN || user.role === UserRole.SELLER || user.role === UserRole.SALES_DIRECTOR,
    ),
  );
  const dispatch = useDispatch();
  const { getColumnSearchProps, getColumnFilterProps } = useFilterAntTable();
  const [selectedOffer, setSelectedOffer] = useState<OfferModel | undefined>(undefined);
  const [, setDeleteOfferModal] = useState(false);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);

  useEffect(() => {
    dispatch(getOffersRequest());
  }, [dispatch]);

  const offersListDate: OffersListDateProps[] = useMemo(() => {
    return offersList.map(offer => {
      return {
        ...offer,
        companyName: offer.client?.companyName ?? '',
        sellerName: `${offer.owner?.name ?? ''} ${offer.owner?.surname ?? ''}`,
      };
    });
  }, [offersList]);

  const handleEditOfferModal = (offerId: string) => {
    const offer = offersList.find(item => item._id === offerId);
    setSelectedOffer(offer);
    clickOpenOfferModal(true);
  };

  const handleDeleteOfferModal = (offerId: string) => {
    dispatch(
      deleteOfferRequest(offerId, () => {
        setDeleteOfferModal(false);
      }),
    );
  };

  const handleCloseOfferModal = () => {
    setSelectedOffer(undefined);
    clickOpenOfferModal(false);
  };

  const handleSubmitOffer = (values: OfferFormValues) => {
    const offerValue = {
      clientId: values.clientId ? values.clientId : null,
      currency: values.currency,
      expiredAt: values.expiredAt,
      notes: values.notes,
      offerNr: values.offerNr,
      productsInOffer: values.productsInOffer,
    };
    if (selectedOffer) {
      dispatch(
        updateOfferRequest(
          {
            ...selectedOffer,
            ...offerValue,
          },
          () => {
            dispatch(getOfferPDFRequest(selectedOffer._id));
            clickOpenOfferModal(false);
            setSelectedOffer(undefined);
          },
        ),
      );
    } else {
      dispatch(
        addOfferRequest({ ...offerValue }, offerId => {
          dispatch(getOfferPDFRequest(offerId));
          clickOpenOfferModal(false);
        }),
      );
    }
  };

  const handleQuickViewOfferModal = (offerId: string) => {
    const selectedOffer = offersList.find(offer => offer._id === offerId);
    setSelectedOffer(selectedOffer);
    setOpenQuickViewModal(true);
  };

  const handleCloseQuickViewModal = () => {
    setSelectedOffer(undefined);
    setOpenQuickViewModal(false);
  };

  const columns: ColumnsType<OffersListDateProps> = [
    {
      title: 'Nr oferty',
      dataIndex: 'offerNr',
      key: 'offerNr',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('offerNr', 'oferty'),
      sorter: (a, b) => a.offerNr.localeCompare(b.offerNr),
      hidden: false,
    },
    {
      title: 'Nazwa firmy',
      dataIndex: 'companyName',
      key: 'companyName',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('companyName', 'nazwy firmy'),
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      hidden: false,
    },
    {
      title: 'Sprzedawca',
      dataIndex: 'sellerName',
      key: 'sellerName',
      fixed: 'left' as FixedType,
      ...getColumnFilterProps(
        'ownerId',
        sellersUserList.map(user => ({
          text: `${user.name} ${user.surname}`,
          value: user._id,
        })),
      ),
      sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
      hidden: false,
    },
    {
      title: 'Oferta ważna do',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: expiredAt => expiredAt && dayjs(expiredAt).format('DD-MM-YYYY'),
      sorter: (a, b) => new Date(a.expiredAt || '').getTime() - new Date(b.expiredAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      width: 120,
      render: (_id: string, offer: OfferModel) => (
        <>
          <Tooltip
            title='Podgląd'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Zobacz podgląd oferty'
              onClick={() => handleQuickViewOfferModal(_id)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj ofertę'
              onClick={() => handleEditOfferModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie oferty'
              description='Czy na pewno chcesz usunąć ofertę?'
              onConfirm={() => handleDeleteOfferModal(offer._id)}
              onCancel={() => setDeleteOfferModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń ofertę'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={offersListDate}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        sticky
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane oferty'
        titleAdd='Dodaj ofertę'
        openModal={openOfferModal}
        handleCloseModal={handleCloseOfferModal}
        selectedItem={selectedOffer}
        width='80%'
      >
        <OfferForm
          handleSubmit={handleSubmitOffer}
          handleCancelForm={handleCloseOfferModal}
          offer={selectedOffer}
        />
      </DrawerAddEdit>
      {selectedOffer && (
        <DrawerAddEdit
          titleEdit={`Podgląd oferty: ${selectedOffer.offerNr}`}
          titleAdd={`Podgląd oferty: ${selectedOffer.offerNr}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedOffer}
          width='60%'
        >
          <OfferPreview offerId={selectedOffer._id} />
        </DrawerAddEdit>
      )}
    </>
  );
};
