import * as countriesISO2toISO3 from './countriesISO2toISO3.json';
import { countries, getCountryData, getEmojiFlag } from 'countries-list';

export type CountryType = {
  name: string;
  shortName: string;
  flag: string;
};

const countriesDictionary = {};

Object.keys(countries).forEach((countryNameISO2: string) => {
  const countryNameISO3 = countriesISO2toISO3[countryNameISO2];

  countriesDictionary[countryNameISO3] = {
    name: getCountryData(countryNameISO3).name,
    shortName: countryNameISO3,
    flag: getEmojiFlag(countryNameISO3),
  };
});

export const countriesMap: Record<string, CountryType> = countriesDictionary;
