import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addCategoryError,
  addCategoryRequest,
  addCategorySuccess,
  deleteCategoryError,
  deleteCategoryRequest,
  deleteCategorySuccess,
  getCategoriesError,
  getCategoriesSuccess,
  updateCategoryError,
  updateCategoryRequest,
  updateCategorySuccess,
} from './actions';

import * as CONST from './consts';
import { CategoryModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientCategories } from '../../services/apiClient/categories';

function* getCategories(): Generator<CallEffect | PutEffect, void, ResponseModel<CategoryModel[]>> {
  try {
    const response = yield call(apiClientCategories.getCategories);

    yield put(getCategoriesSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get categories',
      description: getErrorMessage(err),
    });
    yield put(getCategoriesError(err));
  }
}

function* addCategory(
  action: ActionType<typeof addCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<CategoryModel>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiClientCategories.addCategory, category);

    yield put(addCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding category',
      description: getErrorMessage(err),
    });
    yield put(addCategoryError(err));
  }
}

function* updateCategory(
  action: ActionType<typeof updateCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<CategoryModel>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiClientCategories.updateCategory, category);

    yield put(updateCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating category',
      description: getErrorMessage(err),
    });
    yield put(updateCategoryError(err));
  }
}

function* deleteCategory(
  action: ActionType<typeof deleteCategoryRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { categoryId, onSuccess } = action.payload;
    yield call(apiClientCategories.deleteCategory, categoryId);

    yield put(deleteCategorySuccess(categoryId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting category',
      description: getErrorMessage(err),
    });
    yield put(deleteCategoryError(err));
  }
}

export function* watchCategoriesSaga(): Generator {
  yield takeLatest(CONST.GET_CATEGORIES_REQUEST, getCategories);
  yield takeLatest(CONST.ADD_CATEGORY_REQUEST, addCategory);
  yield takeLatest(CONST.UPDATE_CATEGORY_REQUEST, updateCategory);
  yield takeLatest(CONST.DELETE_CATEGORY_REQUEST, deleteCategory);
}
