import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { RootReducerState } from '../../reducers';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { EquipmentBrandForm, EquipmentBrandFormValues } from '../../forms/equipmentBrands';
import { EquipmentBrandModel } from '../../reducers/equipmentBrands/model';
import {
  addEquipmentBrandRequest,
  deleteEquipmentBrandRequest,
  getEquipmentBrandsRequest,
  updateEquipmentBrandRequest,
} from '../../reducers/equipmentBrands/actions';

type EquipmentBrandsListProps = {
  openEquipmentBrandModal: boolean;
  clickOpenEquipmentBrandModal: (openBrandModal: boolean) => void;
};

export const EquipmentBrandsList: FC<EquipmentBrandsListProps> = props => {
  const { openEquipmentBrandModal, clickOpenEquipmentBrandModal } = props;
  const equipmentBrandsList = useSelector<RootReducerState, EquipmentBrandModel[]>(
    state => state.equipmentBrands.equipmentBrandsList,
  );
  const isFetching = useSelector<RootReducerState, boolean>(state => state.equipmentBrands.isFetching);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();
  const [selectedEquipmentBrand, setSelectedEquipmentBrand] = useState<EquipmentBrandModel | undefined>(undefined);

  useEffect(() => {
    dispatch(getEquipmentBrandsRequest());
  }, [dispatch]);

  const handleEditEquipmentBrandModal = (brandId: string) => {
    const brand = equipmentBrandsList.find(item => item._id === brandId);
    setSelectedEquipmentBrand(brand);
    clickOpenEquipmentBrandModal(true);
  };

  const handleCloseEquipmentBrandModal = () => {
    setSelectedEquipmentBrand(undefined);
    clickOpenEquipmentBrandModal(false);
  };

  const handleDeleteEquipmentBrandModal = (brandId: string) => {
    dispatch(deleteEquipmentBrandRequest(brandId));
  };

  const handleSubmit = (values: EquipmentBrandFormValues) => {
    if (selectedEquipmentBrand) {
      dispatch(
        updateEquipmentBrandRequest(
          {
            ...selectedEquipmentBrand,
            ...values,
          },
          () => {
            setSelectedEquipmentBrand(undefined);
            clickOpenEquipmentBrandModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addEquipmentBrandRequest(values, () => {
          setSelectedEquipmentBrand(undefined);
          clickOpenEquipmentBrandModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<EquipmentBrandModel> = [
    {
      title: 'Marka',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ...getColumnSearchProps('name', 'marki'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string, brand) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj markę'
              onClick={() => handleEditEquipmentBrandModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie marki'
              description='Czy na pewno chcesz usunąć markę?'
              onConfirm={() => handleDeleteEquipmentBrandModal(brand._id)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń markę'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={equipmentBrandsList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane marki'
        titleAdd='Dodaj markę'
        openModal={openEquipmentBrandModal}
        handleCloseModal={handleCloseEquipmentBrandModal}
        selectedItem={selectedEquipmentBrand}
      >
        <EquipmentBrandForm
          equipmentBrand={selectedEquipmentBrand}
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseEquipmentBrandModal}
        />
      </DrawerAddEdit>
    </>
  );
};
