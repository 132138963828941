import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { authService } from '../../services/authService';
import { Paths } from '../paths';
import { notifyNotEnoughPermissions } from '../utils';

export const AuthRoute: React.FC<any> = props => {
  const { children } = props;
  const isAuth = authService.isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      notifyNotEnoughPermissions();
      navigate(Paths.LOGIN_PAGE);
    }
  }, [isAuth, navigate]);

  return children;
};
