import React, { FC } from 'react';
import { Button, Col, Dropdown, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { ForkliftModel, ProductStatusListType } from '../../reducers/forklift/model';
import { itemsStatuses } from '../../utils/itemsStatus';
import { isProductStatus } from '../../utils/productsStatus';
import { useDispatch } from 'react-redux';
import { ForkliftsAdditionalMenu } from './ForkliftsAdditionalMenu';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';

type ForkliftsHeaderProps = {
  setOpenForkliftModal: (openForkliftModal: boolean) => void;
  setOpenOrderModal: (openOrderModal: boolean) => void;
  setOpenReservationModal: (openReservationModal: boolean) => void;
  selectedForklifts: ForkliftModel[];
  groupEditForklifts: string[];
  tableSettings: string[];
  setTableSettings: (settings: string[]) => void;
  setOpenOfferModal: (openOfferModal: boolean) => void;
};

export const ForkliftsHeader: FC<ForkliftsHeaderProps> = props => {
  const {
    setOpenForkliftModal,
    setOpenOrderModal,
    setOpenReservationModal,
    selectedForklifts,
    groupEditForklifts,
    tableSettings,
    setTableSettings,
    setOpenOfferModal,
  } = props;
  const dispatch = useDispatch();
  const { isMobile } = useViewport();

  const editItems = (selectedForklifts: ForkliftModel[]) => {
    const itemsStatus = itemsStatuses(dispatch, groupEditForklifts);
    itemsStatus.push(
      {
        label: 'Zamówienie',
        key: 'order',
        onClick: () => {
          setOpenOrderModal(true);
        },
        disabled: !selectedForklifts.every(forklift =>
          isProductStatus(forklift, ProductStatusListType.READY_FOR_ORDER),
        ),
      },
      {
        label: 'Rezerwacja',
        key: 'reservation',
        onClick: () => {
          setOpenReservationModal(true);
        },
        disabled: selectedForklifts.some(forklift => forklift.reservation),
      },
      {
        label: 'Oferta',
        key: 'offer',
        onClick: () => {
          setOpenOfferModal(true);
        },
      },
    );

    return itemsStatus;
  };

  return isMobile ? (
    <HeaderComponentMobile
      title='Wózki'
      onAddElementOpenModal={setOpenForkliftModal}
    >
      <ForkliftsAdditionalMenu
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
      />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Wózki'>
      <Row
        align='middle'
        gutter={16}
      >
        <Col>
          <Button
            type='primary'
            onClick={() => setOpenForkliftModal(true)}
          >
            Dodaj wózek
          </Button>
        </Col>
        <Col>
          <Dropdown
            menu={{ items: editItems(selectedForklifts) }}
            arrow
            disabled={groupEditForklifts.length <= 0}
          >
            <Button disabled={groupEditForklifts.length <= 0}>Edytuj</Button>
          </Dropdown>
        </Col>
        <ForkliftsAdditionalMenu
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      </Row>
    </HeaderComponent>
  );
};
