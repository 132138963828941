import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { SettingFormProps, SettingFormValues } from '.';
import { GeneralSettingModel } from '../../../reducers/settings/model';

export const initFormik = (
  props: SettingFormProps,
  setting: GeneralSettingModel | undefined,
): FormikConfig<SettingFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    displayName: setting?.displayName ?? '',
    key: setting?.key ?? '',
    value: setting?.value ?? '',
  },
  validationSchema: () =>
    yup.object({
      displayName: yup.string().required('Pole jest wymagane'),
      key: yup.string().required('Pole jest wymagane'),
      value: yup.string().required('Pole jest wymagane'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
