import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { CostModel } from './model';

export const getCostsRequest = () => action(CONST.GET_COSTS_REQUEST);
export const getCostsSuccess = (costs: CostModel[]) => action(CONST.GET_COSTS_SUCCESS, { costs });
export const getCostsError = (error: unknown) => action(CONST.GET_COSTS_ERROR, { error });

export const getProductCostRequest = (costId: string) => action(CONST.GET_PRODUCT_COSTS_REQUEST, { costId });
export const getProductCostsSuccess = (costs: CostModel[]) => action(CONST.GET_PRODUCT_COSTS_SUCCESS, { costs });
export const getProductCostsError = (error: unknown) => action(CONST.GET_PRODUCT_COSTS_ERROR, { error });

export const addCostsRequest = (
  cost: Omit<CostModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: () => void,
) => action(CONST.ADD_COSTS_REQUEST, { cost, onSuccess });
export const addCostsSuccess = (cost: CostModel) => action(CONST.ADD_COSTS_SUCCESS, { cost });
export const addCostsError = (error: unknown) => action(CONST.ADD_COSTS_ERROR, { error });

export const updateCostsRequest = (
  cost: Omit<CostModel, 'type' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: () => void,
) => action(CONST.UPDATE_COSTS_REQUEST, { cost, onSuccess });
export const updateCostsSuccess = (cost: CostModel) => action(CONST.UPDATE_COSTS_SUCCESS, { cost });
export const updateCostsError = (error: unknown) => action(CONST.UPDATE_COSTS_ERROR, { error });

export const deleteCostsRequest = (cost: CostModel, onSuccess?: () => void) =>
  action(CONST.DELETE_COSTS_REQUEST, { cost, onSuccess });
export const deleteCostsSuccess = (costId: string) => action(CONST.DELETE_COSTS_SUCCESS, { costId });
export const deleteCostsError = (error: unknown) => action(CONST.DELETE_COSTS_ERROR, { error });
