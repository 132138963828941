import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Row, Input, Select, Col } from 'antd';

import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../reducers';
import { PaintingCostModel } from '../../../reducers/settings/model';
import { CategoryModel } from '../../../reducers/categories/model';

const { Item } = Form;

export type PaintingFormValues = {
  price: number;
  forkliftCategoryId: string;
  liftCapacityFrom: number;
  liftCapacityTo: number;
};

export type PaintingFormProps = {
  painting?: PaintingCostModel;
  handleSubmit: (values: PaintingFormValues) => void;
  handleCancelForm: () => void;
};

export const PaintingForm: FC<PaintingFormProps> = props => {
  const { painting, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.settings.isFetchingSettings);
  const categoriesList = useSelector<RootReducerState, CategoryModel[]>(state => state.categories.categoriesList);

  const formik = useFormik(initFormik(props, painting));

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Item
          label='Kategoria'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.forkliftCategoryId && formik.touched.forkliftCategoryId ? 'error' : ''}
          help={
            formik.errors.forkliftCategoryId && formik.touched.forkliftCategoryId
              ? formik.errors.forkliftCategoryId
              : null
          }
        >
          <Select
            value={formik.values.forkliftCategoryId}
            style={{ width: '100%' }}
            onChange={value => formik.setFieldValue('forkliftCategoryId', value)}
            options={categoriesList.map(client => ({
              label: client.name,
              value: client._id,
            }))}
          />
        </Item>
        <Row gutter={16}>
          <Col span={12}>
            <Item
              label='Udźwig od [kg]'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.liftCapacityFrom && formik.touched.liftCapacityFrom ? 'error' : ''}
              help={
                formik.errors.liftCapacityFrom && formik.touched.liftCapacityFrom
                  ? formik.errors.liftCapacityFrom
                  : null
              }
            >
              <Input
                type='number'
                step={100}
                name='liftCapacityFrom'
                value={formik.values.liftCapacityFrom}
                placeholder='Udźwig od [kg]'
                onChange={formik.handleChange}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label='Udźwig do [kg]'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.liftCapacityTo && formik.touched.liftCapacityTo ? 'error' : ''}
              help={formik.errors.liftCapacityTo && formik.touched.liftCapacityTo ? formik.errors.liftCapacityTo : null}
            >
              <Input
                type='number'
                step={100}
                name='liftCapacityTo'
                value={formik.values.liftCapacityTo}
                placeholder='Udźwig do [kg]'
                onChange={formik.handleChange}
              />
            </Item>
          </Col>
        </Row>
        <Item
          label='Cena'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.price && formik.touched.price ? 'error' : ''}
          help={formik.errors.price && formik.touched.price ? formik.errors.price : null}
        >
          <Input
            type='number'
            name='price'
            value={formik.values.price}
            placeholder='Cena'
            onChange={formik.handleChange}
          />
        </Item>

        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {painting ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
