import { BaseAPI } from '../base';
import { ClientModel } from '../../../reducers/clients/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientClients extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/clients`;
  }

  public getClients = async (): Promise<ResponseModel<ClientModel[]>> => {
    return this.authFetch<ResponseModel<ClientModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addClient = async (
    data: Omit<ClientModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'orders'>,
  ): Promise<ResponseModel<ClientModel>> => {
    return this.authFetch<ResponseModel<ClientModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateClient = async (data: Partial<ClientModel>): Promise<ResponseModel<ClientModel>> => {
    delete data.owner;
    delete data.orders;
    return this.authFetch<ResponseModel<ClientModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteClient = async (clientId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${clientId}`, { method: 'DELETE' });
  };

  public getClientFromCeidg = async (nip: string): Promise<ResponseModel<ClientModel>> => {
    return this.authFetch<ResponseModel<ClientModel>>(`${this.apiURL}/ceidg/${nip}`, { method: 'GET' });
  };
}

export const apiClientClients = new APIClientClients();
