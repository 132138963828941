import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';

import { apiClientUsers } from '../../services/apiClient/users';
import { authService } from '../../services/authService';

import {
  addUserError,
  addUserRequest,
  addUserSuccess,
  deleteUserError,
  deleteUserRequest,
  deleteUserSuccess,
  getMeError,
  getMeSuccess,
  getUsersError,
  getUsersSuccess,
  loginAsUserError,
  loginAsUserRequest,
  loginAsUserSuccess,
  loginError,
  loginRequest,
  loginSuccess,
  resetPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  setPasswordError,
  setPasswordRequest,
  setPasswordSuccess,
  updateUserError,
  updateUserRequest,
  updateUserSuccess,
} from './actions';
import * as CONST from './consts';
import { LoginModel, UserModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { getUserStartingPage } from '../../utils/users';

function* loginUser(
  action: ActionType<typeof loginRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<LoginModel>> {
  try {
    const { username, password } = action.payload;
    const response = yield call(apiClientUsers.login, username, password);

    authService.setToken(response.data.token);
    yield put(loginSuccess());
    yield put(getMeSuccess(response.data.user));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during login',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(loginError(err));
  }
}

function* getUsers(): Generator<CallEffect | PutEffect, void, ResponseModel<UserModel[]>> {
  try {
    const response = yield call(apiClientUsers.getUsers);

    yield put(getUsersSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get users',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(getUsersError(err));
  }
}

function* addUser(
  action: ActionType<typeof addUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<UserModel>> {
  try {
    const { user, onSuccess } = action.payload;
    const response = yield call(apiClientUsers.addUser, user);

    yield put(addUserSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding user',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(addUserError(err));
  }
}

function* updateUser(
  action: ActionType<typeof updateUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<UserModel>> {
  try {
    const { user, onSuccess } = action.payload;
    const response = yield call(apiClientUsers.updateUser, user);

    yield put(updateUserSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating user',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(updateUserError(err));
  }
}

function* deleteUser(action: ActionType<typeof deleteUserRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { userId, onSuccess } = action.payload;
    yield call(apiClientUsers.deleteUser, userId);

    yield put(deleteUserSuccess(userId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting user',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(deleteUserError(err));
  }
}

function* resetPasswordUser(
  action: ActionType<typeof resetPasswordRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<UserModel>> {
  try {
    const { email, onSuccess } = action.payload;
    const response = yield call(apiClientUsers.resetPassword, email);

    yield put(resetPasswordSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during reset user password',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(resetPasswordError(err));
  }
}

function* setPasswordUser(
  action: ActionType<typeof setPasswordRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<UserModel>> {
  try {
    const { data, onSuccess } = action.payload;
    const response = yield call(apiClientUsers.setPassword, data);

    yield put(setPasswordSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during set user password',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(setPasswordError(err));
  }
}

function* loginAsUser(
  action: ActionType<typeof loginAsUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<LoginModel>> {
  try {
    const { email } = action.payload;
    const response = yield call(apiClientUsers.loginAsUser, email);

    authService.setToken(response.data.token);
    yield put(loginAsUserSuccess());
    yield put(getMeSuccess(response.data.user));

    window.location.href = getUserStartingPage(response.data.user);
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during login as user',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(loginAsUserError(err));
  }
}

function* getMe(): Generator<CallEffect | PutEffect, void, ResponseModel<UserModel>> {
  try {
    const response = yield call(apiClientUsers.getMe);

    yield put(getMeSuccess(response.data));
    yield put(loginSuccess());
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get me',
      description: getErrorMessage(err),
    });
    yield put(getMeError(err));
  }
}

export function* watchUserSaga(): Generator {
  yield takeLatest(CONST.LOGIN_REQUEST, loginUser);
  yield takeLatest(CONST.GET_USERS_REQUEST, getUsers);
  yield takeLatest(CONST.ADD_USER_REQUEST, addUser);
  yield takeLatest(CONST.UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(CONST.DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(CONST.RESET_PASSWORD_USER_REQUEST, resetPasswordUser);
  yield takeLatest(CONST.SET_PASSWORD_USER_REQUEST, setPasswordUser);
  yield takeLatest(CONST.LOGIN_AS_USER_REQUEST, loginAsUser);
  yield takeLatest(CONST.GET_ME_REQUEST, getMe);
}
