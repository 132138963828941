import React from 'react';
import { LogoutMenuButton } from '../../component/header/LogoutMenuButton';
import { SummaryOrders } from '../../component/summaryOrders/SummaryOrders';

export const OrdersAdditionalMenu = () => (
  <>
    <SummaryOrders />
    <LogoutMenuButton />
  </>
);
