import { Button, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../reducers';
import { GeneralSettingModel } from '../../../reducers/settings/model';
import React, { useEffect, useState } from 'react';
import { getSettingsRequest, updateSettingRequest } from '../../../reducers/settings/actions';
import type { ColumnsType } from 'antd/es/table';
import { useFilterAntTable } from '../../../utils/customHooks/useFilterAntTable';
import { AlignType } from 'rc-table/lib/interface';
import { EditOutlined } from '@ant-design/icons';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import { SettingForm, SettingFormValues } from '../../../forms/settings/general';

export const GeneralList = () => {
  const settingsList = useSelector<RootReducerState, GeneralSettingModel[]>(state => state.settings.settingsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.settings.isFetchingSettings);
  const [selectedSetting, setSelectedSetting] = useState<GeneralSettingModel | undefined>(undefined);
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();

  useEffect(() => {
    dispatch(getSettingsRequest());
  }, [dispatch]);

  const handleEditSettingModal = (settingId: string) => {
    const setting = settingsList.find(item => item._id === settingId);
    setSelectedSetting(setting);
    setOpenSettingModal(true);
  };

  const handleCloseSettingModal = () => {
    setSelectedSetting(undefined);
    setOpenSettingModal(false);
  };

  const handleSubmit = (values: SettingFormValues) => {
    if (selectedSetting) {
      dispatch(
        updateSettingRequest(
          {
            ...selectedSetting,
            ...values,
          },
          () => {
            setSelectedSetting(undefined);
            setOpenSettingModal(false);
          },
        ),
      );
    }
  };

  const columns: ColumnsType<GeneralSettingModel> = [
    {
      title: 'Nazwa',
      dataIndex: 'displayName',
      key: 'displayName',
      ...getColumnSearchProps('displayName', 'nazwy'),
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: 'Klucz',
      dataIndex: 'key',
      key: 'key',
      ...getColumnSearchProps('key', 'klucza'),
      sorter: (a, b) => a.key.localeCompare(b.key),
    },
    {
      title: 'Wartość',
      dataIndex: 'value',
      key: 'value',
      ...getColumnSearchProps('value', 'wartości'),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj ustawienia'
              onClick={() => handleEditSettingModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={settingsList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj ustawienia'
        titleAdd='Dodaj ustawienia'
        openModal={openSettingModal}
        handleCloseModal={() => setOpenSettingModal(false)}
        selectedItem={selectedSetting}
      >
        <SettingForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseSettingModal}
          setting={selectedSetting}
        />
      </DrawerAddEdit>
    </>
  );
};
