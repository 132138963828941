import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { EquipmentBrandModel } from './model';

export type EquipmentBrandsAction = ActionType<typeof actions>;
export type EquipmentBrandsState = {
  readonly isFetching: boolean;
  readonly equipmentBrandsList: EquipmentBrandModel[];
};

export const equipmentBrandsReducer = combineReducers<EquipmentBrandsState, EquipmentBrandsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENT_BRANDS_REQUEST:
      case CONST.ADD_EQUIPMENT_BRAND_REQUEST:
      case CONST.UPDATE_EQUIPMENT_BRAND_REQUEST:
      case CONST.DELETE_EQUIPMENT_BRAND_REQUEST:
        return true;

      case CONST.GET_EQUIPMENT_BRANDS_SUCCESS:
      case CONST.GET_EQUIPMENT_BRANDS_ERROR:
      case CONST.ADD_EQUIPMENT_BRAND_SUCCESS:
      case CONST.ADD_EQUIPMENT_BRAND_ERROR:
      case CONST.UPDATE_EQUIPMENT_BRAND_SUCCESS:
      case CONST.UPDATE_EQUIPMENT_BRAND_ERROR:
      case CONST.DELETE_EQUIPMENT_BRAND_SUCCESS:
      case CONST.DELETE_EQUIPMENT_BRAND_ERROR:
        return false;

      default:
        return state;
    }
  },
  equipmentBrandsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENT_BRANDS_SUCCESS:
        return action.payload.brands;

      case CONST.ADD_EQUIPMENT_BRAND_SUCCESS:
        return [action.payload.equipmentBrand, ...state];

      case CONST.UPDATE_EQUIPMENT_BRAND_SUCCESS: {
        const brandIdx = state.findIndex(item => item._id === action.payload.equipmentBrand._id);
        state.splice(brandIdx, 1, action.payload.equipmentBrand);

        return [...state];
      }

      case CONST.DELETE_EQUIPMENT_BRAND_SUCCESS:
        return state.filter(brand => brand._id !== action.payload.equipmentBrandId);

      default:
        return state;
    }
  },
});
