import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { UserModel } from './model';

export const loginRequest = (username: string, password: string) => action(CONST.LOGIN_REQUEST, { username, password });
export const loginSuccess = () => action(CONST.LOGIN_SUCCESS);
export const loginError = (error: Error) => action(CONST.LOGIN_ERROR, { error });
export const logoutUser = () => action(CONST.LOGOUT_USER);

export const getUsersRequest = () => action(CONST.GET_USERS_REQUEST);
export const getUsersSuccess = (users: UserModel[]) => action(CONST.GET_USERS_SUCCESS, { users });
export const getUsersError = (error: Error) => action(CONST.GET_USERS_ERROR, { error });

export const addUserRequest = (
  user: Omit<UserModel, '_id' | 'createdAt' | 'updatedAt' | 'ownerId'>,
  onSuccess?: () => void,
) => action(CONST.ADD_USER_REQUEST, { user, onSuccess });
export const addUserSuccess = (user: UserModel) => action(CONST.ADD_USER_SUCCESS, { user });
export const addUserError = (error: Error) => action(CONST.ADD_USER_ERROR, { error });

export const updateUserRequest = (user: UserModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_USER_REQUEST, { user, onSuccess });
export const updateUserSuccess = (user: UserModel) => action(CONST.UPDATE_USER_SUCCESS, { user });
export const updateUserError = (error: Error) => action(CONST.UPDATE_USER_ERROR, { error });

export const deleteUserRequest = (userId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_USER_REQUEST, { userId, onSuccess });
export const deleteUserSuccess = (userId: string) => action(CONST.DELETE_USER_SUCCESS, { userId });
export const deleteUserError = (error: Error) => action(CONST.DELETE_USER_ERROR, { error });

export const resetPasswordRequest = (email: string, onSuccess?: () => void) =>
  action(CONST.RESET_PASSWORD_USER_REQUEST, { email, onSuccess });
export const resetPasswordSuccess = (data: UserModel) => action(CONST.RESET_PASSWORD_USER_SUCCESS, data);
export const resetPasswordError = (error: Error) => action(CONST.RESET_PASSWORD_USER_ERROR, { error });

export const setPasswordRequest = (
  data: { password: string; passwordConfirmation: string; hash: string },
  onSuccess?: () => void,
) => action(CONST.SET_PASSWORD_USER_REQUEST, { data, onSuccess });
export const setPasswordSuccess = (data: UserModel) => action(CONST.SET_PASSWORD_USER_SUCCESS, { data });
export const setPasswordError = (error: Error) => action(CONST.SET_PASSWORD_USER_ERROR, { error });

export const loginAsUserRequest = (email: string) => action(CONST.LOGIN_AS_USER_REQUEST, { email });
export const loginAsUserSuccess = () => action(CONST.LOGIN_AS_USER_SUCCESS);
export const loginAsUserError = (error: Error) => action(CONST.LOGIN_AS_USER_ERROR, { error });

export const getMeRequest = () => action(CONST.GET_ME_REQUEST);
export const getMeSuccess = (user: UserModel) => action(CONST.GET_ME_SUCCESS, { user });
export const getMeError = (error: unknown) => action(CONST.GET_ME_ERROR, { error });
