import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { EquipmentBrandModel } from './model';

export const getEquipmentBrandsRequest = () => action(CONST.GET_EQUIPMENT_BRANDS_REQUEST);
export const getEquipmentBrandsSuccess = (brands: EquipmentBrandModel[]) =>
  action(CONST.GET_EQUIPMENT_BRANDS_SUCCESS, { brands });
export const getEquipmentBrandsError = (error: unknown) => action(CONST.GET_EQUIPMENT_BRANDS_ERROR, { error });

export const addEquipmentBrandRequest = (
  equipmentBrand: Omit<EquipmentBrandModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: (brand?: EquipmentBrandModel) => void,
) => action(CONST.ADD_EQUIPMENT_BRAND_REQUEST, { equipmentBrand, onSuccess });
export const addEquipmentBrandSuccess = (equipmentBrand: EquipmentBrandModel) =>
  action(CONST.ADD_EQUIPMENT_BRAND_SUCCESS, { equipmentBrand });
export const addEquipmentBrandError = (error: unknown) => action(CONST.ADD_EQUIPMENT_BRAND_ERROR, { error });

export const updateEquipmentBrandRequest = (equipmentBrand: EquipmentBrandModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_EQUIPMENT_BRAND_REQUEST, { equipmentBrand, onSuccess });
export const updateEquipmentBrandSuccess = (equipmentBrand: EquipmentBrandModel) =>
  action(CONST.UPDATE_EQUIPMENT_BRAND_SUCCESS, { equipmentBrand });
export const updateEquipmentBrandError = (error: unknown) => action(CONST.UPDATE_EQUIPMENT_BRAND_ERROR, { error });

export const deleteEquipmentBrandRequest = (equipmentBrandId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_EQUIPMENT_BRAND_REQUEST, { equipmentBrandId, onSuccess });
export const deleteEquipmentBrandSuccess = (equipmentBrandId: string) =>
  action(CONST.DELETE_EQUIPMENT_BRAND_SUCCESS, { equipmentBrandId });
export const deleteEquipmentBrandError = (error: unknown) => action(CONST.DELETE_EQUIPMENT_BRAND_ERROR, { error });
