import { ForkliftModel, ProductStatus } from '../reducers/forklift/model';

export const getStatusForkliftClass = (forklift: ForkliftModel) => {
  switch (true) {
    case forklift.status === ProductStatus.TRANSPORT_TO_ME:
      return 'blue';

    case !!forklift.reservation:
      return 'yellow';

    default:
      return '';
  }
};
