import { BaseAPI } from '../base';
import { CostModel } from '../../../reducers/costs/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientCosts extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/costs`;
  }

  public getCosts = async (): Promise<ResponseModel<CostModel[]>> => {
    return this.authFetch<ResponseModel<CostModel[]>>(this.apiURL, { method: 'GET' });
  };

  public getProductCosts = async (costId: string): Promise<ResponseModel<CostModel[]>> => {
    return this.authFetch<ResponseModel<CostModel[]>>(`${this.apiURL}/product/${costId}`, { method: 'GET' });
  };

  public addCost = async (
    data: Omit<CostModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<CostModel>> => {
    return this.authFetch<ResponseModel<CostModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateCost = async (data: Partial<CostModel>): Promise<ResponseModel<CostModel>> => {
    return this.authFetch<ResponseModel<CostModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteCost = async (brandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${brandId}`, { method: 'DELETE' });
  };
}

export const apiClientCosts = new APIClientCosts();
