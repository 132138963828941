import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Row, Input } from 'antd';

import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { EquipmentBrandModel } from '../../reducers/equipmentBrands/model';

const { Item } = Form;

export type EquipmentBrandFormValues = {
  name: string;
};

export type EquipmentBrandFormProps = {
  equipmentBrand?: EquipmentBrandModel;
  handleSubmit: (values: EquipmentBrandFormValues) => void;
  handleCancelForm: () => void;
};

export const EquipmentBrandForm: FC<EquipmentBrandFormProps> = props => {
  const { equipmentBrand, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.brands.isFetching);

  const formik = useFormik(initFormik(props, equipmentBrand));

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Item
          label='Nazwa marki'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
          help={formik.errors.name && formik.touched.name ? formik.errors.name : null}
        >
          <Input
            name='name'
            value={formik.values.name}
            placeholder='Nazwa'
            onChange={formik.handleChange}
          />
        </Item>
        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {equipmentBrand ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
