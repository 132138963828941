import { useQuery } from 'react-query';
import { apiClientOffers } from './index';

const queryKeys = {
  getOffer: (id: string) => `offers/${id}`,
};

export const useGetOffer = (id: string) => {
  return useQuery(queryKeys.getOffer(id), async () => {
    const response = await apiClientOffers.getOffer(id);
    return response.data;
  });
};
