import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addBrandError,
  addBrandRequest,
  addBrandSuccess,
  deleteBrandError,
  deleteBrandRequest,
  deleteBrandSuccess,
  getBrandsError,
  getBrandsSuccess,
  updateBrandError,
  updateBrandRequest,
  updateBrandSuccess,
} from './actions';

import * as CONST from './consts';
import { BrandModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { apiClientBrands } from '../../services/apiClient/brands';
import { ActionType } from 'typesafe-actions';

function* getBrands(): Generator<CallEffect | PutEffect, void, ResponseModel<BrandModel[]>> {
  try {
    const response = yield call(apiClientBrands.getBrands);

    yield put(getBrandsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get brands',
      description: getErrorMessage(err),
    });
    yield put(getBrandsError(err));
  }
}

function* addBrand(
  action: ActionType<typeof addBrandRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<BrandModel>> {
  try {
    const { brand, onSuccess } = action.payload;
    const response = yield call(apiClientBrands.addBrand, brand);

    yield put(addBrandSuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding brand',
      description: getErrorMessage(err),
    });
    yield put(addBrandError(err));
  }
}

function* updateBrand(
  action: ActionType<typeof updateBrandRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<BrandModel>> {
  try {
    const { brand, onSuccess } = action.payload;
    const response = yield call(apiClientBrands.updateBrand, brand);

    yield put(updateBrandSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating brand',
      description: getErrorMessage(err),
    });
    yield put(updateBrandError(err));
  }
}

function* deleteBrand(action: ActionType<typeof deleteBrandRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { brandId, onSuccess } = action.payload;
    yield call(apiClientBrands.deleteBrand, brandId);

    yield put(deleteBrandSuccess(brandId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting brand',
      description: getErrorMessage(err),
    });
    yield put(deleteBrandError(err));
  }
}

export function* watchBrandsSaga(): Generator {
  yield takeLatest(CONST.GET_BRANDS_REQUEST, getBrands);
  yield takeLatest(CONST.ADD_BRAND_REQUEST, addBrand);
  yield takeLatest(CONST.UPDATE_BRAND_REQUEST, updateBrand);
  yield takeLatest(CONST.DELETE_BRAND_REQUEST, deleteBrand);
}
