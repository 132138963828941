import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Row, Input, Col, Select } from 'antd';

import { initFormik } from './formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { CostModel, CostType } from '../../reducers/costs/model';
import { PaintingCostModel } from '../../reducers/settings/model';
import { getPaintingsRequest } from '../../reducers/settings/actions';
import { ForkliftModel } from '../../reducers/forklift/model';

const { Item } = Form;

export type CostFormValues = {
  name: string;
  cost: number;
  type: CostType;
};

export type CostFormProps = {
  forklift: ForkliftModel;
  cost?: CostModel;
  handleSubmit: (values: CostFormValues, callback?: () => void) => void;
};

export const CostForm: FC<CostFormProps> = props => {
  const { forklift } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.settings.isFetchingSettings);
  const costPaintingList = useSelector<RootReducerState, PaintingCostModel[]>(state => state.settings.paintingList);
  const dispatch = useDispatch();

  const formik = useFormik(initFormik(props));

  useEffect(() => {
    dispatch(getPaintingsRequest());
  }, [dispatch]);

  const costPainting = costPaintingList.filter(cost => cost.forkliftCategoryId === forklift.categoryId);
  const filteredCostPainting = costPainting.find(
    cost => forklift.liftingCapacity >= cost.liftCapacityFrom && forklift.liftingCapacity <= cost.liftCapacityTo,
  );

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Row gutter={16}>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Typ kosztu'
              hasFeedback={true}
              validateStatus={formik.errors.type && formik.touched.type ? 'error' : ''}
              help={formik.errors.type && formik.touched.type ? formik.errors.type : null}
            >
              <Select
                value={formik.values.type}
                style={{ width: '100%' }}
                onChange={value => {
                  if (value === CostType.PAINTING) {
                    void formik.setFieldValue('cost', filteredCostPainting?.price ?? 0);
                    void formik.setFieldValue('name', CostType.PAINTING);
                  } else {
                    void formik.setFieldValue('cost', 0);
                    void formik.setFieldValue('name', '');
                  }
                  return formik.setFieldValue('type', value);
                }}
                options={Object.values(CostType).map(cost => ({
                  label: cost,
                  value: cost,
                }))}
              />
            </Item>
          </Col>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Cena'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.cost && formik.touched.cost ? 'error' : ''}
              help={formik.errors.cost && formik.touched.cost ? formik.errors.cost : null}
            >
              <Input
                name='cost'
                value={formik.values.cost}
                placeholder='Cena'
                onChange={formik.handleChange}
              />
            </Item>
          </Col>
        </Row>
        <Row
          justify='space-between'
          align='bottom'
        >
          <Col span={12}>
            <Item
              label='Nazwa kosztu'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
              help={formik.errors.name && formik.touched.name ? formik.errors.name : null}
            >
              <Input
                name='name'
                value={formik.values.name}
                placeholder='Nazwa kosztu'
                onChange={formik.handleChange}
              />
            </Item>
          </Col>
          <Button
            style={{ marginBottom: '24px' }}
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </div>
  );
};
