import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Popconfirm, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { RootReducerState } from '../../reducers';
import { AdvancePaymentModel } from '../../reducers/advancePayments/model';
import {
  addAdvancePaymentRequest,
  deleteAdvancePaymentRequest,
  getOrderAdvancePaymentsRequest,
  updateAdvancePaymentRequest,
} from '../../reducers/advancePayments/actions';

import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { AdvancePaymentForm, AdvancePaymentFormValues } from '../../forms/advancePayments';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';

type AdvancePaymentsListProps = {
  currency: CurrencyType;
  orderId: string;
};

export const AdvancePaymentsList: FC<AdvancePaymentsListProps> = props => {
  const { currency, orderId } = props;
  const orderAdvancePaymentsList = useSelector<RootReducerState, AdvancePaymentModel[]>(
    state => state.advancePayment.orderAdvancePaymentList,
  );
  const isFetching = useSelector<RootReducerState, boolean>(state => state.advancePayment.isFetching);
  const [openAdvancePaymentModal, setOpenAdvancePaymentModal] = useState(false);
  const [selectedAdvancePayment, setSelectedAdvancePayment] = useState<AdvancePaymentModel | undefined>(undefined);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();

  const sumWithInitial = useMemo(() => {
    return orderAdvancePaymentsList.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0);
  }, [orderAdvancePaymentsList]);

  useEffect(() => {
    dispatch(getOrderAdvancePaymentsRequest(orderId));
  }, [dispatch, orderId]);

  const handleEditAdvancePaymentModal = (advancePaymentId: string) => {
    const advancePayment = orderAdvancePaymentsList.find(item => item._id === advancePaymentId);
    setSelectedAdvancePayment(advancePayment);
    setOpenAdvancePaymentModal(true);
  };

  const handleDeleteAdvancePaymentModal = (advancePaymentId: string) => {
    dispatch(deleteAdvancePaymentRequest(advancePaymentId));
  };

  const handleSubmitAdvancePayment = (values: AdvancePaymentFormValues) => {
    const advancePayment = {
      currency: currency,
      orderId: orderId,
      paidAt: values.paidAt,
      price: values.price,
    };
    if (selectedAdvancePayment) {
      dispatch(
        updateAdvancePaymentRequest(
          {
            ...selectedAdvancePayment,
            ...advancePayment,
          },
          () => {
            setSelectedAdvancePayment(undefined);
            setOpenAdvancePaymentModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addAdvancePaymentRequest(advancePayment, () => {
          setSelectedAdvancePayment(undefined);
          setOpenAdvancePaymentModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<AdvancePaymentModel> = [
    {
      title: 'Kwota zaliczki',
      dataIndex: 'price',
      key: 'price',
      width: '70%',
      ...getColumnSearchProps('price', 'zaliczki'),
      sorter: (a, b) => a.price - b.price,
      render: (price: number) => (
        <>
          {price} {CurrencyTypeNames[currency]}
        </>
      ),
    },
    {
      title: 'Opłacono',
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: paidAt => paidAt && dayjs(paidAt).format('DD-MM-YYYY'),
      sorter: (a, b) => new Date(a.paidAt || '').getTime() - new Date(b.paidAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string, brand) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj zaliczkę'
              onClick={() => handleEditAdvancePaymentModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie zaliczki'
              description='Czy na pewno chcesz usunąć zaliczkę?'
              onConfirm={() => handleDeleteAdvancePaymentModal(brand._id)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń zaliczkę'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Flex
        justify='flex-end'
        style={{ marginTop: '-30px' }}
      >
        <Button onClick={() => setOpenAdvancePaymentModal(true)}>Dodaj zaliczkę</Button>
      </Flex>
      {orderAdvancePaymentsList.length > 0 && (
        <Table
          columns={columns}
          dataSource={orderAdvancePaymentsList}
          loading={isFetching}
          rowKey={record => record._id || ''}
          style={{ padding: '16px 0' }}
          footer={() => `SUMA: ${sumWithInitial} ${CurrencyTypeNames[currency]}`}
        />
      )}
      <DrawerAddEdit
        titleEdit='Edytuj zaliczkę'
        titleAdd='Dodaj zaliczkę'
        selectedItem={selectedAdvancePayment}
        openModal={openAdvancePaymentModal}
        handleCloseModal={() => setOpenAdvancePaymentModal(false)}
      >
        <AdvancePaymentForm
          currency={currency}
          advancePayment={selectedAdvancePayment}
          handleSubmit={handleSubmitAdvancePayment}
          handleCancelForm={() => setOpenAdvancePaymentModal(false)}
        />
      </DrawerAddEdit>
    </>
  );
};
