import { BaseAPI } from '../base';
import { EquipmentBrandModel } from '../../../reducers/equipmentBrands/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientEquipmentBrands extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/equipment-brands`;
  }

  public getEquipmentBrands = async (): Promise<ResponseModel<EquipmentBrandModel[]>> => {
    return this.authFetch<ResponseModel<EquipmentBrandModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addEquipmentBrand = async (
    data: Omit<EquipmentBrandModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<EquipmentBrandModel>> => {
    return this.authFetch<ResponseModel<EquipmentBrandModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateEquipmentBrand = async (
    data: Partial<EquipmentBrandModel>,
  ): Promise<ResponseModel<EquipmentBrandModel>> => {
    return this.authFetch<ResponseModel<EquipmentBrandModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteEquipmentBrand = async (equipmentBrandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${equipmentBrandId}`, { method: 'DELETE' });
  };
}

export const apiClientEquipmentBrands = new APIClientEquipmentBrands();
