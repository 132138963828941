import { useEffect, FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Row, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { LayoutLogin } from '../../layout/login/Login';
import { Paths } from '../../router/paths';
import { UserModel } from '../../reducers/user/model';

import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';

import './style.scss';

const { Item } = Form;
const { Text } = Typography;

export type LoginFormValues = {
  username: string;
  password: string;
};

export type LoginFormProps = {
  handleSubmit: (values: LoginFormValues) => void;
};

export const LoginForm: FC<LoginFormProps> = props => {
  const isFetching = useSelector<RootReducerState, boolean>(state => state.users.isFetching);
  const loggedIn = useSelector<RootReducerState, boolean>(state => state.users.loggedIn);
  const user = useSelector<RootReducerState, UserModel | null>(state => state.users.userLoggedIn);
  const formik = useFormik(initFormik(props));
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
      if (user) {
        navigate(Paths.HOME_PAGE);
      }
    }
  }, [loggedIn, user, navigate]);

  return (
    <LayoutLogin>
      <Form
        layout='vertical'
        className='loginForm'
      >
        <Text className='loginHeaderText'>Logowanie</Text>
        <Item
          label='E-mail'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.username && formik.touched.username ? 'error' : ''}
          help={formik.errors.username && formik.touched.username ? formik.errors.username : null}
        >
          <Input
            name='username'
            value={formik.values.username}
            placeholder='E-mail'
            prefix={<UserOutlined />}
            onChange={formik.handleChange}
          />
        </Item>
        <Item
          label='Hasło'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.password && formik.touched.password ? 'error' : ''}
          help={formik.errors.password && formik.touched.password ? formik.errors.password : null}
        >
          <Input.Password
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder='Hasło'
            prefix={<LockOutlined />}
          />
        </Item>
        <Row
          justify='space-between'
          align='middle'
        >
          <Button
            type='primary'
            size='large'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            Zaloguj
          </Button>
          <Link
            className='link'
            to={Paths.RESET_PASSWORD}
          >
            Nie pamiętasz hasła?
          </Link>
        </Row>
      </Form>
    </LayoutLogin>
  );
};
