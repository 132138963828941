import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { BrandsAdditionalMenu } from './BrandsAdditionalMenu';

type BrandsHeaderProps = {
  onSetOpenBrandModal: (openBrandModal: boolean) => void;
};

export const BrandsHeader: FC<BrandsHeaderProps> = props => {
  const { onSetOpenBrandModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Marki'
      onAddElementOpenModal={onSetOpenBrandModal}
    >
      <BrandsAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Marki'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenBrandModal(true)}
          >
            Dodaj markę
          </Button>
        </Col>
        <BrandsAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
