import React from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { GeneralList } from '../../component/settings/general/GeneralList';
import { GeneralHeader } from './GeneralHeader';

export const GeneralSettingsPage = () => {
  return (
    <LayoutMain>
      <GeneralHeader />
      <GeneralList />
    </LayoutMain>
  );
};
