import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { MastModel } from './model';

export const getMastsRequest = () => action(CONST.GET_MASTS_REQUEST);
export const getMastsSuccess = (masts: MastModel[]) => action(CONST.GET_MASTS_SUCCESS, { masts });
export const getMastsError = (error: unknown) => action(CONST.GET_MASTS_ERROR, { error });

export const addMastRequest = (
  mast: Omit<MastModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: (mast?: MastModel) => void,
) => action(CONST.ADD_MAST_REQUEST, { mast, onSuccess });
export const addMastSuccess = (mast: MastModel) => action(CONST.ADD_MAST_SUCCESS, { mast });
export const addMastError = (error: unknown) => action(CONST.ADD_MAST_ERROR, { error });

export const updateMastRequest = (mast: MastModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_MAST_REQUEST, { mast, onSuccess });
export const updateMastSuccess = (mast: MastModel) => action(CONST.UPDATE_MAST_SUCCESS, { mast });
export const updateMastError = (error: unknown) => action(CONST.UPDATE_MAST_ERROR, { error });

export const deleteMastRequest = (mastId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_MAST_REQUEST, { mastId, onSuccess });
export const deleteMastSuccess = (mastId: string) => action(CONST.DELETE_MAST_SUCCESS, { mastId });
export const deleteMastError = (error: unknown) => action(CONST.DELETE_MAST_ERROR, { error });
