import { OrderModel } from '../orders/model';
import { UserModel } from '../user/model';
import { TCountryCode } from 'countries-list';

export enum ClientType {
  CUSTOMER = 'customer',
  DEALER = 'dealer',
}

export const ClientTypeNames = {
  [ClientType.CUSTOMER]: 'Końcowy',
  [ClientType.DEALER]: 'Dealer',
};

export type ClientModel = {
  _id: string;
  companyName: string;
  companyStreet: string;
  companyCity: string;
  companyPostCode: string;
  companyCountry: TCountryCode;
  companyNIP: string;
  contactName: string;
  contactSurname: string;
  contactEmail: string;
  contactPhone: string;
  orders: OrderModel[];
  deleted: string;
  createdAt: string;
  updatedAt: string;
  clientType: ClientType;
  owner?: UserModel | null;
};
