import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { ClientModel, ClientType, ClientTypeNames } from '../../reducers/clients/model';
import {
  addClientRequest,
  deleteClientRequest,
  getClientsRequest,
  updateClientRequest,
} from '../../reducers/clients/actions';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { ClientForm, ClientFormValues } from '../../forms/client';
import type { ColumnsType } from 'antd/es/table';
import { UserModel, UserRole } from '../../reducers/user/model';
import { getUsersRequest } from '../../reducers/user/actions';
import { Client } from './Client';
import { useViewport } from '../../utils/customHooks/useViewport';

const { Link, Text } = Typography;

type ClientListProps = {
  openClientModal: boolean;
  clickOpenClientModal: (openClientModal: boolean) => void;
};

type ClientModelColumn = Omit<ClientModel, 'orders'> & {
  orders: number;
  ownerFullName: string;
};

export const ClientList: FC<ClientListProps> = props => {
  const { openClientModal, clickOpenClientModal } = props;
  const clientList = useSelector<RootReducerState, ClientModel[]>(state => state.clients.clientsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.clients.isFetching);
  const usersList = useSelector<RootReducerState, UserModel[]>(state => state.users.usersList);
  const dispatch = useDispatch();
  const { height } = useViewport();
  const { getColumnSearchProps, getColumnFilterProps, getColumnSearchRangeProps } = useFilterAntTable();
  const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(undefined);
  const [, setDeleteClientModal] = useState(false);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);

  useEffect(() => {
    dispatch(getClientsRequest());
    dispatch(getUsersRequest());
  }, [dispatch]);

  const handleEditClientModal = (clientId: string) => {
    const client = clientList.find(item => item._id === clientId);
    setSelectedClient(client);
    clickOpenClientModal(true);
  };

  const handleDeleteClientModal = (clientId: string) => {
    dispatch(
      deleteClientRequest(clientId, () => {
        setDeleteClientModal(false);
      }),
    );
  };

  const handleCloseClientModal = () => {
    setSelectedClient(undefined);
    clickOpenClientModal(false);
  };

  const handleSubmit = (values: ClientFormValues) => {
    if (selectedClient) {
      dispatch(
        updateClientRequest(
          {
            ...selectedClient,
            ...values,
          },
          () => {
            setSelectedClient(undefined);
            clickOpenClientModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addClientRequest(values, () => {
          setSelectedClient(undefined);
          clickOpenClientModal(false);
        }),
      );
    }
  };

  const clients = clientList.map(client => ({
    ...client,
    orders: client.orders.length,
    ownerFullName: `${client.owner?.name ?? ''} ${client.owner?.surname ?? ''}`,
  }));

  const handleQuickViewClientModal = (clientId: string) => {
    const client = clientList.find(item => item._id === clientId);
    setSelectedClient(client);
    setOpenQuickViewModal(true);
  };

  const handleCloseQuickViewModal = () => {
    setSelectedClient(undefined);
    setOpenQuickViewModal(false);
  };

  const columns: ColumnsType<ClientModelColumn> = [
    {
      title: 'Nazwa firmy',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName', 'nazwy firmy'),
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      render: companyName => <Text ellipsis={true}>{companyName}</Text>,
    },
    {
      title: 'Typ klienta',
      dataIndex: 'clientType',
      key: 'clientType',
      ...getColumnFilterProps(
        'clientType',
        Object.values(ClientType).map(client => ({
          value: client,
          text: ClientTypeNames[client],
        })),
      ),
      render: (clientType: ClientType) => ClientTypeNames[clientType],
      sorter: (a, b) => a.clientType.localeCompare(b.clientType),
    },
    {
      title: 'Imię',
      dataIndex: 'contactName',
      key: 'contactName',
      width: 150,
      ...getColumnSearchProps('contactName', 'imienia'),
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
    },
    {
      title: 'Nazwisko',
      dataIndex: 'contactSurname',
      key: 'contactSurname',
      ...getColumnSearchProps('contactSurname', 'nazwiska'),
      sorter: (a, b) => a.contactSurname.localeCompare(b.contactSurname),
    },
    {
      title: 'E-mail',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      ...getColumnSearchProps('contactEmail', 'e-mail'),
      sorter: (a, b) => a.contactEmail.localeCompare(b.contactEmail),
      render: (email: string) => (
        <Text ellipsis={true}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Text>
      ),
    },
    {
      title: 'Telefon',
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      width: 150,
      ...getColumnSearchProps('contactPhone', 'telefonu'),
      sorter: (a, b) => a.contactPhone.localeCompare(b.contactPhone),
    },
    {
      title: 'Zamówienia',
      dataIndex: 'orders',
      key: 'orders',
      align: 'center' as AlignType,
      ...getColumnSearchRangeProps('orders', 'Liczba zamówień'),
      sorter: (a, b) => a.orders - b.orders,
    },
    {
      title: 'Opiekun',
      dataIndex: 'ownerFullName',
      key: 'ownerFullName',
      align: 'right' as AlignType,
      ...getColumnFilterProps(
        'ownerId',
        usersList
          .filter(user => [UserRole.SELLER, UserRole.ADMIN, UserRole.SALES_DIRECTOR].includes(user.role))
          .map(user => ({
            text: `${user.name} ${user.surname}`,
            value: user._id,
          })),
      ),
      sorter: (a, b) => a.ownerFullName.localeCompare(b.ownerFullName),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      width: 100,
      render: (_id: string, client) => (
        <>
          <Tooltip
            title='Podgląd'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Zobacz podgląd'
              onClick={() => handleQuickViewClientModal(_id)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj klienta'
              onClick={() => handleEditClientModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie klienta'
              description='Czy na pewno chcesz usunąć klienta?'
              onConfirm={() => handleDeleteClientModal(client._id)}
              onCancel={() => setDeleteClientModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń klienta'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={clients}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        sticky
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        scroll={{ y: height - 131 }}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane klienta'
        titleAdd='Dodaj klienta'
        openModal={openClientModal}
        handleCloseModal={handleCloseClientModal}
        selectedItem={selectedClient}
      >
        <ClientForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseClientModal}
          client={selectedClient}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit={`Podgląd klienta: ${selectedClient?.companyName ?? ''}`}
        titleAdd={`Podgląd klienta: ${selectedClient?.companyName ?? ''}`}
        openModal={openQuickViewModal}
        handleCloseModal={handleCloseQuickViewModal}
        selectedItem={selectedClient}
        width='60%'
      >
        <Client client={selectedClient} />
      </DrawerAddEdit>
    </div>
  );
};
