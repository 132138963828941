import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { OfferModel } from './model';

export const getOffersRequest = () => action(CONST.GET_OFFERS_REQUEST);
export const getOffersSuccess = (offers: OfferModel[]) => action(CONST.GET_OFFERS_SUCCESS, { offers });
export const getOffersError = (error: unknown) => action(CONST.GET_OFFERS_ERROR, { error });

export const getOfferRequest = (id: string) => action(CONST.GET_OFFER_REQUEST, { id });
export const getOfferSuccess = (offer: OfferModel) => action(CONST.GET_OFFER_SUCCESS, { offer });
export const getOfferError = (error: unknown) => action(CONST.GET_OFFER_ERROR, { error });

export const addOfferRequest = (
  offer: Omit<OfferModel, '_id' | 'createdAt' | 'updatedAt' | 'ownerId' | 'price' | 'products'>,
  onSuccess?: (offerId: string) => void,
) => action(CONST.ADD_OFFER_REQUEST, { offer, onSuccess });
export const addOfferSuccess = (offer: OfferModel) => action(CONST.ADD_OFFER_SUCCESS, { offer });
export const addOfferError = (error: unknown) => action(CONST.ADD_OFFER_ERROR, { error });

export const updateOfferRequest = (
  offer: Omit<OfferModel, 'client' | 'ownerId' | 'owner' | 'price'>,
  onSuccess?: () => void,
) => action(CONST.UPDATE_OFFER_REQUEST, { offer, onSuccess });
export const updateOfferSuccess = (offer: OfferModel) => action(CONST.UPDATE_OFFER_SUCCESS, { offer });
export const updateOfferError = (error: unknown) => action(CONST.UPDATE_OFFER_ERROR, { error });

export const deleteOfferRequest = (offerId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_OFFER_REQUEST, { offerId, onSuccess });
export const deleteOfferSuccess = (offerId: string) => action(CONST.DELETE_OFFER_SUCCESS, { offerId });
export const deleteOfferError = (error: unknown) => action(CONST.DELETE_OFFER_ERROR, { error });

export const getOfferPDFRequest = (offerId: string, onSuccess?: () => void) =>
  action(CONST.GET_OFFER_PDF_REQUEST, { offerId, onSuccess });

export const getOfferPDFSuccess = () => action(CONST.GET_OFFER_PDF_SUCCESS);

export const getOfferPDFError = (error: unknown) => action(CONST.GET_OFFER_PDF_ERROR, { error });
